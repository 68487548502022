import React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  InputBase,
  Alert,
  Stack,
  Snackbar,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import {
  StyledButton,
  TypographyHeadModal,
  StyledInputBase,
  BoxModal,
  ErrorText,
} from "../utilities";

const StyledTypography = styled(Typography)`
  font-size: 17px;
  font-weight: 400;
  width: 31%;
  text-transform: capitalize;
`;
export default function UpdateAccountModal({
  currentRow,
  updateModalOpen,
  setUpdateModalOpen,
}) {
  const { t } = useTranslation("finance");
  const schema = yup.object().shape({
    accountNo: yup
      .number()
      .typeError(t("validation.must_be_number"))
      .required(),
    fName: yup.string().required(t("validation.english_Name_is_required")),
    fNameA: yup.string().required(t("validation.arabic_Name_is_required")),
    contactPerson: yup
      .string()
      .required(t("validation.contact_person_is_required")),
    contactNo: yup.string().required(t("validation.contact_no_is_required")),
    address1: yup.string().required(t("validation.address1_is_required")),
    address2: yup.string().required(t("validation.address2_is_required")),
    mainAccount: yup
      .string()
      .required(t("validation.mian_account_is_required")),
    city: yup.string().required(t("validation.city_is_requiured")),
  });
  const queryClient = useQueryClient();
  const [valuee, setValuee] = React.useState("");
  const handleChange = (event) => {
    setValuee(event.target.value);
  };

  const [snack, setSnack] = useState(false);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  const updateHandler = (data) => {
    axios
      // .put(`https://vsoft.com-eg.net:4041/api/PriceLists/Update`, data)
      .then((res) => {
        setUpdateModalOpen(false);
        queryClient.invalidateQueries("gridtableaccount");
      })
      .catch((err) => {
        setSnack(true);
      });
  };
  useEffect(() => {
    setValues(currentRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow]);
  return (
    <div>
      <Modal open={updateModalOpen}>
        <form onSubmit={handleSubmit(updateHandler)}>
          <BoxModal>
            <TypographyHeadModal>
              {t("accounts.edit_account")}
            </TypographyHeadModal>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginX: "15px",
                marginBottom: "15px",
              }}
            >
              <Box
                className="item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "45%",
                  flexWrap: "wrap",
                }}
              >
                <StyledTypography>{t("accounts.account_no")}</StyledTypography>
                <Box sx={{ width: "60%" }}>
                  <StyledInputBase
                    {...register("branchID")}
                    placeholder={t("accounts.number")}
                  />
                </Box>
                <ErrorText>{errors.accountNo?.message}</ErrorText>
              </Box>
            </Box>
            {/* ======================================= */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginX: "15px",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <StyledTypography>
                  {t("accounts.english_name")}
                </StyledTypography>
                <Box sx={{ width: "60%" }}>
                  <StyledInputBase
                    {...register("fNameA")}
                    placeholder={t("accounts.name")}
                  />
                </Box>
                <ErrorText>{errors.fName?.message}</ErrorText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "45%",
                }}
              >
                <StyledTypography>{t("accounts.arabic_name")}</StyledTypography>
                <Box
                  sx={{
                    width: "60%",
                  }}
                >
                  <StyledInputBase
                    {...register("fName")}
                    placeholder={t("accounts.name")}
                  />
                </Box>
                <ErrorText>{errors.fNameA?.message}</ErrorText>
              </Box>
            </Box>
            {/* ======================================= */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginX: "15px",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <StyledTypography>
                  {t("accounts.contact_person")}
                </StyledTypography>
                <Box sx={{ width: "60%" }}>
                  <StyledInputBase
                    {...register("startOperating")}
                    placeholder={t("accounts.number")}
                  />
                </Box>
                <ErrorText>{errors.contactPerson?.message}</ErrorText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "45%",
                }}
              >
                <StyledTypography>{t("accounts.contact_no")}</StyledTypography>
                <Box
                  sx={{
                    width: "60%",
                  }}
                >
                  <StyledInputBase
                    {...register("zones")}
                    placeholder={t("accounts.number")}
                  />
                </Box>
                <ErrorText>{errors.contactNo?.message}</ErrorText>
              </Box>
            </Box>
            {/* ======================================= */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginX: "15px",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <StyledTypography>{t("accounts.address1")}</StyledTypography>
                <Box sx={{ width: "60%" }}>
                  <StyledInputBase
                    {...register("startOperating")}
                    placeholder={t("accounts.name")}
                  />
                </Box>
                <ErrorText>{errors.address1?.message}</ErrorText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "45%",
                }}
              >
                <StyledTypography>{t("accounts.address2")}</StyledTypography>
                <Box
                  sx={{
                    width: "60%",
                  }}
                >
                  <StyledInputBase
                    {...register("zones")}
                    placeholder={t("accounts.name")}
                  />
                </Box>
                <ErrorText>{errors.address2?.message}</ErrorText>
              </Box>
            </Box>

            {/* ======================================= */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginX: "15px",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <StyledTypography>
                  {t("accounts.main_account")}
                </StyledTypography>
                <Box
                  sx={{
                    width: "60%",
                  }}
                >
                  <StyledInputBase
                    {...register("zones")}
                    placeholder={t("accounts.name")}
                  />
                </Box>
                <ErrorText>{errors.mainAccount?.message}</ErrorText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "45%",
                }}
              >
                <StyledTypography>{t("accounts.city")}</StyledTypography>
                <Box sx={{ width: "60%" }}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Select
                      sx={{
                        border: "2px solid var(--secondary-color)",
                        borderRadius: "50px",
                        padding: "3px 35px",
                        display: "flex",
                        width: "100%",
                        minWidth: "220px",
                      }}
                      input={<InputBase />}
                      value={valuee}
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        <em>PriceList</em>
                      </MenuItem>
                      <MenuItem value="csv">Csv</MenuItem>
                      <MenuItem value="Excel">Excel</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <ErrorText>{errors.city?.message}</ErrorText>
              </Box>
            </Box>
            {/* ======================================= */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginX: "15px",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "48%",
                  alignItems: "center",
                }}
              >
                <StyledInputBase
                  {...register("notes")}
                  placeholder={t("accounts.online_notes")}
                  multiline
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-input": {
                      borderRadius: "10px !important",
                    },
                  }}
                  rows="5"
                />
              </Box>
            </Box>
            {/* ==================btn============= */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "2rem",
              }}
            >
              <StyledButton modal onClick={() => setUpdateModalOpen(false)}>
                {t("accounts.cancel")}
              </StyledButton>
              <StyledButton modal type="submit">
                {t("accounts.update")}
              </StyledButton>
            </Box>
          </BoxModal>
        </form>
      </Modal>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("accounts.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
