import { Alert, Box, Stack } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar } from "@mui/material";
import { BoxModalDel, StyledButton, TypographyHeadModal } from "../utilities";
import axios from "axios";
import { useState } from "react";
import { useQueryClient } from "react-query";
export default function SuspendClient({ handleCloseSuspend, currentRow }) {
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation("finance");
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };
  async function handleSuspend() {
    setDisable(true);
    setIsLoading(true);
    await axios
      .put(`https://vsoft.com-eg.net:4041/api/Clients/SetActive`, {
        fID: currentRow.fID,
        inActive: !currentRow.inActive,
      })
      .then((res) => {
        queryClient.invalidateQueries("ClientsDetails");
        setIsLoading(false);
        setDisable(false);
        handleCloseSuspend();
        currentRow.inActive = true;
      })
      .catch((err) => {
        setSnack(true);
        setIsLoading(false);
        setDisable(false);
      });
  }
  return (
    <div>
      <form onSubmit={handleSuspend}>
        <BoxModalDel>
          <Box
            sx={{
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
              margin: "20px 0 45px 0",
            }}
          >
            <WarningAmberIcon
              sx={{
                transform: "Scale(2.8)",
                margin: "0 30px 0 0",
                color: "#ffb121",
              }}
            />
            <TypographyHeadModal delmodal={true}>
              {currentRow.inActive === true
                ? t("clients.TypographyHeadModalunsup")
                : t("clients.TypographyHeadModalsup")}{" "}
            </TypographyHeadModal>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <StyledButton modal onClick={handleCloseSuspend}>
              {t("clients.close")}
            </StyledButton>
            <StyledButton modal disabled={disable} onClick={handleSuspend}>
              {isLoading ? (
                <CircularProgress />
              ) : currentRow.inActive === true ? (
                t("clients.unSuspend")
              ) : (
                t("clients.suspend")
              )}
            </StyledButton>
          </Box>
        </BoxModalDel>
      </form>

      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("clients.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
