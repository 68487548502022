import React, { useState, Suspense } from "react";
import styles from "../assets/css/modules/pages/Dashboard.module.css";
import styles2 from "../assets/css/modules/pages/OperationsList.module.css";
import { Loading } from "../components";
import { Input, Button, IconButton, Popover } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free-solid";
import cardsData from "../mocks/_generateCardData";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Card, RightSidedBar, Statistics } from "../components/dashboard";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DateRange from "../components/utilities/DateRange";
import { StyledButton } from "../components/utilities";

export default function Dashboard() {
  const [dashboardFilterSearch, setDashboardFilterSearch] = useState("");
  const [searchBarInput, setSearchBarInput] = useState(" ");

  const [date, setDate] = useState("");
  const { i18n } = useTranslation();
  const { language } = i18n;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <Suspense fallback={<Loading />}>
      <Box className={styles.dashboardContainer} sx={{ padding: { sm: 0 } }}>
        <div className={styles.dashboardInsights}>
          <div className={styles.header}>My Dashboard</div>
          <div className={styles.searchbar}>
            <form className={styles.filter}>
              <Input
                className={styles.inputField}
                size="big"
                disableUnderline
                placeholder="search for contacts..."
                value={dashboardFilterSearch}
                onChange={(e) => setDashboardFilterSearch(e.target.value)}
              />
              <div>
                <div className={styles2.iconContainer}>
                  <input
                    placeholder="Filter by Day"
                    type="text"
                    required
                    name="search"
                    value={
                      date
                        ? `From: ${date.from.getDate()} / ${
                            date.from.getMonth() + 1
                          } / ${date.from.getFullYear()}, To: ${date.to.getDate()} / ${
                            date.to.getMonth() + 1
                          } / ${date.to.getFullYear()}`
                        : "Filter by Day"
                    }
                    readOnly
                    className={styles2.input}
                  />
                  <div className={styles2.searchIcon}>
                    <IconButton onClick={handleClick} sx={{ color: "white" }}>
                      <DateRangeIcon />
                    </IconButton>
                  </div>
                </div>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <DateRange setDate={setDate} />
                </Popover>
              </div>
              <div>
                <StyledButton type="submit" className={`${styles.submitbtn} `}>
                  Search
                </StyledButton>
              </div>
            </form>
          </div>
          <div className={styles.cardGroup}>
            {cardsData.map((cardData) => (
              <Card data={cardData} key={cardData.title} />
            ))}
          </div>
          <Box sx={{ padding: { sm: "5px" } }}>
            <Statistics />
          </Box>
        </div>
        <Box
          component="div"
          sx={{
            right: language === "ar" ? "auto" : 0,
            left: language === "ar" ? 0 : "auto",
          }}
          className={styles.rightside}
        >
          <form className={`${styles.searchBardiv} d-flex position-relative`}>
            <Input
              className={styles.clientsSearchBar}
              style={{ padding: "20px" }}
              disableUnderline
              value={searchBarInput}
              size="md"
              placeholder="search for contacts"
              onChange={(e) => setSearchBarInput(e.target.value)}
            />
            <Button
              type="submit"
              className={`position-absolute end-0   ${styles.clientsSearchIcon}`}
            >
              <FontAwesomeIcon icon={["fas", "user"]} color="white" />
            </Button>
          </form>
          <RightSidedBar SearchBarInput={searchBarInput} />
        </Box>
      </Box>
    </Suspense>
  );
}
