import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
export default function Loading() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" component="h1">
          Loading....
        </Typography>
        <CircularProgress
          size={40}
          sx={{
            color: "var(--primary-color)",
          }}
        />
      </Box>
    </>
  );
}
