import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import View from "../../assets/images/Admin/Group 12149.png";
import Profile from "../../assets/images/Admin/profile.png";
import Account from "../../assets/images/Admin/Group 12158.png";
import { EditSvg, StyledCheckbox, DeleteSvg } from "../utilities";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import UpdateEmployeeModal from "./UpdateEmployeeModal";
import DeleteEmployeeModal from "./DeleteEmployeeModal";
import { useTranslation } from "react-i18next";

export default function GridTableEmployees({ pageName, identifier }) {
  const [pageSize, setPageSize] = React.useState(20);
  const [currentRow, setCurrentRow] = useState({});
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDelelteModalOpen] = useState(false);
  const navigateAccount = useNavigate();
  const navigateView = useNavigate();
  const navigateMore = useNavigate();
  const { t } = useTranslation("admin");

  const columns = [
    {
      field: "Code",
      headerName: t("employees.code"),
      width: 120,
      editable: false,
    },
    {
      field: "fName",
      headerName: t("employees.english_name"),
      width: 180,
      editable: false,
    },
    {
      field: "fNameA",
      headerName: t("employees.arabic_name"),
      width: 180,
      editable: false,
    },
    {
      field: "DeliveryCommission",
      headerName: t("Commission"),
      width: 200,
      editable: false,
    },
    {
      field: "HiringDate",
      headerName: t("employees.hiring_date"),
      width: 200,
      editable: false,
      renderCell: data => {
        return data.row.HiringDate.slice(0, 10);
      },
    },
    {
      field: "fPlayer",
      headerName: t("employees.representative"),
      width: 160,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <StyledCheckbox
              checked={row.fPlayer === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },
    {
      field: "sendSMS",
      headerName: t("employees.send_sms"),
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <StyledCheckbox
              checked={row.sendSMS === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },
    {
      field: "action",
      headerName: t("employees.actions"),
      width: 220,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <Box
              sx={{
                display: "flex",
                gap: "0.25rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => {
                  navigateView(`/admin/view-profile/${row[identifier]} `);
                }}
              >
                <img
                  src={View}
                  style={{ width: "33px", cursor: "pointer" }}
                  alt=""
                />
              </Box>
              <Box
                onClick={() => {
                  setUpdateModalOpen(true);
                  setCurrentRow(row);
                }}
              >
                <EditSvg />
              </Box>
              <Box
                onClick={() => {
                  setDelelteModalOpen(true);
                  setCurrentRow(row);
                }}
              >
                <DeleteSvg />
              </Box>
              <Box
                onClick={() => {
                  navigateAccount(`/admin/${pageName}/${row[identifier]} `);
                }}
              >
                <img
                  src={Profile}
                  style={{ width: "33px", cursor: "pointer" }}
                  alt=""
                />
              </Box>
              <Box
                onClick={() => {
                  navigateMore(`/admin/more-information/${row[identifier]} `);
                }}
              >
                <img
                  src={Account}
                  style={{ width: "33px", cursor: "pointer" }}
                  alt=""
                />
              </Box>
            </Box>
          </strong>
        );
      },
    },
  ];
  const { isLoading, data } = useQuery(
    "gridTableEmployees",
    async () => {
      const res = await axios.post(
        `https://vsoft.com-eg.net:4041/api/Employees/GetPage`,
        {
          fromDate: "2023-03-27T12:46:24.564Z",
          toDate: "2023-03-27T12:46:24.564Z",
          serial: 0,
          pageParam: {
            pageSize: 0,
            pageNumber: 0,
          },
          language: "string",
        }
      );
      console.log(res.data.data);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div>
      <div>
        <Box sx={{ height: "650px", width: "100%" }}>
          <DataGrid
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            loading={isLoading}
            rows={data ?? []}
            columns={columns}
            getRowId={rowData => {
              return rowData.fID;
            }}
          />
        </Box>
      </div>
      <UpdateEmployeeModal
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        currentRow={currentRow}
      />
      <DeleteEmployeeModal
        deleteModalOpen={deleteModalOpen}
        setDelelteModalOpen={setDelelteModalOpen}
        currentRow={currentRow}
      />
    </div>
  );
}
