import { React, useEffect } from "react";
import { Box, FormLabel } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  StyledButton,
  TypographyHeader,
  StyledInputBase,
  Card,
  SelectInputGroup,
} from "../../components/utilities";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useGetRunners } from "../../Queries/operation";
import styled from "styled-components";
const StyledLabel = styled(FormLabel)`
  font-size: 18px;
  font-weight: 400;
  color: #252e48 !important;
  text-transform: capitalize;
`;
const StyledLabelshow = styled.label`
  border: none;
  min-width: 120px;
  height: 60px;
  background: var(--body-color);
  border-radius: 50px;
  text-align: center !important;
  font: normal normal bold 25px "Roboto";
  color: var(--primary-color);
  text-transform: capitalize;
  :focus {
    outline: none;
  }
`;
export default function RunnerActivityInquiry() {
  const { t } = useTranslation("report");
  const [pageSize, setPageSize] = useState(20);
  const { data: runnersData } = useGetRunners();
  useEffect(() => {
    if (runnersData) {
      setRunnersRow(
        runnersData.data.map((runner) => ({
          name: runner.englishName,
          id: runner.id,
        }))
      );
    }
  }, [runnersData]);
  const [runnersRow, setRunnersRow] = useState([]);
  const columns = [
    {
      field: "courier",
      headerName: "Courier",
      width: 160,
      editable: false,
    },
    {
      field: "total",
      headerName: "Total",
      width: 160,
      editable: false,
    },
    {
      field: "done",
      headerName: "Done",
      width: 160,
      editable: false,
    },
    {
      field: "notDone",
      headerName: "Not Done",
      width: 160,
      editable: false,
    },
    {
      field: "outOfDelivery",
      headerName: "Out Of Delivery",
      width: 160,
      editable: false,
    },
    {
      field: "percent",
      headerName: "Percent",
      width: 160,
      editable: false,
    },
  ];
  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      runner: "",
    },
  });
  const { isLoading, data } = useQuery(
    "gridtableRunnerActivityInquiry",
    async () => {
      const res = await axios.post(
        // `https://vsoft.com-eg.net:4041/api/Branches/GetPage`,
        {
          pageSize: 1000,
          pageNumber: 0,
        }
      );
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <TypographyHeader>
          {t("runner_activity_inquiry.runner_activity_inquiry")}
        </TypographyHeader>
      </Box>
      <form>
        <FormProvider {...methods}>
          <Card width={"100%"}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                rowGap: "30px ",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>
                    {t("runner_activity_inquiry.runner")}
                  </StyledLabel>
                  <SelectInputGroup
                    name="runner"
                    items={runnersRow}
                    placeholder={t("runner_activity_inquiry.runner")}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  columnGap: "22px",
                  rowGap: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("runner_activity_inquiry.from")}</StyledLabel>
                  <StyledInputBase
                    sx={{ width: "255px" }}
                    type="datetime-local"
                  />
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("runner_activity_inquiry.to")}</StyledLabel>
                  <StyledInputBase
                    type="datetime-local"
                    sx={{ width: "255px" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <StyledButton sx={{ width: "255px !important" }}>
                    {t("runner_activity_inquiry.show")}
                  </StyledButton>
                </Box>
              </Box>
            </Box>
          </Card>
        </FormProvider>
      </form>
      {/* ============================================================================ */}
      <Card width={"100%"}>
        <Box padding={"0 10px"}>
          <StyledLabel sx={{ fontWeight: "500 !important" }}>
            {t("runner_activity_inquiry.summary")}
          </StyledLabel>
          <Box
            sx={{
              padding: "10px 0px 0px 30px",
              width: "100%",
              display: "flex",
              rowGap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  minWidth: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "5px",
                }}
              >
                <StyledLabel>{t("runner_activity_inquiry.total")}</StyledLabel>
                <StyledLabelshow />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                columnGap: "60px",
                rowGap: "20px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  minWidth: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "5px",
                }}
              >
                <StyledLabel>{t("runner_activity_inquiry.done")}</StyledLabel>
                <StyledLabelshow />
              </Box>
              <Box
                sx={{
                  width: "20%",
                  minWidth: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "5px",
                }}
              >
                <StyledLabel>
                  {t("runner_activity_inquiry.not_done")}
                </StyledLabel>
                <StyledLabelshow />
              </Box>
              <Box
                sx={{
                  width: "20%",
                  minWidth: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "5px",
                }}
              >
                <StyledLabel>
                  {t("runner_activity_inquiry.with_runner")}
                </StyledLabel>
                <StyledLabelshow />
              </Box>
              <Box
                sx={{
                  width: "20%",
                  minWidth: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "5px",
                }}
              >
                <StyledLabel>
                  {t("runner_activity_inquiry.percent")}
                </StyledLabel>
                <StyledLabelshow />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
      <Box sx={{ display: "flex", gap: "5%" }}>
        <Box sx={{ height: "650px", width: "70%" }}>
          <DataGrid
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            loading={isLoading}
            rows={data ?? []}
            columns={columns}
            getRowId={(rowData) => {
              return rowData.awb;
            }}
          />
        </Box>
        <Box sx={{ height: "650px", width: "25%" }}>
          <DataGrid
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            loading={isLoading}
            rows={data ?? []}
            columns={columns}
            getRowId={(rowData) => {
              return rowData.awb;
            }}
          />
        </Box>
      </Box>
    </div>
  );
}
