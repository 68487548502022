import { Box } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { StyledButton, StyledCheckbox } from "../utilities";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 400;
  width: 60%;
  text-transform: capitalize;
`;
const formContainer = {
  width: "30%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
};
export default function StepperActionForm({
  handleFormSubmit,
  previousStep,
  activeStep,
  setStepperData,
  stepperData,
}) {
  const { t } = useTranslation("finance");
  const { register, handleSubmit } = useForm();
  const submitForm = (data) => {
    handleFormSubmit(data);
  };
  const test = () => {
    console.log(stepperData);
  };
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "25px",
          margin: "50px 5px 0 5px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="webLogin">
              {t("clients.add_fees_to_cod")}
            </StyledLabel>
            <Box sx={{ width: "40%" }}>
              <StyledCheckbox
                id="webLogin"
                onChange={() => {
                  setStepperData({ ...stepperData.action });
                }}
                {...register("webLogin")}
              />
            </Box>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="applyReturnCost">
              {t("clients.apply_return_cost")}
            </StyledLabel>
            <Box sx={{ width: "40%" }}>
              <StyledCheckbox
                id="applyReturnCost"
                onChange={() => {
                  setStepperData({ ...stepperData.action });
                }}
                {...register("applyReturnCost")}
              />
            </Box>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="inActive">
              {t("clients.inactive")}
            </StyledLabel>
            <Box sx={{ width: "40%" }}>
              <StyledCheckbox id="inActive" {...register("inActive")} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="cashPayment">
              {t("clients.cash_payment")}
            </StyledLabel>
            <Box sx={{ width: "40%" }}>
              <StyledCheckbox id="cashPayment" {...register("cashPayment")} />
            </Box>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="bankPayment">
              {t("clients.bank_payment")}
            </StyledLabel>
            <Box sx={{ width: "40%" }}>
              <StyledCheckbox id="bankPayment" {...register("bankPayment")} />
            </Box>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="sendSMS">{t("clients.send_sms")}</StyledLabel>
            <Box sx={{ width: "40%" }}>
              <StyledCheckbox id="sendSMS" {...register("sendSMS")} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          flexWrap: "wrap",
          marginTop: "2rem",
        }}
      >
        <StyledButton
          onClick={() => previousStep()}
          disabled={activeStep === 0}
        >
          Back
        </StyledButton>
        <StyledButton onClick={test} type="submit">
          Finish
        </StyledButton>
      </Box>
    </form>
  );
}
