import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useQuery } from "react-query";
import { TypographyHeader } from "../utilities";
import { useTranslation } from "react-i18next";
import { NotFound } from "../../pages";
import Loading from "../Loading";
import { useParams } from "react-router-dom";
export default function DetailsPayToCust() {
  const { t } = useTranslation("finance");
  const [pageSize, setPageSize] = React.useState(20);
  const { transID } = useParams();

  const columns = [
    {
      field: "awb",
      headerName: "AWB",
      editable: false,
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
    {
      field: "cod",
      headerName: "COD",
      editable: false,
      width: 120,
      minWidth: 120,
      maxWidth: 150,
    },
    {
      field: "transportation",
      headerName: "Transportation",
      editable: false,
      width: 150,
      minWidth: 150,
      maxWidth: 170,
    },
    {
      field: "netCod",
      headerName: "Net COD",
      editable: false,
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
    {
      field: "customer",
      headerName: "Customer",
      editable: false,
      width: 140,
      minWidth: 140,
      maxWidth: 130,
    },
    {
      field: "consignee",
      headerName: "Consignee",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 130,
    },
    {
      field: "ref",
      headerName: "Ref",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 130,
    },

    {
      field: "subAccount",
      headerName: "Sub Account",
      editable: false,
      width: 180,
      minWidth: 180,
      maxWidth: 190,
    },
    {
      field: "product",
      headerName: "Product",
      editable: false,
      width: 160,
      minWidth: 160,
      maxWidth: 170,
    },
    {
      field: "zone",
      headerName: "Zone",
      editable: false,
      width: 160,
      minWidth: 160,
      maxWidth: 170,
    },
  ];
  const { isError, isLoading, data } = useQuery(
    "gridtablePaytocustomer",
    async () => {
      const data = await axios.get(
        `https://vsoft.com-eg.net:4041/api/PayToCustDtls/GetByID/${transID}`,
        {
          pageSize: 1000,
          pageNumber: 0,
        }
      );
      console.log(data.data.data);
      return data.data.data;
    },
    { refetchOnWindowFocus: false }
  );
  if (isError) {
    return <NotFound />;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <TypographyHeader>{t("paytocust.dtl_pay_to_cust")}</TypographyHeader>
      <div>
        <Box sx={{ height: "650px", width: "100%" }}>
          <DataGrid
            loading={isLoading}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            rows={data ?? []}
            columns={columns}
            getRowId={(rowData) => {
              return rowData.transID;
            }}
            hideFooterSelectedRowCount
          />
        </Box>
      </div>
    </div>
  );
}
