import React from "react";
import {
  Box,
  Modal,
  Snackbar,
  Stack,
  Alert,
  CircularProgress,
} from "@mui/material";
import { StyledButton, BoxModalDel, TypographyHeadModal } from "../utilities";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useState } from "react";
export default function DeleteEmployeeModal(props) {
  const { t } = useTranslation("admin");
  const queryClient = useQueryClient();
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = (employee) => {
    if (employee === "clickaway") {
      return;
    }
    setSnack(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    setIsLoading(true);
    axios
      .delete(
        `https://vsoft.com-eg.net:4041/api/Employees/Delete/${props.currentRow.fID}`
      )
      .then((res) => {
        props.setDelelteModalOpen(false);
        queryClient.invalidateQueries("gridTableEmployees");
        setIsLoading(false);
        setDisable(false);
      })
      .catch((err) => {
        setSnack(true);
        setIsLoading(false);
        setDisable(false);
      });
  };
  return (
    <div>
      <Modal open={props.deleteModalOpen}>
        <form onSubmit={handleSubmit}>
          <BoxModalDel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0 45px 0",
              }}
            >
              <WarningAmberOutlinedIcon
                sx={{
                  transform: "Scale(2.8)",
                  margin: "0 30px 0 0",
                  color: "#ffb121",
                }}
              />
              <TypographyHeadModal delmodal={true}>
                {t("employees.delete_confirmation_employee")}
              </TypographyHeadModal>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <StyledButton
                modal
                onClick={() => {
                  props.setDelelteModalOpen(false);
                }}
              >
                {t("employees.close")}
              </StyledButton>
              <StyledButton modal disabled={disable} type="submit">
                {isLoading ? <CircularProgress /> : t("employees.delete")}
              </StyledButton>
            </Box>
          </BoxModalDel>
        </form>
      </Modal>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("employees.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
