import React from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClientsDataGrid from "../../components/Finance/ClientsDataGrid";
import { StyledButton, TypographyHeader } from "../../components/utilities";
import { useNavigate } from "react-router-dom";

export default function Clients() {
  const { t } = useTranslation("finance");
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#EEF2F6",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "25px",
        }}
      >
        <Grid item xs={10}>
          <TypographyHeader>{t("clients.clients")}</TypographyHeader>
        </Grid>
        <Grid item xs={2}>
          <StyledButton
            onClick={() => {
              navigate("/finance/clients/add-client");
            }}
          >
            {t("clients.add_clients")}
          </StyledButton>
        </Grid>
      </Grid>
      <ClientsDataGrid />
    </Box>
  );
}
