import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducerToolKit from "./reducers/authReducerToolKit";

// const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const rootReducer = combineReducers({
  auth: authReducerToolKit,
});

const store = configureStore({
  reducer: {
    rootReducer,
  },
});

export default store;
