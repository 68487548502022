import React from "react";
import { useFormContext } from "react-hook-form";
import styles from "../../assets/css/modules/TextAreaInputGroup.module.css";
import StyledInputBase from "./InputBase";
export default function TextAreaInputGroup(props) {
  const methods = useFormContext();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputGroup}>
          <label htmlFor={props.name} className={styles.label}>
            {props.label}
          </label>
          <StyledInputBase
            id={props.name}
            multiline
            sx={{
              "& .MuiInputBase-input": {
                width: "182px",
                borderRadius: "10px !important",
              },
            }}
            placeholder={props.placeholder}
            {...methods.register(props.name)}
            rows={props.rows ? props.rows : "4"}
            onKeyPress={props.onKeyPress}
          />
        </div>
        {props.error ? (
          <p className={styles.error}>{props.error.message}</p>
        ) : null}
      </div>
    </>
  );
}
