import { Box, Typography } from "@mui/material";
import React from "react";

export default function UploadImgSvg() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44.064"
        height="28.327"
        viewBox="0 0 44.064 28.327"
      >
        <path
          id="upload"
          d="M38.258,19.8c0-.118.02-.236.02-.354A11.472,11.472,0,0,0,26.9,7.875a11.347,11.347,0,0,0-10.259,6.59A5.831,5.831,0,0,0,14,13.826,5.9,5.9,0,0,0,8.22,18.793,8.962,8.962,0,0,0,11.053,36.2H21.135V28.333H16.394L24.282,20.1l7.888,8.222H27.429v7.869H38.278a8.2,8.2,0,0,0-.02-16.4Z"
          transform="translate(-2.25 -7.875)"
          fill="#828eae"
        />
      </svg>
      <Typography
        sx={{ color: "#828eae", fontSize: "15px", marginTop: "0.5rem" }}
      >
        Upload Logo
      </Typography>
    </Box>
  );
}
