import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { DeleteSvg, EditSvg, DetailsSvg, StyledCheckbox } from "../utilities";
import UpdatePayModal from "./UpdatePayModal";
import DeletePayModal from "./DeletePayModal";
import { useTranslation } from "react-i18next";
export default function PendingGridPayToCustomer({ PendingData, isLoading }) {
  const [pageSize, setPageSize] = React.useState(20);
  const [currentRow, setCurrentRow] = useState({});
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [openDeleteIcon, setOpenDeleteIcon] = React.useState(false);
  const columns = [
    {
      field: "TransID",
      headerName: "Trans ID",
      editable: false,
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
    {
      field: "fDate",
      headerName: "Date",
      editable: false,
      width: 200,
      minWidth: 200,
      maxWidth: 220,
      // dataType: "data",
    },
    {
      field: "customer",
      headerName: "Customer",
      editable: false,
      width: 140,
      minWidth: 140,
      maxWidth: 130,
    },
    {
      field: "Discount",
      headerName: "Discount",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 130,
    },
    {
      field: "total",
      headerName: "Total COD",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 130,
    },
    {
      field: "transportation",
      headerName: "Transportation",
      editable: false,
      width: 150,
      minWidth: 150,
      maxWidth: 170,
    },
    {
      field: "netCod",
      headerName: "Net COD",
      editable: false,
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
    {
      field: "Posted",
      headerName: "Posted",
      editable: false,
      width: 100,
      minWidth: 100,
      maxWidth: 110,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <StyledCheckbox
              checked={row.Posted === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },
    {
      field: "PostedBy",
      headerName: "Posted By",
      editable: false,
      width: 130,
      minWidth: 130,
      maxWidth: 150,
    },
    {
      field: "PostDate",
      headerName: "Post Date",
      editable: false,
      width: 200,
      minWidth: 200,
      maxWidth: 220,
    },
    {
      field: "AWBsCreated",
      headerName: "Awbs Created",
      editable: false,
      width: 140,
      minWidth: 140,
      maxWidth: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <StyledCheckbox
              checked={row.AWBsCreated === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },
    {
      field: "deliveryTransNo",
      headerName: "Delivery Trans. No.",
      editable: false,
      width: 180,
      minWidth: 180,
      maxWidth: 190,
    },
    {
      field: "Notes",
      headerName: "Notes",
      editable: false,
      width: 160,
      minWidth: 160,
      maxWidth: 170,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      minWidth: 150,
      maxWidth: 160,
      renderCell: ({ row }) => {
        return (
          <strong>
            <Box
              sx={{
                display: "flex",
                gap: "0.3rem",
              }}
            >
              {/* // =============================Edit Button============================= */}
              <Box
                onClick={() => {
                  setUpdateModalOpen(true);
                  setCurrentRow(row);
                }}
              >
                <EditSvg />
              </Box>
              {/* // =============================Delete Button============================= */}
              <Box
                onClick={() => {
                  setOpenDeleteIcon(true);
                  setCurrentRow(row);
                }}
              >
                <DeleteSvg />
              </Box>
              {/* ===============================Details Button============================= */}
              <Box
                component={Link}
                to={`/finance/paytocust/${parseInt(row.transID)}`}
              >
                <DetailsSvg />
              </Box>
            </Box>
          </strong>
        );
      },
    },
  ];
  return (
    <>
      <Box sx={{ height: 650, width: "100%" }}>
        <DataGrid
          sx={{ height: 440, mt: 2 }}
          loading={isLoading}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[20, 50, 100]}
          rows={PendingData}
          columns={columns}
          getRowId={(rowData) => {
            return (rowData = Math.floor(Math.random() * 100000));
          }}
          hideFooterSelectedRowCount
        />
      </Box>
      <UpdatePayModal
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        currentRow={currentRow}
      />
      <DeletePayModal
        openDeleteIcon={openDeleteIcon}
        setOpenDeleteIcon={setOpenDeleteIcon}
        currentRow={currentRow}
      />
    </>
  );
}
