import { Checkbox } from "@mui/material";
import styled from "styled-components";

const StyledCheckbox = styled(Checkbox)`
  color: var(--secondary-color);
  &.Mui-checked {
    color: var(--secondary-color);
  }
`;
export default StyledCheckbox;
