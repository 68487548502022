import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  RunnerActivityInquiry,
  EmployeeActivityInquiry,
  PaymentStatusTracking,
  TrackRunners,
  PaymentsBulk,
} from "../../pages/Reports";
import ComingSoon from "../../pages/ComingSoon";
export default function Reports() {
  return (
    <Routes>
      <Route path="*" element={<ComingSoon />} />
      <Route
        path="payment-status-tracking"
        element={<PaymentStatusTracking />}
      />

      <Route
        path="runner-activity-inquiry"
        element={<RunnerActivityInquiry />}
      />
      <Route
        path="employee-activity-inquiry"
        element={<EmployeeActivityInquiry />}
      />
      <Route path="track-runners" element={<TrackRunners />} />

      <Route path="payments-bulk" element={<PaymentsBulk />} />
    </Routes>
  );
}
