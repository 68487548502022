import { Button } from "@mui/material";
import styled from "styled-components";
const ButtonExcel = styled(Button)`
  min-width: 240px;
  height: 50px;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff 0% 0% no-repeat padding-box;
  border: 2px solid var(--secondary-color);
  border-radius: 30px;
  text-align: left;
  color: #252e48;
  font: normal normal bold 20px "Roboto";
  text-transform: capitalize;
`;

export default ButtonExcel;
