import React from "react";
import { Routes, Route } from "react-router-dom";
import { NotFound } from "../../pages";
import { CashCollection, PayToCust } from "../../pages/Finance";
import Clients from "../../pages/Finance/Clients";
import AddingModalClients from "../Finance/AddingModalClients";
import AddNewCollection from "../Finance/AddNewCollection";
import AddpayModal from "../Finance/AddPayModal";
import ClientsDetails from "../Finance/ClientsDetails";
import DetailsPayToCust from "../Finance/DetailsPayToCust";

export default function Finance() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/clients/add-client" element={<AddingModalClients />} />
      <Route path="/clients/:fID" element={<ClientsDetails />} />
      <Route path="/cash-collection" element={<CashCollection />} />
      <Route
        path="/cash-collection/addcollection"
        element={<AddNewCollection />}
      />
      <Route path="/paytocust" element={<PayToCust />} />
      <Route path="/paytocust/addpay" element={<AddpayModal />} />
      <Route path="/paytocust/:transID" element={<DetailsPayToCust />} />
    </Routes>
  );
}
