import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { InputBase, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "../../assets/css/modules/InputGroup.module.css";
import ErrorText from "./ErrorText";

const InputStyle = styled(InputBase)(({ theme }) => ({
  " .MuiInputBase-input": {
    borderRadius: 50,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid var(--secondary-color);",
    transition: "box-shadow .3s",
    padding: "7px 30px",
    "&:focus": {
      boxShadow: "0 0 0 1px var(--secondary-color)",
      borderRadius: 50,
    },
  },
}));

export default function SelectInputGroup(props) {
  const { control } = useFormContext();
  return (
    <div className={styles.container}>
      <div className={styles.inputGroup}>
        <label className={styles.label}>{props.label}</label>
        <Controller
          name={props.name}
          control={control}
          rules={
            props.applyRules ? { required: `${props.errorMassage}` } : null
          }
          render={({ field }) => (
            <Select
              {...field}
              displayEmpty
              sx={{ width: "255px", minWidth: "255px" }}
              input={<InputStyle />}
            >
              <MenuItem sx={{ color: "#828EAE" }} value={""} disabled>
                {props.placeholder}
              </MenuItem>
              {props.items.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
      </div>
      {props.error ? <ErrorText>{props.error.message}</ErrorText> : null}
    </div>
  );
}
