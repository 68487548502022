import { Button } from "@mui/material";
import styled from "styled-components";
const StyledButton = styled(Button)`
  width: ${props => (props.modal ? "200px" : "220px")};
  min-width: 150px;
  height: 40px;
  background: ${props =>
    props.green
      ? props.color
      : `transparent
      var(--gradient)`};

  border: 0;
  border-radius: 30px;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
`;

export default StyledButton;
