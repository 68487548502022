import React from "react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Box,
  Modal,
  Alert,
  Stack,
  Snackbar,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import {
  StyledButton,
  TypographyHeadModal,
  StyledCheckbox,
  StyledInputBase,
  BoxModal,
  ErrorText,
} from "../utilities";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 400;
  width: 40%;
`;

export default function UpdateEmployeeModal({
  currentRow,
  updateModalOpen,
  setUpdateModalOpen,
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation("admin");
  const schema = yup.object().shape({
    code: yup.string().required(t("validation.code_msg")),
    fName: yup.string().required(t("validation.fName_msg_required")),
    fNameA: yup.string().required(t("validation.fNameA_msg_required")),
    branchID: yup.string().required(t("validation.branch_msg_required")),
    hiringDate: yup.string().required(t("validation.date_msg_required")),
    dateOfBirth: yup.string().required(t("validation.date_msg_required")),
  });
  const [snack, setSnack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const { data: branchesData } = useQuery("branches_list", async () => {
    const data = await axios.post(
      "https://vsoft.com-eg.net:4041/api/Branches/GetList",
      {
        pageSize: 1000,
        pageNumber: 0,
      }
    );
    return data.data.data;
  });
  const handleClose = (employee) => {
    if (employee === "clickaway") {
      return;
    }
    setSnack(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  async function updateHandler(data) {
    setIsLoading(true);
    await axios
      .put(`https://vsoft.com-eg.net:4041/api/Employees/Update`, data)
      .then((res) => {
        queryClient.invalidateQueries("gridTableEmployees");
        setUpdateModalOpen(false);
        setIsLoading(false);
        setDisable(false);
      })
      .catch((err) => {
        setSnack(true);
        setIsLoading(false);
        setDisable(false);
      });
  }
  useEffect(() => {
    setValues(currentRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow]);
  return (
    <div>
      <Modal open={updateModalOpen}>
        <form onSubmit={handleSubmit(updateHandler)}>
          <BoxModal>
            <TypographyHeadModal>
              {t("employees.add_employees")}
            </TypographyHeadModal>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "25px",
              }}
            >
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="code">
                    {t("employees.code")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="code"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("code")}
                      type="number"
                      placeholder={t("employees.code")}
                    />
                  </Box>
                  <ErrorText>{errors.code?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="english_name">
                    {t("employees.english_name")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="english_name"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("fName")}
                      placeholder={t("employees.name")}
                    />
                  </Box>
                  <ErrorText>{errors.fName?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="arabic_name">
                    {t("employees.arabic_name")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="arabic_name"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("fNameA")}
                      placeholder={t("employees.name")}
                    />
                  </Box>
                  <ErrorText>{errors.fNameA?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="delivery_commission">
                    {t("employees.delivery_commission")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="delivery_commission"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("deliveryCommission")}
                      type="number"
                      placeholder={t("employees.number")}
                    />
                  </Box>
                  <ErrorText>{errors.deliveryCommission?.message}</ErrorText>
                </Box>
                <Box
                  sx={{
                    width: "36%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledLabel htmlFor="representative">
                    {t("employees.representative")}
                  </StyledLabel>
                  <Box sx={{ width: "20%" }}>
                    <StyledCheckbox
                      id="representative"
                      {...register("fPlayer")}
                      defaultChecked={currentRow.fPlayer}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel>{t("employees.branch")}</StyledLabel>
                  <Box sx={{ width: "60%", minWidth: "150px !important" }}>
                    <Select
                      displayEmpty
                      sx={{ width: "100%" }}
                      input={<StyledInputBase {...register("branchID")} />}
                    >
                      <MenuItem sx={{ color: "#828EAE" }} value={""} disabled>
                        Branch
                      </MenuItem>
                      {branchesData &&
                        branchesData.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id || ""}>
                              {item.englishName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Box>
                  <ErrorText>{errors.branchID?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="hiring_date">
                    {t("employees.hiring_date")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="hiring_date"
                      sx={{ width: "100%" }}
                      {...register("hiringDate")}
                      type="date"
                    />
                  </Box>
                  <ErrorText>{errors.hiringDate?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="birth_date">
                    {t("employees.birth_date")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="birth_date"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("dateOfBirth")}
                      type="date"
                    />
                  </Box>
                  <ErrorText>{errors.dateOfBirth?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "36%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="send_sms">
                    {t("employees.send_sms")}
                  </StyledLabel>
                  <Box sx={{ width: "20%" }}>
                    <StyledCheckbox
                      id="send_sms"
                      {...register("sendSMS")}
                      defaultChecked={currentRow.sendSMS}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* ===================btn================ */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "1rem",
              }}
            >
              <StyledButton modal onClick={() => setUpdateModalOpen(false)}>
                {t("employees.cancel")}
              </StyledButton>
              <StyledButton modal disabled={disable} type="submit">
                {isLoading ? <CircularProgress /> : t("employees.update")}
              </StyledButton>
            </Box>
          </BoxModal>
        </form>
      </Modal>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("employees.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
