import React, { useState } from "react";
import { Box, ButtonBase, Drawer, Grid } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  StyledInputBase,
  StyledButton,
  TypographyHeader,
} from "../../components/utilities";
import TextAreaInputGroup from "../../components/utilities/TextAreaInputGroup";
import DataGridPaymentsBulk from "../../components/Operations/Pages/DataGridPaymentsBulk";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import MyModal from "../../components/Operations/MyModal";
import BulkShipmentsFilter from "../../components/Operations/BulkShipmentsFilter";
const formStyle = {
  width: "25%",
  minWidth: "220px",
};
const alignForm = {
  display: "flex",
  alignItems: "base-line",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "0.5em",
  flexWrap: "wrap",
};

const formFont = {
  fontSize: "1em",
  color: "#252E48",
  letterSpacing: 0,
};

const formContainer = {
  width: "40%",
  minWidth: "240px",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
};
const filterContainer = {
  width: "100%",
  minWidth: "240px",
  display: "grid",
  placeItems: "center",
  margin: "10px",
};

const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 500;
  color: #252e48 !important;
  text-transform: capitalize;
`;
export default function ShipmentsBulk() {
  const { t } = useTranslation("operations_separated");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const methodsAwb = useForm({
    mode: "onTouched",
    defaultValues: {
      cell: "",
      status: "",
    },
  });
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors },
  } = methodsAwb;
  const awbSchema = {
    fromDate: "2022-03-28T12:12:33.014Z",
    toDate: "2023-03-28T12:12:33.014Z",
    pageParam: {
      pageSize: 100,
      pageNumber: 0,
    },
  };
  const submitMulti = async data => {
    const splitted = getValues("awBs").split(/\n/);
    let tmpo = splitted.join(",");
    console.log(data);
    if (splitted.length > 0 || data.pickupNo) {
      await axios
        .post(`https://vsoft.com-eg.net:4041/api/Shipments/GetShipments`, {
          ...awbSchema,
          awBs: tmpo,
          // drs:data.drs,
          // runner:data.runner,
          pickupNo: data.pickupNo ? data.pickupNo : 0,
        })
        .then(res => {
          setData(res.data.data);
          reset();
        })
        .catch(err => {});
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "25px",
          flexWrap: "Wrap",
        }}
      >
        <TypographyHeader>Payments Inquiry</TypographyHeader>
        {/* ========================================================================================================= */}
        <form onSubmit={handleSubmit(submitMulti)}>
          <FormProvider {...methodsAwb}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                gap: "5rem",
                flexWrap: "wrap",
              }}
            >
              <Box>
                <Box container sx={formContainer}>
                  <Grid container sx={formStyle}>
                    <Grid item xs={5} sx={alignForm}>
                      <StyledLabel sx={formFont}>
                        {t("bulk_shipments.drs")}
                      </StyledLabel>
                    </Grid>
                    <Grid item xs={7} sx={alignForm}>
                      <StyledInputBase
                        placeholder={t("bulk_shipments.drs")}
                        {...register("drs")}
                      />
                    </Grid>
                    {/* <ErrorText>{errors.awb?.message}</ErrorText> */}
                  </Grid>
                </Box>
                <Box container sx={formContainer}>
                  <Grid container sx={formStyle}>
                    <Grid item xs={5} sx={alignForm}>
                      <StyledLabel sx={formFont}>
                        {t("bulk_shipments.runner")}
                      </StyledLabel>
                    </Grid>
                    <Grid item xs={7} sx={alignForm}>
                      <StyledInputBase
                        placeholder={t("bulk_shipments.runner")}
                        {...register("runner")}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box>
                <TextAreaInputGroup
                  name=""
                  label=""
                  placeholder="Payments"
                  rows="5"
                  {...register("awBs")}
                />
              </Box>
            </Box>
            <Box container sx={filterContainer}>
              <StyledButton type="submit">
                {t("bulk_shipments.search")}
              </StyledButton>
            </Box>
          </FormProvider>
        </form>
        {/* ========================================================================================================= */}
        {/* <Box
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0.7 1rem",
            alignItems: "center",
          }}
        >
          {/* <Box container sx={formContainer}>
            <Grid container sx={formStyle}>
              <Grid item xs={5} sx={alignForm}>
                <StyledLabel sx={formFont}>
                  {t("bulk_shipments.refs")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7} sx={alignForm}>
                <StyledInputBase placeholder={t("bulk_shipments.ref")} />
              </Grid>
            </Grid>
          </Box> */}
        {/* ====================================================================== */}
        {/* </Box> */}
        {/* ========================================================================================================= */}
        <DataGridPaymentsBulk
          data={data}
          pageName="payments"
          identifier="AWB"
        />
      </Box>
      {/* <Box>
        <Drawer
          sx={{
            width: "400px !important",
            "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
              width: "400px",
              borderRadius: "none",
            },
            "& .MuiPaper-root": {
              borderRadius: "0",
            },
          }}
          anchor="right"
          open={draw}
          onClose={() => handleCloseDraw()}
        >
          <BulkShipmentsFilter />
        </Drawer>
      </Box> */}
      {/* <MyModal
        open={open}
        setOpen={setOpen}
        transactionData={transactionData}
        setTransactionData={setTransactionData}
        cb={multiCallBack}
      /> */}
    </Box>
  );
}
