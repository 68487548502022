import { React, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import NotFound from "../../../pages/NotFound";
import Loading from "../../Loading";

export default function DataGridPaymentsBulk(props) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const { pageName, identifier } = props;
  const searchData = props.data;
  const { data, isLoading, isError } = useQuery(
    "Bulk Shipments",
    async () => {
      const data = await axios.post(
        "https://vsoft.com-eg.net:4041/api/Shipments/GetShipments",
        {
          fromDate: "2022-03-28T12:12:33.014Z",
          toDate: "2023-03-28T12:12:33.014Z",
          pageParam: {
            pageSize: 100,
            pageNumber: 0,
          },
        }
      );
      return data.data.data;
    },
    { refetchOnWindowFocus: false }
  );
  if (isError) {
    return <NotFound />;
  }
  if (isLoading) {
    return <Loading />;
  }
  const columns = [
    {
      field: "AWB",
      headerName: "Payment Number",
      width: 210,
      editable: false,
    },
    {
      field: "ClientName",
      headerName: "Client",
      width: 210,
      editable: false,
    },
    {
      field: "StatusNameA",
      headerName: "Status",
      width: 210,
      editable: false,
    },
    {
      field: "ToAddress",
      headerName: "Address",
      type: "text",
      width: 210,
      editable: false,
    },
    {
      field: "COD",
      headerName: "Amount",
      width: 210,
      editable: false,
    },
  ];
  const AwbCells = document.querySelectorAll('[data-field="AWB"]');
  if (AwbCells.length) {
    for (let awbCell of AwbCells) {
      awbCell.style.color = "var(--primary-color)";
      awbCell.style.cursor = "pointer";
      awbCell.addEventListener("mouseenter", () => {
        awbCell.style.transform = "scale3d(1, 1.2, 1.2)";
        awbCell.style.fontWeight = "bolder";
      });
      awbCell.addEventListener("mouseleave", () => {
        awbCell.style.transform = "scale3d(1, 1, 1)";
        awbCell.style.fontWeight = "normal";
      });
    }
  }
  return (
    <Box sx={{ height: "520px", width: "100%" }}>
      <DataGrid
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 15, 50]}
        pagination
        rows={searchData.length ? searchData : data}
        getRowId={row => row.AWB}
        columns={columns}
        hideFooterSelectedRowCount
        onCellClick={row => {
          navigate(`/operations/${pageName}/${row.row[identifier]} `);
        }}
        sx={{
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </Box>
  );
}
