const Languages = [
    {
        code:'en',
        name:'English',
        country_code:'usa'
    },
    {
        code:'ar',
        name:'العربية',
        country_code:'sa'
    },
    {
        code:'pt',
        name:'Português',
        country_code:'pt'
    },
    {
        code:'fr',
        name:'français',
        country_code:'fr'
    },
    {
        code:'es',
        name:'español',
        country_code:'es'
    }
]





export default Languages;