const generateBranches = (num) => {
  let tmpArr = [];
  const namesList = [
    "Giza",
    "Dokki",
    "October",
    "Banha",
    "Dummita",
    "Bani Suif",
    "Cairo",
    "Assuit",
    "Sharkia",
    "Monoffia",
    "Dakahlia",
  ];

  let genRundomData = () => {
    let orders = Math.floor(Math.random() * (10000 - 1000) + 1000);
    return {
      name: namesList[Math.floor(Math.random() * namesList.length)],
      orders,
      done: Math.floor(Math.random() * (1000 - 100) + 1000),
      returns: Math.floor(Math.random() * (1000 - 100) + 1000),
      id: Math.random().toString(36).slice(2),
      progress: (orders / 10000) * 100,
    };
  };
  for (let i = 0; i < num; i++) {
    tmpArr.push(genRundomData());
  }
  return tmpArr;
};
export default generateBranches;
