import {
  Alert,
  Box,
  CircularProgress,
  Container,
  FormLabel,
  Grid,
  InputBase,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonModal from "../utilities/ButtonModal";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { clientSchema } from "../../validation/client";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
const formStyle = {
  width: "45%",
};
const alignForm = {
  display: "flex",
  alignItems: "base-line",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "1em",
};
const formBorder = {
  border: "var(--secondary-color) 2px solid",
  borderRadius: "50px",
  width: "100%",
  paddingX: "1em",
  height: "3rem",
};
const formFont = {
  fontSize: "1em",
  color: "#252E48",
  letterSpacing: 0,
  fontFamily: "poppins",
};

const formContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};
const btn = {
  background: "var(--gradient)",
  color: "#fff",
  borderRadius: "30px",
  height: "50px",
  minHeight: "40px",
  maxHeight: "60px",
  width: "40%",
};
export default function EditClientInfo({ handleCloseEditClient, currentRow }) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(clientSchema),
    defaultValues: currentRow,
  });
  const { t } = useTranslation("finance");
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  async function handleFormSubmit(data) {
    setDisable(true);
    setIsLoading(true);
    setError();
    let response = await axios
      .put(`https://vsoft.com-eg.net:4041/api/Clients/Update`, data)
      .catch(err => {
        setSnack(true);
      });
    if (response.data.success) {
      setIsLoading(false);
      handleCloseEditClient();
      queryClient.invalidateQueries("ClientsDetails");
    } else {
      setIsLoading(false);
      handleCloseEditClient();
    }
  }
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box
        sx={{
          border: "1px #fff solid",
          width: "100%",
        }}
      >
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.account_no")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("accountNo")}
                placeholder={t("clients.number")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.accountNo?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.arabic_name")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("fNameA")}
                placeholder={t("clients.name")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.fNameA?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.english_name")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("fName")}
                placeholder={t("clients.name")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.fNameA?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.email")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("email")}
                placeholder={t("clients.email")}
                sx={{ backgroundColor: "transparent", ...formBorder }}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.email?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.contact_person")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("contactPerson")}
                placeholder={t("clients.name")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.contactPerson?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.contact_no")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("contactNo")}
                placeholder={t("clients.number")}
                sx={{ backgroundColor: "transparent", ...formBorder }}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.contactNo?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.address1")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("address1")}
                placeholder={t("clients.address")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.address1?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.address2")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("address2")}
                placeholder={t("clients.address")}
                sx={{ backgroundColor: "transparent", ...formBorder }}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.address2?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.created_by")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("createdBy")}
                placeholder={t("clients.created_by")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.createdBy?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.contact_no2")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("contactNo2")}
                placeholder={t("clients.number")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.contactNo3?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.contact_no3")}</FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                {...register("contactNo3")}
                placeholder={t("clients.number")}
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.contactNo2?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <ButtonModal sx={btn} onClick={handleCloseEditClient}>
            {t("clients.cancel")}
          </ButtonModal>
          <ButtonModal type="submit" disabled={disable} sx={btn}>
            {isLoading ? <CircularProgress /> : t("clients.update")}
          </ButtonModal>
          <Snackbar
            open={snack}
            autoHideDuration={3000}
            onClose={handleCloseEditClient}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleCloseEditClient}
              severity="error"
              sx={{ width: "100%" }}
            >
              {t("clients.error")}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </form>
  );
}
