import axios from "axios";
import { setAuthedUser, logOut } from "./reducers/authReducerToolKit";
export const handleUserLogin = async (userObject, setLoading, setError) => {
  return (dispatch) => {
    setLoading(true);
    axios
      .post("https://vsoft.com-eg.net:4041/api/CoreUsers/V1Login", userObject, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((userData) => {
        const { data } = userData.data;
        console.log(data);
        dispatch(setAuthedUser(data));
        localStorage.setItem("AuthedUser", JSON.stringify(data));
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        const { message } = error.response.data;
        setLoading(false);
        setError(message);
        setTimeout(() => setError(""), 6000);
      });
  };
};
export const handleLogout = () => {
  return (dispatch) => {
    dispatch(logOut());
    localStorage.removeItem("AuthedUser");
  };
};
export const checkUser = () => {
  return (dispatch) => {
    const AuthedUser = localStorage.getItem("AuthedUser");
    if (AuthedUser) {
      dispatch(setAuthedUser(JSON.parse(AuthedUser)));
    }
  };
};
