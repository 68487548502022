import React from "react";

export default function ExcelSvg() {
  return (
    <div>
      <svg
        style={{
          cursor: "pointer",
          transform: "Scale(0.9)",
        }}
        id="excel-svgrepo-com"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="26"
        viewBox="0 0 28 26"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.174"
            y1="-146.919"
            x2="0.826"
            y2="-145.787"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#18884f" />
            <stop offset="0.5" stopColor="#117e43" />
            <stop offset="1" stopColor="#0b6631" />
          </linearGradient>
        </defs>
        <path
          id="Path_9281"
          data-name="Path 9281"
          d="M19.581,15.35,8.512,13.4V27.809A1.192,1.192,0,0,0,9.7,29h19.1A1.192,1.192,0,0,0,30,27.809h0V22.5Z"
          transform="translate(-2 -3)"
          fill="#185c37"
        />
        <path
          id="Path_9282"
          data-name="Path 9282"
          d="M19.581,3H9.7A1.192,1.192,0,0,0,8.512,4.191h0V9.5L19.581,16l5.861,1.95L30,16V9.5Z"
          transform="translate(-2 -3)"
          fill="#21a366"
        />
        <path
          id="Path_9283"
          data-name="Path 9283"
          d="M8.512,9.5H19.581V16H8.512Z"
          transform="translate(-2 -3)"
          fill="#107c41"
        />
        <path
          id="Path_9284"
          data-name="Path 9284"
          d="M16.434,8.2H8.512V24.45h7.922a1.2,1.2,0,0,0,1.194-1.191V9.391A1.2,1.2,0,0,0,16.434,8.2Z"
          transform="translate(-2 -3)"
          opacity="0.1"
        />
        <path
          id="Path_9285"
          data-name="Path 9285"
          d="M15.783,8.85H8.512V25.1h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
          transform="translate(-2 -3)"
          opacity="0.2"
        />
        <path
          id="Path_9286"
          data-name="Path 9286"
          d="M15.783,8.85H8.512V23.8h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
          transform="translate(-2 -3)"
          opacity="0.2"
        />
        <path
          id="Path_9287"
          data-name="Path 9287"
          d="M15.132,8.85H8.512V23.8h6.62a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.132,8.85Z"
          transform="translate(-2 -3)"
          opacity="0.2"
        />
        <path
          id="Path_9288"
          data-name="Path 9288"
          d="M3.194,8.85H15.132a1.193,1.193,0,0,1,1.194,1.191V21.959a1.193,1.193,0,0,1-1.194,1.191H3.194A1.192,1.192,0,0,1,2,21.959V10.041A1.192,1.192,0,0,1,3.194,8.85Z"
          transform="translate(-2 -3)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_9289"
          data-name="Path 9289"
          d="M5.7,19.873l2.511-3.884-2.3-3.862H7.758L9.013,14.6a4.809,4.809,0,0,1,.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359,3.84,2.419,3.905H10.821l-1.45-2.711A2.355,2.355,0,0,1,9.2,16.8H9.176a1.688,1.688,0,0,1-.168.351L7.515,19.873Z"
          transform="translate(-2 -3)"
          fill="#fff"
        />
        <path
          id="Path_9290"
          data-name="Path 9290"
          d="M28.806,3H19.581V9.5H30V4.191A1.192,1.192,0,0,0,28.806,3Z"
          transform="translate(-2 -3)"
          fill="#33c481"
        />
        <path
          id="Path_9291"
          data-name="Path 9291"
          d="M19.581,16H30v6.5H19.581Z"
          transform="translate(-2 -3)"
          fill="#107c41"
        />
      </svg>
    </div>
  );
}
