import React from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { Modal } from "@mui/material";
import styles from "../../assets/css/modules/MyModal.module.css";
import {
  StyledButton,
  TypographyHeadModal,
  StyledInputBase,
} from "../utilities";

export default function MyModal(props) {
  const { t } = useTranslation("operations_separated");
  const methods = useForm();
  const { getValues, handleSubmit, register } = methods;
  const submitMulti = () => {
    const splitted = getValues("multiAWB").split(/\n/);
    const returnRandom = () => {
      return Math.floor(Math.random() * 1000);
    };
    if (props.transactionData) {
      if (props.cb) {
        props.cb(splitted.join());
      } else {
        let tmp = [];
        for (let i = 0; i < splitted.length; i++) {
          tmp.push({ id: returnRandom(), awb: splitted[i] });
        }
        props.setTransactionData([...props.transactionData, ...tmp]);
      }
    }

    props.setOpen(false);
  };
  return (
    <Modal open={props.open} onClose={() => props.setOpen(false)}>
      <form onSubmit={handleSubmit(submitMulti)}>
        <div className={styles.container}>
          <div className={styles.modalCard}>
            <TypographyHeadModal>{t("Multiple Payments")}</TypographyHeadModal>
            <div className={styles.textContainer}>
              <div className={styles.label}>
                {t("Please Enter Payments Numbers")}
              </div>
              <StyledInputBase
                sx={{
                  "& .MuiInputBase-input": {
                    width: "350px",
                    borderRadius: "10px !important",
                  },
                }}
                multiline
                placeholder={t("Payment Number")}
                rows="7"
                {...register("multiAWB")}
              ></StyledInputBase>
            </div>
            <div className={styles.buttonsContainer}>
              <StyledButton modal onClick={() => props.setOpen(false)}>
                {t("modal.cancel")}
              </StyledButton>
              <StyledButton modal type="submit">
                {t("modal.ok")}
              </StyledButton>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
