import { Box, Select, MenuItem } from "@mui/material";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { StyledButton, ErrorText, StyledInputBase } from "../utilities";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { accountSchema } from "../../validation/client";
import { useTranslation } from "react-i18next";

const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 400;
  width: 40%;
  text-transform: capitalize;
`;
const formContainer = {
  width: "45%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
};
export default function StepperAccountForm({
  nextStep,
  previousStep,
  activeStep,
  setStepperData,
  stepperData,
}) {
  const { data: cityListData } = useQuery("CityList_Select", async () => {
    const data = await axios.post(
      `https://vsoft.com-eg.net:4041/api/bncGeoCity/GetList`,
      {
        fromDate: "2022-04-10T09:10:38.805Z",
        toDate: "2023-04-10T09:10:38.805Z",
        serial: 0,
        pageParam: {
          pageSize: 1000,
          pageNumber: 0,
        },
        language: "string",
      }
    );
    return data.data.data;
  });
  const { data: priceListData } = useQuery("PriceList_Select", async () => {
    const data = await axios.post(
      `https://vsoft.com-eg.net:4041/api/Clients/GetList`,
      {
        pageSize: 1000,
        pageNumber: 0,
      }
    );

    return data.data.data;
  });
  const { data: productListData } = useQuery("ProductList_Select", async () => {
    const data = await axios.post(
      `https://vsoft.com-eg.net:4041/api/bncProducts/GetPage
      `,
      {
        fromDate: "2022-04-10T09:14:16.215Z",
        toDate: "2023-04-10T09:14:16.215Z",
        serial: 0,
        pageParam: {
          pageSize: 1000,
          pageNumber: 0,
        },
        language: "string",
      }
    );
    return data.data.data;
  });
  const { t } = useTranslation("finance");
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(accountSchema(t)),
    defaultValues: stepperData.account,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const submitForm = (data) => {
    setStepperData({ ...stepperData, account: data });
    nextStep();
  };
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "25px",
          margin: "50px 5px 0 5px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel>{t("clients.pickup_city")}</StyledLabel>
            <Box sx={{ width: "60%" }}>
              <Select
                displayEmpty
                sx={{ width: "100%" }}
                input={<StyledInputBase {...register("CityID")} />}
              >
                <MenuItem sx={{ color: "#828EAE" }} value={""} disabled>
                  Branch
                </MenuItem>
                {cityListData &&
                  cityListData.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id || ""}>
                        {item.englishName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Box>
            <ErrorText>{errors.CityID?.message}</ErrorText>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel>{t("clients.pickup_product")}</StyledLabel>
            <Box sx={{ width: "60%" }}>
              <Select
                displayEmpty
                sx={{ width: "100%" }}
                input={<StyledInputBase {...register("ProductID")} />}
              >
                <MenuItem sx={{ color: "#828EAE" }} value={""} disabled>
                  Product
                </MenuItem>
                {productListData &&
                  productListData.map((product) => {
                    return (
                      <MenuItem
                        key={product.ProductID}
                        value={product.ProductID || ""}
                      >
                        {product.fName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Box>
            <ErrorText>{errors.ProductID?.message}</ErrorText>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="allowedWeight">
              {t("clients.allowed_weight")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="allowedWeight"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("allowedWeight")}
                placeholder={t("clients.weight")}
                type="number"
              />
            </Box>
            <ErrorText>{errors.allowedWeight?.message}</ErrorText>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel>{t("clients.price_list")}</StyledLabel>
            <Box sx={{ width: "60%" }}>
              <Select
                displayEmpty
                sx={{ width: "100%" }}
                input={<StyledInputBase {...register("PriceListID")} />}
              >
                <MenuItem sx={{ color: "#828EAE" }} value={""} disabled>
                  Price List
                </MenuItem>
                {priceListData &&
                  priceListData.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id || ""}>
                        {item.englishName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Box>
            <ErrorText>{errors.PriceListID?.message}</ErrorText>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="bankName">
              {t("clients.bank_name")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="bankName"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("bankName")}
                placeholder={t("clients.name")}
              />
            </Box>
            <ErrorText>{errors.bankName?.message}</ErrorText>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="bankAccount">
              {t("clients.bank_account")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="bankAccount"
                sx={{
                  width: "100%",
                  minWidth: "150px !important",
                  backgroundColor: "transparent",
                }}
                {...register("bankAccount")}
                placeholder={t("clients.number")}
                type="number"
              />
            </Box>
            <ErrorText>{errors.bankAccount?.message}</ErrorText>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="accountType">
              {t("clients.account_type")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="accountType"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("accountType")}
                placeholder={t("clients.type")}
              />
            </Box>
            <ErrorText>{errors.accountType?.message}</ErrorText>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="prefix">{t("clients.prefix")}</StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="prefix"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("prefix")}
                placeholder={t("clients.prefix")}
              />
            </Box>
            <ErrorText>{errors.prefix?.message}</ErrorText>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="monthlyAWBs">
              {t("clients.monthly_awbs")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="monthlyAWBs"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("monthlyAWBs")}
                placeholder={t("clients.awbs")}
                type="number"
              />
            </Box>
            <ErrorText>{errors.monthlyAWBs?.message}</ErrorText>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="importRate">
              {t("clients.import_rate")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="importRate"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("importRate")}
                placeholder={t("clients.type")}
                type="number"
              />
            </Box>
            <ErrorText>{errors.importRate?.message}</ErrorText>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="registrationTypeID">
              {t("clients.registeration_type")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="registrationTypeID"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("registrationTypeID")}
                placeholder={t("clients.type")}
              />
            </Box>
            <ErrorText>{errors.registrationTypeID?.message}</ErrorText>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="salesPerson">
              {t("clients.sales_person")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="salesPerson"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("salesPerson")}
                placeholder={t("clients.sales_person")}
              />
            </Box>
            <ErrorText>{errors.salesPerson?.message}</ErrorText>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="exportRate">
              {t("clients.export_rate")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="exportRate"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("exportRate")}
                placeholder={t("clients.export_rate")}
                type="number"
              />
            </Box>
            <ErrorText>{errors.exportRate?.message}</ErrorText>
          </Box>
          <Box sx={formContainer}>
            <StyledLabel htmlFor="swiftCode">
              {t("clients.swift_code")}
            </StyledLabel>
            <Box sx={{ width: "60%" }}>
              <StyledInputBase
                id="swiftCode"
                sx={{ width: "100%", minWidth: "150px !important" }}
                {...register("swiftCode")}
                placeholder={t("clients.code")}
                type="number"
              />
            </Box>
            <ErrorText>{errors.swiftCode?.message}</ErrorText>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          flexWrap: "wrap",
          marginTop: "1rem",
        }}
      >
        <StyledButton
          onClick={() => previousStep()}
          disabled={activeStep === 0}
        >
          {t("clients.back")}
        </StyledButton>
        <StyledButton type="submit"> {t("clients.next")}</StyledButton>
      </Box>
    </form>
  );
}
