import React from "react";
import LanguagePicker from "./LanguagePicker";
export default function AuthFooter() {
  return (
    <>
      <div
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>MPS Dispatch V2.9.0.6</div>
        <div>
          <a href="http://mpsegypt.com">http://mpsegypt.com</a>
        </div>
        <div>
          <LanguagePicker theme="white" />
        </div>
      </div>
    </>
  );
}
// className="position-relative w-100  d-flex justify-content-between align-items-center"
