import React from 'react'
import {Input} from 'reactstrap'
import Languages from '../helpers/supportedLanguages'

export default function LanguagePicker({theme}) {
    return (
        <Input
        name="select-language"
        className={theme === "white" ? 'language-light-picker' : 'language-picker'  }
        type="select"
        onChange={(e)=>{console.log(e.target.value)}}
        >
            {
                Languages.map(lang=>(
                    <option key={lang.country_code}>
                    {lang.name}
                    </option>
                ))
            }
        </Input>
    )
}



