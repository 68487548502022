import { Button } from "@mui/material";
import styled from "styled-components";
const StyledButtongridtable = styled(Button)(() => ({
  minWidth: "120px",
  height: "30px",
  fontSize: "17px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  cursor: "pointer",
  color: "white",
  background: "#36CD59",
  ":hover": { background: "#2f8442" },
  textTransform: "capitalize",
}));
export default StyledButtongridtable;
