import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Label, FormGroup, Button, Col } from "reactstrap";
import { handleUserLogin } from "../../redux/sharedToolKit";
import { useDispatch } from "react-redux";
import styles from "../../assets/css/modules/auth/Login.module.css";
import { LanguagePicker } from "../../components";
import Snackbar from "@mui/material/Snackbar";
import {
  Alert,
  Collapse,
  IconButton,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { t } from "i18next";

function Login() {
  const [userData, setUserData] = useState({ username: "", password: "" });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  // console.log(handleUserLogin(userData));
  const handleLogin = async (e) => {
    e.preventDefault();
    if (userData.username || userData.password) {
      dispatch(handleUserLogin(userData, setLoading, setError));
    }
  };
  return (
    <div className={` ${styles.authFormContainer}`}>
      <div className="">
        <div className={styles.formInfo}>
          <div className="text-center text-bold position-relative h-30 w-100">
            <h1>logo</h1>
          </div>
          <div className="">
            <h3>Welcome To</h3>
            <h1>Dispatch App</h1>
          </div>
          <div>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore
            perspiciatis beatae, autem sint voluptatem voluptatum similique
            accusantium aut voluptas inventore neque. Dolores quos aliquam
            fugiat at, nostrum facilis sed perferendis!
          </div>
          <div>
            <Link to={{ pathname: "/auth/signup" }} className="btn  text-white">
              {" "}
              sign up
            </Link>
          </div>
          <small className="text-center text-capitalize ">
            MPS Dispatch V2.0.6{" "}
          </small>
        </div>
      </div>
      <div className="">
        <div className={`text-white ${styles.formInputs}`}>
          <h1 className="text-center text-capitalize">sign in</h1>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : error ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" color="red" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          ) : (
            <Form onSubmit={handleLogin}>
              <FormGroup row className="mb-4">
                <Label htmlFor="username" sm={3}>
                  * username
                </Label>
                <Col sm={9}>
                  <Input
                    id="username"
                    name="username"
                    type="username"
                    placeholder="username"
                    onChange={handleChange}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mb-4">
                <Label htmlFor="password" sm={3}>
                  * Password
                </Label>
                <Col sm={9}>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    required
                  />
                </Col>
              </FormGroup>
              <div className="d-flex justify-content-end">
                <Link
                  to="/auth/forgot-password"
                  className="text-capitalize mb-3 "
                >
                  forgot password
                </Link>
              </div>
              <Button type="submit" className="w-100">
                Login
              </Button>
            </Form>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <div className=""></div>
            <div>
              <LanguagePicker />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
