import React from "react";
import { useFormContext } from "react-hook-form";
import styles from "../../assets/css/modules/InputGroup.module.css";
import { StyledInputBase, ErrorText } from "../../components/utilities";

export default function InputGroup(props) {
  const { register } = useFormContext();
  return (
    <>
      {props.applyValidation ? (
        <div className={styles.container}>
          <div className={styles.inputGroup}>
            <label className={styles.label}>{props.label}</label>
            <StyledInputBase
              sx={props.sx}
              id={props.name}
              type={props.input}
              placeholder={props.placeholder}
              {...register(props.name, { required: `${props.errorMassage}` })}
            />
          </div>
          {props.error ? <ErrorText>{props.error.message}</ErrorText> : null}
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.inputGroup}>
            <label className={styles.label}>{props.label}</label>
            <StyledInputBase {...props.input} {...register(props.name)} />
          </div>
          {props.error ? <ErrorText>{props.error.message}</ErrorText> : null}
        </div>
      )}
    </>
  );
}
