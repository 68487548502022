import React from "react";
import NotFoundSVG from "../assets/images/erorrNotFound.svg";
import { Box } from "@mui/material";
function NotFound() {
  return (
    <Box
      sx={{ minHeight: "100vh" }}
      className="position-relative d-flex justify-content-center align-items-center"
    >
      <img src={NotFoundSVG} alt="error not found" width="700" height="700" />
    </Box>
  );
}
export default React.memo(NotFound);
