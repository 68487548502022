import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Navbar, Sidebar } from "../components/Layout";
import { checkUser } from "../redux/sharedToolKit";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkUser());
  }, [dispatch]);
  const [currentWidth, setCurrentWidth] = useState("73px");
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { isLoggedIn } = useSelector((state) => ({
    isLoggedIn: state.rootReducer.auth.isLoggedIn,
  }));
  console.log(isLoggedIn);
  if (isLoggedIn) {
    return (
      <>
        <Navbar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
          dir={language === "ar" ? "rtl" : "ltr"}
        >
          <Sidebar setCurrentWidth={setCurrentWidth} />
          <Box
            sx={{ width: `calc(100% - ${currentWidth})` }}
            className="payload"
          >
            <Outlet />
          </Box>
        </Box>
      </>
    );
  } else {
    return <Navigate to="/auth/login" />;
  }
}
