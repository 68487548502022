import React from "react";
import { Routes, Route } from "react-router-dom";
import { NotFound } from "../../pages";
import { CompanyData, Employees, Runners } from "../../pages/Admin";
import AddCompanyData from "../Admin/AddCompanyData";
import AccountMobileRunnerDetails from "../Admin/AccountMobileRunnerDetails";
import MoreInformationEmployee from "../Admin/MoreInformationEmployee";
import MoreInformationRunner from "../Admin/MoreInformationRunner";

import ViewInformation from "../Admin/ViewInformation";

export default function Admin() {
  return (
    <Routes>
      <Route path="/company-data" element={<CompanyData />} />
      <Route path="/company-data/add" element={<AddCompanyData />} />
      <Route path="/employees" element={<Employees />} />
      <Route path="/runners" element={<Runners />} />
      <Route
        path="/account-details/:id"
        element={<AccountMobileRunnerDetails />}
      />
      <Route
        path="/more-information/:id"
        element={<MoreInformationEmployee />}
      />
      <Route path="/more-information/:id" element={<MoreInformationRunner />} />
      <Route path="/view-profile/:id" element={<ViewInformation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
