import React from "react";

export default function DoubleCheckSvg() {
  return (
    <div>
      <svg
        style={{
          cursor: "pointer",
          transform: "Scale(0.75)",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="32"
        viewBox="0 0 42 32"
      >
        <g
          id="Group_12484"
          data-name="Group 12484"
          transform="translate(14861 -353)"
        >
          <g
            id="Group_11156"
            data-name="Group 11156"
            transform="translate(0 1)"
          >
            <g
              id="Rectangle_18018"
              data-name="Rectangle 18018"
              transform="translate(-14861 352)"
              fill="#fff"
              stroke="#36cd59"
              strokeWidth="1"
            >
              <rect width="42" height="32" rx="5" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="41"
                height="31"
                rx="4.5"
                fill="none"
              />
            </g>
          </g>
          <path
            id="done_all_FILL0_wght400_GRAD0_opsz48"
            d="M78.185,366.857,70,355.964l1.571-2.091,6.614,8.8,1.571,2.091Zm6.212,0-8.185-10.894,1.571-2.091,6.614,8.8L98.429,344,100,346.091Zm0-8.267L82.826,356.5,92.217,344l1.571,2.091Z"
            transform="translate(-14925 14)"
            fill="#36cd59"
          />
        </g>
      </svg>
    </div>
  );
}
