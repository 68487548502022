import { Box, Drawer, Grid, InputBase } from "@mui/material";
import { StyledInputBase, TypographyHeadModal } from "../utilities";
import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetBranches,
  useGetCells,
  useGetCity,
  useGetClients,
  useGetReasons,
  useGetRunners,
  useGetStatus,
  useGetUsers,
} from "../../Queries/operation";
const alignForm = {
  display: "flex",
  alignItems: "base-line",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "0.5em",
};
const SelectBox = styled.select`
  border: 2px solid var(--secondary-color);
  border-radius: 50px;
  width: 255px;
  height: 2.5rem;
`;
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
`;
export default function BulkShipmentsFilter() {
  const { data: statusData } = useGetStatus();
  const { data: clientsData } = useGetClients();
  const { data: runnerData } = useGetRunners();
  const { data: cellData } = useGetCells();
  const { data: cityData } = useGetCity();
  const { data: reasonsData } = useGetReasons();

  const { data: branchesData } = useGetBranches();
  const { data: usersData } = useGetUsers();
  const { t } = useTranslation("operations_separated");
  return (
    <Box sx={{ p: 2 }} role="presentation">
      <TypographyHeadModal>{t("bulk_filter.filter")}</TypographyHeadModal>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.account")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.account")}>
            <option value="">{t("bulk_filter.account")}</option>
            {clientsData?.data?.map(client => (
              <option value={client.id} key={client.id}>
                {client.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.sub_account")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.account")}>
            <option value="">{t("bulk_filter.sub_account")}</option>
            {clientsData?.data?.map(client => (
              <option value={client.id} key={client.id}>
                {client.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.status")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.status")}>
            <option value="">{t("bulk_filter.status")}</option>
            {statusData?.data?.map(status => (
              <option value={status.id} key={status.id}>
                {status.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.courier")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.courier")}>
            <option value="">{t("bulk_filter.courier")}</option>
            {runnerData?.data?.map(runner => (
              <option value={runner.id} key={runner.id}>
                {runner.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.reason")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.reason")}>
            <option value="">{t("bulk_filter.reason")}</option>
            {reasonsData?.data?.map(reason => (
              <option value={reason.id} key={reason.id}>
                {reason.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.cells")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.cells")}>
            <option value="">{t("bulk_filter.cells")}</option>
            {cellData?.data?.map(cell => (
              <option value={cell.id} key={cell.id}>
                {cell.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.to_city")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.to_city")}>
            <option value="">{t("bulk_filter.to_city")}</option>
            {cityData?.data?.map(city => (
              <option value={city.id} key={city.id}>
                {city.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.branch")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.branch")}>
            <option value="">{t("bulk_filter.branch")}</option>
            {branchesData?.data?.map(branch => (
              <option value={branch.id} key={branch.id}>
                {branch.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.user")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.user")}>
            <option value="">{t("bulk_filter.user")}</option>
            {usersData?.data?.map(user => (
              <option value={user.id} key={user.id}>
                {user.englishName}
              </option>
            ))}
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.pickup_date")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.from")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
            sx={{ mb: 1 }}
          />
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.to")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.delivery_date")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.from")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
            sx={{ mb: 1 }}
          />
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.to")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.last_change")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.from")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
            sx={{ mb: 1 }}
          />
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.to")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.picked_up_date")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.from")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
            sx={{ mb: 1 }}
          />
          <StyledInputBase
            type="text"
            placeholder={t("bulk_filter.to")}
            onFocus={e => {
              e.target.type = "datetime-local";
            }}
            onBlur={e => {
              e.target.type = "text";
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.consignee")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase placeholder={t("bulk_filter.consignee")} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.telephone")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase placeholder={t("bulk_filter.telephone")} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.address")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase placeholder={t("bulk_filter.address")} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.transaction")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <StyledInputBase placeholder={t("bulk_filter.transaction")} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.show_top")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox>
            <option value="">{t("bulk_filter.all")}</option>
            <option value="true" key="true">
              Yup
            </option>
            <option value="false" key="false">
              No
            </option>
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.call_center")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox>
            <option value="">{t("bulk_filter.all")}</option>
            <option value="true" key="true">
              Yup
            </option>
            <option value="false" key="false">
              No
            </option>
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.collected_from_runner")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox>
            <option value="">{t("bulk_filter.all")}</option>
            <option value="true" key="true">
              Yup
            </option>
            <option value="false" key="false">
              No
            </option>
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.collected_from_branch")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox>
            <option value="">{t("bulk_filter.all")}</option>
            <option value="true" key="true">
              Yup
            </option>
            <option value="false" key="false">
              No
            </option>
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.paid_to_customer")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox>
            <option value="">{t("bulk_filter.all")}</option>
            <option value="true" key="true">
              Yup
            </option>
            <option value="false" key="false">
              No
            </option>
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.awb_x_awb")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox>
            <option value="">{t("bulk_filter.all")}</option>
            <option value="true" key="true">
              Yup
            </option>
            <option value="false" key="false">
              No
            </option>
          </SelectBox>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} sx={alignForm}>
          <StyledLabel>{t("bulk_filter.delivery_cycle")}</StyledLabel>
        </Grid>
        <Grid item xs={8} sx={alignForm}>
          <SelectBox placeholder={t("bulk_filter.delivery_cycle")}>
            <option value="">{t("bulk_filter.all")}</option>
            <option value="true" key="true">
              Yup
            </option>
            <option value="false" key="false">
              No
            </option>
          </SelectBox>
        </Grid>
      </Grid>
    </Box>
  );
}
