import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Label, FormGroup, Button, Col } from "reactstrap";
import styles from "../../assets/css/modules/auth/Signup.module.css";
import { LanguagePicker } from "../../components";
export default function Signup() {
  return (
    <div className={` ${styles.authFormContainer}`}>
      <div className="">
        <div className={styles.formInfo}>
          <div className="text-center text-bold position-relative h-30 w-100">
            <h1>logo</h1>
          </div>
          <div className="">
            <h3>Welcome in</h3>
            <h1>Dispatch App</h1>
          </div>
          <div>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore
            perspiciatis beatae, autem sint voluptatem voluptatum similique
            accusantium aut voluptas inventore neque. Dolores quos aliquam
            fugiat at, nostrum facilis sed perferendis!
          </div>
          <div>
            <Link to={{ pathname: "/auth/login" }} className="btn  text-white">
              {" "}
              sign in
            </Link>
          </div>
          <small className="text-center text-capitalize ">
            MPS Dispatch V2.0.6{" "}
          </small>
        </div>
      </div>
      <div className="">
        <div className={`text-white ${styles.formInputs}`}>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="text-center text-capitalize">Sign Up</h1>
          </div>
          <Form>
            <FormGroup row className="mb-2">
              <Label htmlFor="name-ar" sm={5}>
                * Name In Arabic
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="name-ar"
                  name="name-ar"
                  type="name-ar"
                  placeholder="Name"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="name-en" sm={5}>
                * Name In English
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="name-en"
                  name="name-en"
                  type="name-en"
                  placeholder="Name"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="address-ar" sm={5}>
                * Address In Arabic
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="address-ar"
                  name="address-ar"
                  type="text"
                  placeholder="Address"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="address-en" sm={5}>
                * Address In English
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="address-en"
                  name="address-en"
                  type="text"
                  placeholder="Address"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="email" sm={5}>
                * Email
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="phone-1" sm={5}>
                * Phone
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="phone-1"
                  name="phone-1"
                  type="text"
                  placeholder="Phone"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="phone-2" sm={5}>
                * Phone2
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="phone-2"
                  name="phone-2"
                  type="text"
                  placeholder="Phone"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="password" sm={5}>
                * Password
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="password_confirmation" sm={5}>
                * Confirm Password
              </Label>
              <Col sm={7}>
                <Input
                  required
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  placeholder="Password"
                />
              </Col>
            </FormGroup>
            <Button type="submit" className="w-100">
              Register
            </Button>
          </Form>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <a href="/home">mps.egypt.com</a>
            </div>
            <div>
              <LanguagePicker />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
