import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import styles from "../../assets/css/modules/pages/RightSided.module.css";
import TabPanel from "../TabPanel";
import generateUsers from "../../mocks/_generateUsersMini";
import generateBranches from "../../mocks/_generateBranches";
import DeliveredPercentage from "./circularProgress";
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
export default function RightSidedBar({ SearchBarInput }) {
  const [value, setValue] = useState(0);
  let usersData = generateUsers(10);
  let branchesData = generateBranches(10);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ my: 2, maxHeight: "70px" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={<span className={styles.tabLabel}>Top Clients (10)</span>}
            {...a11yProps(0)}
            className={styles.tabHolder}
          />
          <Tab
            label={<span className={styles.tabLabel}>Top Branches (10)</span>}
            {...a11yProps(1)}
            className={styles.tabHolder}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className={styles.tab}>
        <ul className={styles.usersList}>
          {usersData
            .filter((user) => {
              if (SearchBarInput === "") {
                return user;
              } else if (
                user.name.toLowerCase().includes(SearchBarInput.toLowerCase())
              ) {
                return user;
              }
            })
            .map((user) => (
              <li className={styles.userContainer} key={user.orders}>
                <div className={styles.userInner}>
                  <div className={styles.userLeft}>
                    <div className={styles.userImg}>
                      <img src={user.image} alt="" />
                    </div>
                    <div className={styles.userText}>
                      <div className={styles.username}>{user.name}</div>
                      <div className={styles.phoneNumber}>
                        {user.phoneNumber}
                      </div>
                    </div>
                  </div>
                  <div className={styles.userRight}>Orders {user.orders}</div>
                </div>
                <div className={styles.userProgress}>
                  <div
                    className={styles.progressFiller}
                    style={{ width: ` ${user.progress}%` }}
                  ></div>
                </div>
              </li>
            ))}
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1} className={styles.tab}>
        <ul className={styles.usersList}>
          {branchesData
            .filter((branch) => {
              if (SearchBarInput === " ") {
                return branch;
              } else if (
                branch.name.toLowerCase().includes(SearchBarInput.toLowerCase())
              ) {
                return branch;
              }
            })
            .map((branch) => (
              <li className={styles.branchContainer} key={branch.id}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Box>{branch.name}</Box>
                  <Box
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      color: "var(--primary-color)",
                      gap: "5px",
                    }}
                  >
                    All AWBS <Box>{branch.orders} </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <DeliveredPercentage
                    progress={(branch.orders / 10000) * 100}
                  />
                </Box>
              </li>
            ))}
        </ul>
      </TabPanel>
    </>
  );
}
