import { Box, Button, Grid, Modal } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../../pages/NotFound";
import Loading from "../Loading";
import DeletedModalClients from "./DeleteModalClients";
import SuspendClient from "./SuspendClient";
import { useState } from "react";
import { EditSvg } from "../utilities";
import TypographyHeader from "../utilities/TypographyHeader";
import EditClientInfo from "./EditClientInfo";
import EditAccountForm from "./EditAccountForm";
import EditActionForm from "./EditActionForm";
const card = {
  background: "#fff",
  borderRadius: "15px",
  paddingY: "0.5rem",
  paddingX: "1rem",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  marginBottom: "2rem",
};
const flexMode = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "0.2rem",
  padding: "0 1rem",
};
const blueData = {
  color: "#63729A",
  fontSize: "15px",
  fontWeight: "medium",
};
const blackData = {
  color: "#000",
  fontSize: "12px",
  fontWeight: "medium",
};
const BigModal = {
  width: "75%",
  backgroundColor: "#fff",
  borderRadius: "0 80px",
  boxShadow: 24,
  p: 4,
};
const styleEdit = {
  width: "55%",
  backgroundColor: "#fff",
  borderRadius: "0 80px",
  boxShadow: 24,
  p: 4,
};
const mainBarSuspend = {
  width: "200px",
  minWidth: "150px",
  height: "45px",
  border: "0px",
  borderRadius: "30px",
  letterSpacing: "0.5px",
  background:
    "linear-gradient(180deg,#f48c06 0%,#fca311 100%)0% 0% no-repeat padding-box",
  fontWeight: "600",
  fontSize: "16px",
  color: "#fff",
  textTransform: "capitalize",
};
const mainBarDelete = {
  width: "200px",
  minWidth: "150px",
  height: "45px",
  border: "0px",
  borderRadius: "30px",
  letterSpacing: "0.5px",
  background:
    "linear-gradient(180deg,#d62828 0%,#c1121f 100%)0% 0% no-repeat padding-box",
  fontWeight: "600",
  fontSize: "16px",
  color: "#fff",
  textTransform: "capitalize",
};
const footerButton = {
  width: "150px",
  minWidth: "100px",
  height: "45px",
  border: "0px",
  borderRadius: "30px",
  letterSpacing: "0.5px",
  background: "var(--gradient)",
  fontWeight: "600",
  fontSize: "16px",
  color: "#fff",
  textTransform: "capitalize",
};
export default function ClientsDetails() {
  const { t } = useTranslation("finance");
  const navigate = useNavigate();
  const [deleteButton, setDeleteButton] = useState(false);
  const handleDelete = () => setDeleteButton(true);
  const handleCloseDelete = () => setDeleteButton(false);
  const [suspendButton, setSuspendButton] = useState(false);
  const handleSuspend = () => setSuspendButton(true);
  const handleCloseSuspend = () => setSuspendButton(false);
  const [editClientButton, setEditClientButton] = useState(false);
  const handleEditClient = () => setEditClientButton(true);
  const handleCloseEditClient = () => setEditClientButton(false);
  const [editAccountButton, setEditAccountButton] = useState(false);
  const handleEditAccount = () => setEditAccountButton(true);
  const handleCloseEditAccount = () => setEditAccountButton(false);
  const [editActionButton, setEditActionButton] = useState(false);
  const handleEditAction = () => setEditActionButton(true);
  const handleCloseEditAction = () => setEditActionButton(false);
  const [currentRow, setCurrentRow] = useState({});
  const { fID } = useParams();
  const { data, isLoading, isError } = useQuery(
    "ClientsDeails",
    async () => {
      const data = await axios.post(
        `https://vsoft.com-eg.net:4041/api/Clients/Get/${fID}`,
        {
          pageParam: {
            pageSize: 0,
            pageNumber: 0,
          },
          language: "string",
        }
      );
      return data.data.data;
    },
    { refetchOnWindowFocus: false }
  );
  if (isError) {
    return <NotFound />;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Modal
        open={deleteButton}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root": {
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        <Box sx={styleEdit}>
          <DeletedModalClients
            handleCloseDelete={handleCloseDelete}
            currentRow={data}
            onClick={() => {
              navigate("/finance/clients/add-client");
            }}
          />
        </Box>
      </Modal>
      <Modal
        open={suspendButton}
        onClose={handleCloseSuspend}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root": {
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        <Box sx={styleEdit}>
          <SuspendClient
            handleCloseSuspend={handleCloseSuspend}
            currentRow={data}
          />
        </Box>
      </Modal>
      <Modal
        open={editClientButton}
        onClose={handleCloseEditClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root": {
            backgroundColor: "transparent",
            border: "none",
          },
          width: "100%",
        }}
      >
        <Box sx={BigModal}>
          <EditClientInfo
            handleCloseEditClient={handleCloseEditClient}
            currentRow={data}
          />
        </Box>
      </Modal>
      <Modal
        open={editAccountButton}
        onClose={handleCloseEditAccount}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root": {
            backgroundColor: "transparent",
            border: "none",
          },
          width: "100%",
        }}
      >
        <Box sx={BigModal}>
          <EditAccountForm
            handleCloseEditAccount={handleCloseEditAccount}
            currentRow={data}
          />
        </Box>
      </Modal>
      <Modal
        open={editActionButton}
        onClose={handleCloseEditAction}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root": {
            backgroundColor: "transparent",
            border: "none",
          },
          width: "100%",
        }}
      >
        <Box sx={BigModal}>
          <EditActionForm
            handleCloseEditAction={handleCloseEditAction}
            currentRow={data}
          />
        </Box>
      </Modal>
      <Box
        sx={{
          paddingY: "0.5rem",
          marginBottom: "2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <TypographyHeader>{t("clients.client_details")}</TypographyHeader>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Box
            onClick={() => {
              setCurrentRow(currentRow);
              handleSuspend();
            }}
          >
            <Button sx={mainBarSuspend}>{t("clients.suspend")}</Button>
          </Box>
          <Box
            onClick={() => {
              setCurrentRow(currentRow);
              handleDelete();
            }}
          >
            <Button sx={mainBarDelete}>{t("clients.delete")}</Button>
          </Box>
        </Box>
      </Box>
      <Box sx={card}>
        <Grid container spacing={0} sx={{ marginBottom: "1rem" }}>
          <Grid
            item
            xs={11}
            sx={{
              fontSize: "20px",
              fontWeight: "medium",
            }}
          >
            {t("clients.clients_info")}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ textAlign: "Right" }}
            onClick={() => {
              setCurrentRow(data);
              handleEditClient();
            }}
          >
            <EditSvg />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.account_no")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.accountNo}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.english_name")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.fName}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.arabic_name")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.fNameA}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={8} sx={blueData}>
              {t("clients.contact_person")}
            </Grid>
            <Grid item xs={4} sx={blackData}>
              {data.contactPerson}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.contact_no")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.contactNo}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.address1")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.address1}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.address2")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.address2}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.email")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.email}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.registeration_date")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.registrationDate}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.created_by")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.createdBy}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.contact_no2")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.contactNo2}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={8} sx={blueData}>
              {t("clients.contact_no3")}
            </Grid>
            <Grid item xs={4} sx={blackData}>
              {data.contactNo3}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={card}>
        <Grid container spacing={0} sx={{ marginBottom: "1rem" }}>
          <Grid
            item
            xs={11}
            sx={{
              fontSize: "20px",
              fontWeight: "medium",
            }}
          >
            {t("clients.account_info")}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ textAlign: "Right" }}
            onClick={() => {
              setCurrentRow(data);
              handleEditAccount();
            }}
          >
            <EditSvg />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.pickup_city")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.cityID}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.pickup_product")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.productID}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.allowed_weight")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.allowedWeight}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={8} sx={blueData}>
              {t("clients.price_list")}
            </Grid>
            <Grid item xs={4} sx={blackData}>
              {data.priceListID}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.bank_name")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.bankName}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.bank_account")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.bankAccount}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.account_type")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.accountType}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.prefix")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.prefix}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.monthly_awbs")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.monthlyAWBs}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.import_rate")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.importRate}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.registeration_type")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.registrationTypeID}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={8} sx={blueData}>
              {t("clients.sales_person")}
            </Grid>
            <Grid item xs={4} sx={blackData}>
              {data.registrationTypeID}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.export_rate")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.exportRate}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.swift_code")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.swiftCode}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}></Grid>
            <Grid item xs={6} sx={blackData}></Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}></Grid>
            <Grid item xs={6} sx={blackData}></Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={card}>
        <Grid container spacing={0} sx={{ marginBottom: "1rem" }}>
          <Grid
            item
            xs={11}
            sx={{
              fontSize: "20px",
              fontWeight: "medium",
            }}
          >
            {t("clients.action_info")}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ textAlign: "Right" }}
            onClick={() => {
              setCurrentRow(data);
              handleEditAction();
            }}
          >
            <EditSvg />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.add_fees_to_cod")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.applyReturnCost === true ? (
                <CheckOutlinedIcon />
              ) : (
                <ClearOutlinedIcon />
              )}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.apply_return_cost")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.applyReturnCost === true ? (
                <CheckOutlinedIcon />
              ) : (
                <ClearOutlinedIcon />
              )}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.active")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.inActive === true ? (
                <ClearOutlinedIcon />
              ) : (
                <CheckOutlinedIcon />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "@media screen and (max-width: 980px)": {
              flexWrap: "wrap",
            },
            ...flexMode,
          }}
        >
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.send_sms")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.sendSMS === true ? (
                <CheckOutlinedIcon />
              ) : (
                <ClearOutlinedIcon />
              )}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.cash_payment")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.cashPayment === true ? (
                <CheckOutlinedIcon />
              ) : (
                <ClearOutlinedIcon />
              )}
            </Grid>
          </Grid>
          <Grid container sx={flexMode}>
            <Grid item xs={6} sx={blueData}>
              {t("clients.bank_payment")}
            </Grid>
            <Grid item xs={6} sx={blackData}>
              {data.bankPayment === true ? (
                <CheckOutlinedIcon />
              ) : (
                <ClearOutlinedIcon />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "2em",
          alignItems: "center",
        }}
      >
        <Box
          onClick={() => {
            setCurrentRow(currentRow);
          }}
        >
          <Button sx={footerButton}>{t("clients.all_accounts")}</Button>
        </Box>
        <Box
          onClick={() => {
            setCurrentRow(currentRow);
          }}
        >
          <Button sx={footerButton}>{t("clients.all_users")}</Button>
        </Box>
        <Box
          onClick={() => {
            setCurrentRow(currentRow);
          }}
        >
          <Button sx={footerButton}>{t("clients.services")}</Button>
        </Box>
      </Box>
    </>
  );
}
