import React from "react";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Box, Alert, Stack, Snackbar, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { useQuery } from "react-query";

import { StyledButton, StyledInputBase, TypographyHeader } from "../utilities";
import { useTranslation } from "react-i18next";

const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 500;
  width: 15%;
  text-transform: capitalize;
`;

const StyledCard = styled(Box)(() => ({
  padding: "40px 80px",
  background: "white",
  borderRadius: "0px 90px 0px 90px",
  boxShadow: "inset 0px -10px 0px #B9B9B9",
  marginBottom: "30px",
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "5%",
  zIndex: 1,
  "&::after": {
    content: '""',
    zIndex: -1,
    borderBottomLeftRadius: "90px",
    position: "absolute",
    height: "90px",
    background: "#A3A4A4",
    right: 0,
    bottom: 20,
  },
}));
export default function AddpayModal() {
  const { t } = useTranslation("finance");
  const schema = yup.object().shape({
    code: yup.string().required(t("validation.code_msg")).required(""),
    fName: yup.string().required(t("validation.english_Name_is_required")),
    fNameA: yup.string().required(t("validation.arabic_Name_is_required")),
    zones: yup.string().required(t("validation.zone_is_required")),
  });
  const [pageSize, setPageSize] = React.useState(20);
  const columns = [
    {
      field: "transID",
      headerName: "Trans ID",
      sortable: false,
      editable: false,
      width: 120,
    },
    {
      field: "awb",
      headerName: "AWB",
      sortable: false,
      editable: false,
      width: 150,
    },
    {
      field: "cod",
      headerName: "COD",
      sortable: false,
      editable: false,
      width: 120,
    },
    {
      field: "transportation",
      headerName: "Transportation",
      editable: false,
      sortable: false,
      width: 160,
    },
    {
      field: "customer",
      headerName: "Customer",
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "subAccountID",
      headerName: "Sub Account",
      editable: false,
      sortable: false,
      width: 180,
    },
    {
      field: "product",
      headerName: "Product",
      editable: false,
      width: 150,
      sortable: false,
    },
    {
      field: "cityID",
      headerName: "City",
      editable: false,
      width: 150,
      sortable: false,
    },
  ];
  const { isLoading, data } = useQuery(
    "gridtablePaytocustomer",
    async () => {
      const res = await axios.post(
        `https://vsoft.com-eg.net:4041/api/PayToCustDtls/GetPage`,
        {
          pageSize: 1000,
          pageNumber: 0,
        }
      );
      return res.data.agentPaymentDtls;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();
  const [snack, setSnack] = useState(false);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const regeisterHandler = (data) => {
    reset();
    axios
      .post("https://vsoft.com-eg.net:4041/api/PayToCustDtls/Add", data)

      .then((res) => {
        queryClient.invalidateQueries("gridtablePaytocustomer");
      })
      .catch((err) => {
        setSnack(true);
      });
  };
  return (
    <>
      <div>
        <form onSubmit={handleSubmit(regeisterHandler)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "25px",
            }}
          >
            <TypographyHeader>{t("paytocust.new_pay")}</TypographyHeader>
            <StyledButton type="submit">
              {isLoading ? <CircularProgress /> : t("paytocust.save")}
            </StyledButton>
          </Box>
          <StyledCard>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                flexWrap: "wrap",
                mb: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: "2%",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <StyledLabel>{t("paytocust.customer")}</StyledLabel>
                <StyledInputBase
                  {...register("customer")}
                  placeholder="Customer"
                />
                <StyledButton>{t("paytocust.shipments")}</StyledButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  columnGap: "2%",
                  rowGap: "10px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <StyledLabel>{t("paytocust.awb_no")}</StyledLabel>
                <StyledInputBase {...register("awb")} placeholder="AWB" />
                <StyledButton>{t("paytocust.multi_awb")}</StyledButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                columnGap: "2%",
                rowGap: "20px",
                width: "100%",
              }}
            >
              <StyledLabel>{t("paytocust.notes")}</StyledLabel>
              <StyledInputBase
                {...register("notes")}
                placeholder="Notes"
                multiline
                sx={{
                  width: "490px !important",
                  "& .MuiInputBase-input": {
                    borderRadius: "10px !important",
                  },
                }}
                rows="5"
              />
            </Box>
          </StyledCard>
        </form>
        <Box>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={snack}
              autoHideDuration={4500}
              onClose={handleClose}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                {t("paytocust.error")}
              </Alert>
            </Snackbar>
          </Stack>
        </Box>
      </div>
      <div>
        <Box sx={{ height: "650px", width: "100%" }}>
          <DataGrid
            loading={isLoading}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            rows={data ?? []}
            columns={columns}
            getRowId={(rowData) => {
              return (rowData = Math.floor(Math.random() * 100000));
            }}
            hideFooterSelectedRowCount
          />
        </Box>
      </div>
    </>
  );
}
