import { Button } from "@mui/material";
import styled from "styled-components";
const StyledButton = styled(Button)`
  width: 200px;
  height: 45px;
  min-width: 150px;
  border: 0px;
  border-radius: 30px;
  letter-spacing: 0.5px;
  background: linear-gradient(180deg, #36cd59 0%, #197b30 100%) 0% 0% no-repeat
    padding-box;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
`;
export default StyledButton;
