import styled from "styled-components";
import { useTranslation } from "react-i18next";

import SaveIcon from "../../assets/images/operations/savetodrive.svg";
const Button = styled.button`
  width: 130px;
  height: 130px;
  background: transparent linear-gradient(180deg, #299422 0%, #299422 100%) 0%
    0% no-repeat padding-box;

  border: 0;
  border-radius: 75px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 20px "Roboto";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  z-index: 20;
  position: sticky;
  left: calc(100% - 90px);
  bottom: 10px;
  @media (max-width: 1440px) {
    transform: scale(0.8, 0.8);
  }
  @media (max-width: 1024x) {
    transform: scale(0.6, 0.6);
  }
  @media (max-width: 768px) {
    transform: scale(0.5, 0.5);
  }
`;

export default function SaveButton(props) {
  const { t } = useTranslation("operations_separated");

  return (
    <Button {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <img src={SaveIcon} alt="Excel logo" />
        <div>
          {t("utilities.save", {
            ns: "operations_separated",
          })}
        </div>
      </div>
    </Button>
  );
}
