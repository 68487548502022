import React from 'react';
import styles from '../../assets/css/modules/pages/Dashboard.module.css'
import { Box } from '@mui/system';
export default function Card(data) {
    const {title , number , icon , fromall,color} = data.data;
    return(
    <Box className={styles.card} sx={{boxShadow:'0px 3px 6px #cfcfcf' }}>
        <Box component={'div'} className={styles.cardColoredTopBorder} sx={{backgroundColor: color}}></Box>
        <div className={styles.cardLeft}>
            <div className={styles.cardNumber} style={{color}}>
                {number}
            </div>
            <div className={styles.cardTitle}>
                {title}
            </div>
        </div>
        <Box className={styles.cardRight} sx={{justifyContent:'space-between' , alignItems:'flex-end'}}>
            <div className={styles.cardIcon}>
                <img src={icon} alt="sad"/>
            </div>
            <div className={styles.fromAll}>
                {fromall && fromall}
            </div>
        </Box>
    </Box>
    )
}
