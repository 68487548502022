import { Grid } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  TypographyHeader,
  StyledButton,
  StyledCheckbox,
} from "../../components/utilities";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/system";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteEmployeeModal from "./DeleteEmployeeModal";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
`;

export default function ViewInformation() {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation("admin");
  const language = i18n;
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);
  const { data } = useQuery(
    "gridTableEmployees",
    async () => {
      const res = await axios.get(
        `https://vsoft.com-eg.net:4041/api/Employees/GetByID/${id}`
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  // const handleEmptyObject = (val) => {
  //   return typeof val === "object" && Object.values(val).length === 0
  //     ? null
  //     : val;
  // };
  if (!data) {
    return <div>loading</div>;
  }
  const info = data;
  return (
    <div>
      <TypographyHeader style={{ marginBottom: "30px" }}>
        {t("employees.employee_profile")}
      </TypographyHeader>
      <Grid container sx={{ gap: "2rem" }}>
        <Grid
          xs={3.6}
          sx={{
            height: "500px",
            background: "#fff",
            GridShadow: "0px 3px 6px #C4C8D066",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            placeItems: "center",
            paddingX: "0.5rem",
            pt: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: "8rem",
              width: "100%",
            }}
          >
            <Box sx={{ fontWeight: "bold" }}>{info?.fName}</Box>
            <Box>
              <Switch defaultChecked />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: "8rem",
              width: "100%",
            }}
          >
            <Box sx={{ marginBottom: "10px" }}>
              {info.Email ? info.Email : "-"}
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: "8rem",
              }}
            >
              <Box>{t("employees.branch")}</Box>
              <Box>{info?.BranchName}</Box>
            </Box>
            <hr></hr>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: "8rem",
              }}
            >
              <Box>{t("employees.delivery_commission")}</Box>
              <Box>
                {info.DeliveryCommission ? info.DeliveryCommission : "-"}
              </Box>
            </Box>
            <hr></hr>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: "8rem",
              }}
            >
              <Box sx={{ width: "20%" }}>{t("employees.hiring_date")}</Box>
              <Box sx={{ width: "80%" }}>
                {info.HiringDate ? info.HiringDate : "-"}
              </Box>
            </Box>
            <hr></hr>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: "8rem",
              }}
            >
              <Box>{t("employees.representative")}</Box>
              <Box>{info?.fPlayer ? "Yes" : "NO"}</Box>
            </Box>
            <hr></hr>
          </Box>
          <StyledButton
            sx={{ margin: "15px !important" }}
            onClick={() => navigate(`/admin/more-information/${id}`)}
          >
            {t("employees.edit_profile")}
          </StyledButton>
          <StyledButton
            sx={{
              background: "transparent !important",
              border: "var(--secondary-color) solid 1px !important",
              color: "var(--secondary-color) !important",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("employees.delete")}
          </StyledButton>
        </Grid>
        <Grid
          container
          xs={8.0}
          sx={{
            height: "700px",
            background: "#fff",
            GridShadow: "0px 3px 6px #C4C8D066",
            borderRadius: "0px 20px 20px 20px",
            padding: "30px 70px",
          }}
        >
          <Grid xs={3}>
            <StyledLabel>{t("employees.national_id")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info?.ID_NO}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.type_of_car")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.VehicleType && info.VehicleType}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.bank_name")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.Bank ? info.Bank : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.phone_number_1")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.Telephone1 ? info.Telephone1 : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.account_number")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.AcountNo ? info.AcountNo : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.phone_number_2")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.Telephone2 ? info.Telephone2 : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.personal_code")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.HRCode ? info.HRCode : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.address")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.Address ? info.Address : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.fingerprint")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.FingerPrint ? info.FingerPrint : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.job_description")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.fDescription ? info.fDescription : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.notes")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.fDescription ? info.fDescription : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.qualification")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.Qualification ? info.Qualification : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.social_insurance")}</StyledLabel>
          </Grid>
          <Grid xs={3}>
            <StyledCheckbox
              checked={info.SocialIncurance === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
            />
          </Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.graduation_year")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.Graduation ? info.Graduation : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.driving_license")}</StyledLabel>
          </Grid>
          <Grid xs={3}>
            <StyledCheckbox
              checked={info.DrivingLiscence === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
            />
          </Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.experience")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.Experience ? info.Experience : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.own_a_car")}</StyledLabel>
          </Grid>
          <Grid xs={3}>
            <StyledCheckbox
              checked={info.VehicleOwnership === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
            />
          </Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.direct_manager")}</StyledLabel>
          </Grid>
          <Grid xs={3}>
            {info.DirectManagerID ? info.DirectManagerID : "-"}
          </Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.leave_work")}</StyledLabel>
          </Grid>
          <Grid xs={3}>
            <StyledCheckbox
              checked={info.fDeleted === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
            />
          </Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.basic_salary")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.SalaryFixed ? info.SalaryFixed : "-"}</Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.medical_insurance")}</StyledLabel>
          </Grid>
          <Grid xs={3}>
            <StyledCheckbox
              checked={info.MedicalIncurance === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
            />
          </Grid>
          <Grid xs={3}>
            <StyledLabel>{t("employees.variable_salary")}</StyledLabel>
          </Grid>
          <Grid xs={3}>{info.SalaryVariable ? info.SalaryVariable : "-"}</Grid>
        </Grid>
      </Grid>
      <DeleteEmployeeModal
        openDeleteIcon={open}
        setOpenDeleteIcon={setOpen}
        id={id}
      />
    </div>
  );
}
