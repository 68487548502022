import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import CollapsableListItem from "./CollapsableListItem";
import { useTranslation } from "react-i18next";

const drawerWidth = 260;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  top: "70px",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function Sidebar(props) {
  const { t } = useTranslation("layout");
  const [open, setOpen] = useState(false);
  const [disableFunctions, setDisableFunctions] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const location = useLocation().pathname;
  const { setCurrentWidth } = props;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const handleHoverOn = () => setOpen(true);
  const handleHoverOff = () => setOpen(false);
  const handleToggleNavbar = () => {
    setCurrentWidth(!open ? "260px" : "73px");
    setOpen(!open);
    setDisableFunctions(disableFunctions => !disableFunctions);
  };
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 25,
          left: language === "ar" ? "auto" : "23px",
          right: language === "ar" ? "28px" : "auto",
          zIndex: "101",
          fontSize: "30px",
          cursor: "pointer",
        }}
        onClick={handleToggleNavbar}
      >
        <FontAwesomeIcon
          icon="bars"
          color={open ? "var(--primary-color)" : "#7A84F9"}
          size="xs"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          position: disableFunctions ? "relative" : "fixed",
          zIndex: "3",
        }}
        onMouseEnter={!disableFunctions ? handleHoverOn : null}
        onMouseLeave={!disableFunctions ? handleHoverOff : null}
      >
        <Drawer
          variant="permanent"
          dir="ltr"
          anchor={language === "ar" ? "right" : "left"}
          open={open}
        >
          <List
            sx={{
              "& > a , & > div": { left: "7px" },
              top: "85px",
              maxHeight: "calc(100vh - 85px)",
              overflowX: "hidden",
              "&::scrollbar": {
                width: "5px",
              },
              scrollbarWidth: "thin",
              overflowY: "auto",
            }}
            onClick={() => setOpen(true)}
          >
            <ListItemButton
              selected={selectedIndex === "Home"}
              onClick={() => setSelectedIndex("Home")}
              key="Home"
              sx={{
                "& span ": {
                  color:
                    selectedIndex === "Home" || location === "/"
                      ? "var(--primary-color)"
                      : "currentColor",
                },
              }}
              component={NavLink}
              to="/"
            >
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M14.5,7.345,6.722,13.567v8.71h5.556V17.833h4.444v4.444h5.556V14.1a1.111,1.111,0,0,0-.417-.867Zm0-2.845,8.749,7A3.333,3.333,0,0,1,24.5,14.1v8.176A2.222,2.222,0,0,1,22.278,24.5H6.722A2.222,2.222,0,0,1,4.5,22.278v-8.71a2.222,2.222,0,0,1,.833-1.734Z"
                    transform="translate(-4.5 -4.5)"
                    fill={
                      selectedIndex === "Finance" ||
                      location.includes("finance") === "Finance"
                        ? "var(--secondary-color)"
                        : "#7A84F9"
                    }
                  />
                </svg>
              </ListItemIcon>
              <ListItemText primary={`${t("Dashboard")}`} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === "Agents"}
              onClick={() => setSelectedIndex("Agents")}
              key="Home"
              sx={{
                "& span ": {
                  color:
                    selectedIndex === "Agents" || location === "/"
                      ? "var(--primary-color)"
                      : "currentColor",
                },
              }}
              component={NavLink}
              to="/admin/employees"
            >
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 14.286 20"
                >
                  <g transform="translate(-2.25 -2.25)">
                    <path
                      d="M10.625,3.679A3.572,3.572,0,1,1,7.054,7.25a3.572,3.572,0,0,1,3.572-3.572m0-1.429a5,5,0,1,0,5,5A5,5,0,0,0,10.625,2.25Z"
                      transform="translate(-1.232)"
                      fill={
                        selectedIndex === "admin"
                          ? "var(--secondary-color)"
                          : "#7A84F9"
                      }
                    />
                    <path
                      d="M16.536,28.822H15.107V25.25a3.572,3.572,0,0,0-3.572-3.572H7.25A3.572,3.572,0,0,0,3.679,25.25v3.572H2.25V25.25a5,5,0,0,1,5-5h4.286a5,5,0,0,1,5,5Z"
                      transform="translate(0 -6.571)"
                      fill={
                        selectedIndex === "admin"
                          ? "var(--secondary-color)"
                          : "#7A84F9"
                      }
                    />
                  </g>
                </svg>
              </ListItemIcon>
              <ListItemText primary={`${t("Agents")}`} />
            </ListItemButton>

            <CollapsableListItem
              drawerOpen={open}
              active={
                selectedIndex === "reports" || location.includes("reports")
              }
              setSelectedIndex={setSelectedIndex}
              index={"donations"}
              name={`${t("Reports")}`}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 21.75 26"
                >
                  <g transform="translate(-5.625 -2.25)">
                    <path
                      d="M11.25,20.25h9V22.5h-9Z"
                      transform="translate(-1.071 -3.385)"
                      fill={
                        selectedIndex === "report"
                          ? "var(--secondary-color)"
                          : "#7A84F9"
                      }
                    />
                    <path
                      d="M11.25,14.625h10.5v2.25H11.25Z"
                      transform="translate(0 -2.327)"
                      fill={
                        selectedIndex === "report"
                          ? "var(--secondary-color)"
                          : "#7A84F9"
                      }
                    />
                    <path
                      d="M11.25,25.875h5.625v2.25H11.25Z"
                      transform="translate(-0.882 -4.442)"
                      fill={
                        selectedIndex === "report"
                          ? "var(--secondary-color)"
                          : "#7A84F9"
                      }
                    />
                    <path
                      d="M25.4,5.036H22.432V4.107A1.92,1.92,0,0,0,20.455,2.25H12.545a1.92,1.92,0,0,0-1.977,1.857v.929H7.6A1.92,1.92,0,0,0,5.625,6.893v19.5A1.92,1.92,0,0,0,7.6,28.25H25.4a1.92,1.92,0,0,0,1.977-1.857V6.893A1.92,1.92,0,0,0,25.4,5.036ZM12.545,4.107h7.909V7.821H12.545ZM25.4,26.393H7.6V6.893h2.966V9.679H22.432V6.893H25.4Z"
                      fill={
                        selectedIndex === "report"
                          ? "var(--secondary-color)"
                          : "#7A84F9"
                      }
                    />
                  </g>
                </svg>
              }
            >
              <List sx={{ paddingInline: 2 }}>
                <ListItemButton
                  onClick={() => setSelectedIndex("Payment Status Tracking")}
                  key="Payment Status Tracking"
                  sx={{
                    "& span ": {
                      color:
                        selectedIndex === "Payment Status Tracking" ||
                        location === "/reports/payment-status-tracking"
                          ? "var(--primary-color)"
                          : "currentColor",
                      fontSize: 13,
                    },
                  }}
                  component={NavLink}
                  to="reports/payment-status-tracking"
                >
                  <ListItemText primary={`${t("Payment Status Tracking")}`} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => setSelectedIndex("Runner Activity Inquiry")}
                  key="Runner Activity Inquiry"
                  sx={{
                    "& span ": {
                      color:
                        selectedIndex === "Runner Activity Inquiry" ||
                        location === "/reports/runner-activity-inquiry"
                          ? "var(--primary-color)"
                          : "currentColor",
                      fontSize: 13,
                    },
                  }}
                  component={NavLink}
                  to="reports/runner-activity-inquiry"
                >
                  <ListItemText primary={`${t("Runner Activity Inquiry")}`} />
                </ListItemButton>

                <ListItemButton
                  onClick={() => setSelectedIndex("Track Runners")}
                  key="Track Runners"
                  sx={{
                    "& span ": {
                      color:
                        selectedIndex === "Track Runners" ||
                        location === "/reports/track-runners"
                          ? "var(--primary-color)"
                          : "currentColor",
                      fontSize: 13,
                    },
                  }}
                  component={NavLink}
                  to="/reports/track-runners"
                >
                  <ListItemText primary={`${t("Track Runners")}`} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => setSelectedIndex("Payments Bulk")}
                  key="New DRS"
                  sx={{
                    "& span ": {
                      color:
                        selectedIndex === "Payments Bulk" ||
                        location === "/reports/payments-bulk"
                          ? "var(--primary-color)"
                          : "currentColor",
                      fontSize: 13,
                    },
                  }}
                  component={NavLink}
                  to="/reports/payments-bulk"
                >
                  <ListItemText primary={`${t("Payments Inquiry")}`} />
                </ListItemButton>
              </List>
            </CollapsableListItem>
            <CollapsableListItem
              drawerOpen={open}
              active={
                selectedIndex === "donations" || location.includes("donations")
              }
              setSelectedIndex={setSelectedIndex}
              index={"donations"}
              name={`${t("donations_section.donations")}`}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 11.963 20"
                >
                  <path
                    d="M16.435,27.083V25.027H12.417V21.1h8.037V19.047H14.474a2.064,2.064,0,0,1-1.449-.561,1.828,1.828,0,0,1-.608-1.4V11.1a1.828,1.828,0,0,1,.608-1.4,2.064,2.064,0,0,1,1.449-.561h1.963V7.083h4.018V9.139H24.38v3.925H16.436v2.056h5.981a1.916,1.916,0,0,1,1.963,1.963v5.981a1.916,1.916,0,0,1-1.963,1.963H20.454v2.056H16.436Z"
                    transform="translate(-12.417 -7.083)"
                    fill={
                      selectedIndex === "donations" ||
                      location.includes("donations") === "donations"
                        ? "var(--secondary-color)"
                        : "#7A84F9"
                    }
                  />
                </svg>
              }
            >
              <List sx={{ paddingInline: 2 }}>
                <ListItemButton
                  onClick={() => setSelectedIndex("new_donation")}
                  key="new_donation"
                  sx={{
                    "& span ": {
                      color:
                        selectedIndex === "new_donation" ||
                        location === "/donations/new_donation"
                          ? "var(--primary-color)"
                          : "currentColor",
                      fontSize: 13,
                    },
                  }}
                  component={NavLink}
                  to="/donations/donation"
                >
                  <ListItemText
                    primary={`${t("donations_section.new_donation")}`}
                  />
                </ListItemButton>
                <CollapsableListItem
                  drawerOpen={open}
                  subsection
                  active={selectedIndex === "Assign Visits"}
                  setSelectedIndex={setSelectedIndex}
                  index={"Assign To Runner"}
                  name={`${t("donations_section.assign_cash")}`}
                >
                  <List sx={{ paddingInline: 2 }}>
                    <ListItemButton
                      onClick={() => setSelectedIndex("New DRS")}
                      key="New DRS"
                      sx={{
                        "& span ": {
                          color:
                            selectedIndex === "New DRS" ||
                            location === "/donations/assign/new"
                              ? "var(--primary-color)"
                              : "currentColor",
                          fontSize: 13,
                        },
                      }}
                      component={NavLink}
                      to="donations/assign/new"
                    >
                      <ListItemText primary={`${t("New Visits Sheet")}`} />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setSelectedIndex("DRS List")}
                      key="DRS List"
                      sx={{
                        "& span ": {
                          color:
                            selectedIndex === "DRS List" ||
                            location === "/donations/assign/list"
                              ? "var(--primary-color)"
                              : "currentColor",
                          fontSize: 13,
                        },
                      }}
                      component={NavLink}
                      to="donations/assign/list"
                    >
                      <ListItemText primary={`${t("Visits List")}`} />
                    </ListItemButton>
                  </List>
                </CollapsableListItem>
                <ListItemButton
                  onClick={() => setSelectedIndex("collection")}
                  key="collection"
                  sx={{
                    "& span ": {
                      color:
                        selectedIndex === "collection" ||
                        location === "/donations/collection-definition"
                          ? "var(--primary-color)"
                          : "currentColor",
                      fontSize: 13,
                    },
                  }}
                  component={NavLink}
                  to="/donations/collect"
                >
                  <ListItemText
                    primary={`${t("donations_section.cash_collection")}`}
                  />
                </ListItemButton>
              </List>
            </CollapsableListItem>
          </List>
        </Drawer>
      </Box>
    </>
  );
}
