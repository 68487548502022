import {
  Box,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StepperAccountForm from "./StepperAccountForm";
import StepperActionForm from "./StepperActionForm";
import StepperClientsInfoForm from "./StepperClientsInfoForm";
import { QueryClient } from "react-query";
const formFont = {
  fontSize: "2em",
  color: "#252E48",
  letterSpacing: 0,
  fontFamily: "poppins",
};
export default function AddingModalClients() {
  const { t } = useTranslation("finance");
  const [activeStep, setActiveStep] = useState(0);
  const [stepperData, setStepperData] = useState({});
  const navigate = useNavigate();
  const nextStep = () => {
    if (activeStep < 2) setActiveStep(activeStep + 1);
  };
  const previousStep = () => {
    if (activeStep !== 0) setActiveStep(activeStep - 1);
  };
  async function handleFormSubmit(data) {
    await axios
      .post(`https://vsoft.com-eg.net:4041/api/Clients/Add`, {
        ...stepperData.client,
        ...stepperData.account,
        ...data,
      })
      .then(() => {
        QueryClient.invalidateQueries("ClientsDetails");
        navigate("/finance/clients");
      })
      .catch((err) => {
        navigate("/finance/clients");
      });
  }
  const stepperProps = {
    setStepperData,
    activeStep,
    nextStep,
    stepperData,
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "8vh",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1em",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                variant: "h1",
                component: "h1",
                color: "#252E48",
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              {t("clients.client_details")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        component={Container}
        sx={{
          borderRadius: "0 50px 0 50px",
          width: "100%",
          minWidth: "500px",
          background: "#fff",
          py: "2rem",
          marginBottom: "0.5rem",
        }}
      >
        <Stepper alternativeLabel activeStep={activeStep} sx={{ mb: 2 }}>
          <Step>
            <StepLabel sx={formFont}>{t("clients.clients_info")}</StepLabel>
          </Step>
          <Step>
            <StepLabel sx={formFont}>{t("clients.account")}</StepLabel>
          </Step>
          <Step>
            <StepLabel sx={formFont}>{t("clients.action")}</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && <StepperClientsInfoForm {...stepperProps} />}
        {activeStep === 1 && (
          <StepperAccountForm previousStep={previousStep} {...stepperProps} />
        )}
        {activeStep === 2 && (
          <StepperActionForm
            setStepperData={setStepperData}
            activeStep={activeStep}
            previousStep={previousStep}
            handleFormSubmit={handleFormSubmit}
            stepperData={stepperData}
          />
        )}
      </Box>
    </>
  );
}
