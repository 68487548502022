import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Modal,
  Alert,
  Stack,
  Snackbar,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import {
  StyledButton,
  StyledInputBase,
  BoxModal,
  TypographyHeadModal,
  StyledCheckbox,
  ErrorText,
} from "../utilities";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
`;

export default function AddingModalCreateUserMobileApp(props) {
  const { t } = useTranslation("admin");
  const schema = yup.object().shape({
    displayName: yup.string().required(t("validation.display_name_is_req")),
    webUserName: yup.string().required(t("validation.user_name_is_req")),
    webPassword: yup.string().required(t("validation.password_required")),
    passwordConfirm: yup
      .string()
      .oneOf(
        [yup.ref("webPassword"), null],
        t("validation.password_must_match")
      ),
  });
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [snack, setSnack] = useState(false);
  const handleClose = (UserMobile) => {
    if (UserMobile === "clickaway") {
      return;
    }
    setSnack(false);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  async function regeisterHandler(data) {
    setIsLoading(true);
    setDisable(true);
    await axios
      .post("https://vsoft.com-eg.net:4041/api/WebRunnerUsers/Add", {
        runnerID: id,
        ...data,
      })
      .then((res) => {
        queryClient.invalidateQueries("gridTableWebUser");
        props.handleCloseCreateUser();
        setIsLoading(false);
        setDisable(false);
        reset();
      })
      .catch((err) => {
        setSnack(true);
        setIsLoading(false);
        setDisable(false);
      });
  }

  return (
    <div>
      <Modal open={props.open}>
        <form onSubmit={handleSubmit(regeisterHandler)}>
          <BoxModal variant="solo">
            <TypographyHeadModal>
              {t("employees.create_account")}
            </TypographyHeadModal>
            <Box
              className="items"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0 50px",
              }}
            >
              <Box
                className="item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Box>
                  <StyledLabel htmlFor="display_name">
                    {t("employees.display_name")}
                  </StyledLabel>
                  <ErrorText>{errors.displayName?.message}</ErrorText>
                </Box>
                <StyledInputBase
                  id="display_name"
                  {...register("displayName")}
                  placeholder={t("employees.name")}
                />
              </Box>
              <Box
                className="item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Box>
                  <StyledLabel htmlFor="user_name">
                    {t("employees.user_name")}
                  </StyledLabel>
                  <ErrorText>{errors.webUserName?.message}</ErrorText>
                </Box>
                <StyledInputBase
                  id="user_name"
                  {...register("webUserName")}
                  placeholder={t("employees.name")}
                />
              </Box>
              <Box
                className="item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Box>
                  <StyledLabel htmlFor="password">
                    {t("employees.password")}
                  </StyledLabel>
                  <ErrorText>{errors.webPassword?.message}</ErrorText>
                </Box>
                <Box position={"relative"}>
                  <StyledInputBase
                    id="password"
                    {...register("webPassword")}
                    placeholder="Password"
                    type={passwordType}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "25%",
                      transform: "translate(90%,-50%)",
                      background: "#fff",
                      width: "20%",
                      height: "90%",
                      zIndex: "1600",
                    }}
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Box
                className="item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Box>
                  <StyledLabel htmlFor="passwordConfirm">
                    {t("employees.confirm_password")}
                  </StyledLabel>
                  <ErrorText>{errors.passwordConfirm?.message}</ErrorText>
                </Box>
                <Box position={"relative"}>
                  <StyledInputBase
                    id="passwordConfirm"
                    {...register("passwordConfirm")}
                    placeholder="Password"
                    type={confirmPasswordType}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "25%",
                      transform: "translate(90%,-50%)",
                      background: "#fff",
                      width: "20%",
                      height: "90%",
                      zIndex: "16000 !important",
                    }}
                    onClick={toggleConfirmPassword}
                  >
                    {confirmPasswordType === "password" ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Box
                className="item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Box>
                  <StyledLabel htmlFor="active">
                    {t("employees.active")}
                  </StyledLabel>
                </Box>
                <Box width={"40%"}>
                  <StyledCheckbox id="active" {...register("isEnabled")} />
                </Box>
              </Box>
            </Box>
            {/* ================btn============ */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "2rem",
              }}
            >
              <StyledButton
                modal
                onClick={() => {
                  props.handleCloseCreateUser();
                }}
              >
                {t("employees.cancel")}
              </StyledButton>
              <StyledButton modal disabled={disable} type="submit">
                {isLoading ? <CircularProgress /> : t("employees.save")}
              </StyledButton>
            </Box>
          </BoxModal>
        </form>
      </Modal>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("employees.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
