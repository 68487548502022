import React from "react";
import { Box } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const data = [
  { name: "Jenuary", Delivered: 300, Pending: 400, Rto: 2400, amt: 400 },
  { name: "February ", Delivered: 3000, Pending: 600, Rto: 2900, amt: 2600 },
  { name: "March", Delivered: 2500, Pending: 600, Rto: 2100, amt: 2100 },
  { name: "April ", Delivered: 1800, Pending: 500, Rto: 1900, amt: 2300 },
  { name: "May ", Delivered: 1200, Pending: 800, Rto: 800, amt: 2200 },
  { name: "June ", Delivered: 300, Pending: 1200, Rto: 1900, amt: 1600 },
  { name: "July ", Delivered: 1500, Pending: 1600, Rto: 2500, amt: 600 },
];

export default function Statistics() {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        borderRadius: "35px",
        marginTop: "2rem",
        alignItems: "center",
        minHeight: "300px",
        position: "relative",
        width: "100%",
        borderTopRightRadius: "35px",
        background: "white",
        boxShadow: "0px 3px 6px #C4C8D066",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #eee",
          borderLeft: "0px",
          borderBlock: "0px",
          borderRadius: "35px 0px 35px 35px",
          minHeight: "300px",
          background: "transparent var(--gradient)",
          minWidth: { md: 300, sm: "100%", xs: "100%" },
          marginBottom: { sm: 10, xs: 5, lg: 0, md: 0 },
        }}
      >
        <Box
          sx={{
            height: "100px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            letterSpacing: "1px",
            fontSize: "30px",
            color: "white",
            borderBottom: "3px solid white",
          }}
        >
          Statistics
        </Box>
        <Box
          sx={{
            height: "200px",
            width: "100%",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontWeight: 500,
            letterSpacing: "1px",
            fontSize: "15pt",
            color: "white",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ color: "white", fontSize: "15px", fontWeight: "500" }}>
              Delivered Shipments
            </Box>
            <Box sx={{ fontSize: "14pt", color: "#36CD59" }}>300000</Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ color: "white", fontSize: "15px", fontWeight: "500" }}>
              Pending Shipments
            </Box>
            <Box sx={{ fontSize: "14pt", color: "#FFDF00" }}>10000</Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ color: "white", fontSize: "15px", fontWeight: "500" }}>
              Returned Shipments
            </Box>
            <Box sx={{ fontSize: "14pt", color: "#FF9900" }}>3000</Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "calc(100% - 300px)",
          borderLeft: "none",
          minWidth: { xs: "100%", md: "350px" },
        }}
      >
        <ResponsiveContainer
          width="90%"
          className=""
          stroke="none"
          height={250}
        >
          <LineChart data={data}>
            <Line
              type="monotone"
              strokeWidth={5}
              stroke="#FFDF00"
              dataKey="Pending"
            />
            <Line
              type="monotone"
              strokeWidth={5}
              stroke="#36CD59"
              dataKey="Delivered"
            />
            <Line
              type="monotone"
              strokeWidth={5}
              stroke="#F00B0B"
              dataKey="Rto"
            />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            <XAxis dataKey="name" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}
