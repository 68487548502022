import {
  FormLabel,
  Grid,
  InputBase,
  Snackbar,
  Stack,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import {
  ErrorText,
  StyledButton,
  StyledInputBase,
  TypographyHeader,
  UploadImgSvg,
} from "../utilities";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
`;
const StyledCard = styled(Box)(() => ({
  padding: "40px 40px 90px 40px ",
  background: "white",
  borderRadius: "0px 90px 0px 90px",
  boxShadow: "inset 0px -10px 0px #B9B9B9",
  marginBottom: "30px",
  width: "90%",
  zIndex: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  flexWrap: "Wrap",
  "&::after": {
    content: '""',
    zIndex: -1,
    borderBottomLeftRadius: "90px",
    position: "absolute",
    height: "90px",
    background: "#A3A4A4",
    right: 0,
    bottom: 20,
  },
}));
export default function AddCompanyData() {
  const { t, i18n } = useTranslation("admin");
  const schema = yup.object().shape({
    CompanyName: yup
      .string(t("validation.fName_msg_string"))
      .required(t("validation.fName_msg_required"))
      .min(4, t("validation.min_4"))
      .max(50, t("validation.max_50")),
    fAddress: yup
      .string(t("validation.fAddress_msg_string"))
      .required(t("validation.fAddress_msg_required"))
      .min(4, t("validation.min_4")),
    fAddressA: yup
      .string(t("validation.fAddress_msg_string"))
      .required(t("validation.fAddress_msg_required"))
      .min(4, t("validation.min_4")),
    Email: yup
      .string()
      .email(t("validation.mail_msg_string"))
      .required(t("validation.mail_msg_required")),
    fTelephone1: yup
      .string(t("validation.telephone_msg_string"))
      .required(t("validation.telephone_msg_required"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("validation.telephone_msg_match")
      ),
    fTelephone2: yup
      .string(t("validation.telephone_msg_string"))
      .required(t("validation.telephone_msg_required"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("validation.telephone_msg_match")
      ),
    Fax: yup
      .string(t("validation.fax_msg_string"))
      .required(t("validation.fax_msg_required"))
      .matches(/^\+?[0-9]{8,}$/, "Please Write Valid Fax Number"),
    WebSite: yup
      .string(t("validation.website_msg_string"))
      .required(t("validation.website_msg_required"))
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
        t("validation.website_msg_match")
      ),
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const { language } = i18n;
  const handleClose = reason => {
    if (reason === "clickaway") {
      return;
    }

    setSnack(false);
  };
  const { data } = useQuery("componydata", async () => {
    const res = await axios.get(
      `https://vsoft.com-eg.net:4041/api/Companies/GetCompanyInfo`
    );
    return res.data.data;
  });
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: data,
  });
  const setValues = obj => {
    for (let i = 0; i < Object.keys(obj || []).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  const updateHandler = data => {
    setIsLoading(true);
    setDisable(true);
    axios
      .put(`https://vsoft.com-eg.net:4041/api/Companies/Update`, data)
      .then(res => {
        queryClient.invalidateQueries("componydata");
        navigate("/admin/company-data");
        setIsLoading(false);
        setDisable(false);
      })
      .catch(err => {
        setSnack(true);
        setIsLoading(false);
        setDisable(false);
      });
  };
  useEffect(() => {
    data && setValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [selectedImg, setSelectedImg] = useState([]);
  const [fileError, setFileError] = useState();
  const selectFile = e => {
    if (e.target.files[0].size <= 266000) {
      const selecetedFiles = e.target.files;
      const selectedFilesArr = Array.from(selecetedFiles);
      const ImageArray = selectedFilesArr.map(file => {
        return URL.createObjectURL(file);
      });

      setSelectedImg(ImageArray);
      setFileError(undefined);
    } else {
      setFileError(t("validation.logo_error"));
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(updateHandler)}>
        <TypographyHeader style={{ marginBottom: "40px" }}>
          {t("company_data.company_profile")}
        </TypographyHeader>
        <StyledCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "55%",
            }}
          >
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="name">
                  {t("company_data.company_name")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("CompanyName")}
                  id="name"
                  placeholder={t("company_data.company_name")}
                  fullWidth={true}
                />
              </Grid>
              <ErrorText>{errors.CompanyName?.message}</ErrorText>
            </Grid>
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="address">
                  {t("company_data.address_in_english")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("fAddress")}
                  id="address"
                  placeholder={t("company_data.address_in_english")}
                  fullWidth={true}
                />
              </Grid>
              <ErrorText>{errors.fAddress?.message}</ErrorText>
            </Grid>
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="adressA">
                  {t("company_data.address_in_arabic")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("fAddressA")}
                  id="adressA"
                  placeholder={t("company_data.address_in_arabic")}
                  fullWidth={true}
                />
              </Grid>
              <ErrorText>{errors.fAddressA?.message}</ErrorText>
            </Grid>
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="mail">
                  {t("company_data.email")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("Email")}
                  id="mail"
                  placeholder={t("company_data.email")}
                  fullWidth={true}
                />
              </Grid>
              <ErrorText>{errors.Email?.message}</ErrorText>
            </Grid>
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="phone1">
                  {t("company_data.phone1")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("fTelephone1")}
                  id="phone1"
                  placeholder={t("company_data.phone1")}
                  fullWidth={true}
                />
              </Grid>
              <ErrorText>{errors.fTelephone1?.message}</ErrorText>
            </Grid>
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="phone2">
                  {t("company_data.phone2")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("fTelephone2")}
                  id="phone2"
                  placeholder={t("company_data.phone2")}
                  fullWidth={true}
                />
              </Grid>
              <ErrorText>{errors.fTelephone2?.message}</ErrorText>
            </Grid>
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="fax">{t("company_data.fax")}</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("Fax")}
                  id="fax"
                  placeholder={t("company_data.fax")}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container rowGap={0.5} sx={{ alignItems: "center" }}>
              <Grid item xs={5}>
                <StyledLabel htmlFor="website">
                  {t("company_data.website")}
                </StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <StyledInputBase
                  {...register("WebSite")}
                  id="website"
                  placeholder={t("company_data.website")}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "40%",
            }}
          >
            <Grid
              container
              rowGap={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={3}></Grid>
              <Grid item xs={9} sx={{ direction: "rtl" }}>
                <FormLabel
                  htmlFor="image-input"
                  sx={{
                    cursor: "pointer",
                    width: "100%",
                    height: "260px",
                    border: "2px dashed var(--secondary-color)",
                    borderRadius: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedImg.length > 0 ? (
                    <img
                      src={selectedImg}
                      width="100%"
                      sizes="64kb"
                      alt="upload"
                    />
                  ) : data?.Logo1 ? (
                    <img
                      src={`data:image/png;base64,${data.Logo1}`}
                      width="100%"
                      sizes="64kb"
                      alt="upload"
                    />
                  ) : (
                    <UploadImgSvg />
                  )}
                </FormLabel>
                <InputBase
                  type="file"
                  name="file"
                  id="image-input"
                  accept="image/png, image/jpg, image/jpeg, image/svg, image/webp"
                  hidden
                  onChange={selectFile}
                />
                {fileError && <ErrorText>{fileError}</ErrorText>}
              </Grid>
            </Grid>
            <Grid
              container
              rowGap={2}
              justifyContent="center"
              alignItems="center"
            >
              {/* <Grid item xs={3}>
                <StyledLabel htmlFor="name">
                  {t("company_data.notes")}
                </StyledLabel>
              </Grid>
              <Grid item xs={9}>
                <StyledInputBase
                  value={data?.notes}
                  {...register("notes")}
                  id="name"
                  placeholder={t("company_data.notes")}
                  fullWidth={true}
                />
              </Grid> */}
              <StyledButton
                type="submit"
                disabled={disable}
                sx={{ width: "100% !important" }}
              >
                {isLoading ? <CircularProgress /> : t("company_data.save")}
              </StyledButton>
            </Grid>
          </Box>
        </StyledCard>
      </form>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("branches.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
