import React from "react";
import { useState } from "react";
import {
  Box,
  Modal,
  Alert,
  Snackbar,
  CircularProgress,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient, useQuery } from "react-query";
import {
  BoxModal,
  StyledButton,
  TypographyHeadModal,
  StyledInputBase,
  ErrorText,
  StyledCheckbox,
} from "../utilities";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 400;
  width: 40%;
`;

export default function AddEmployeesModal(props) {
  const { t } = useTranslation("admin");
  const schema = yup.object().shape({
    code: yup.string().required(t("validation.code_msg")),
    fName: yup.string().required(t("validation.fName_msg_required")),
    fNameA: yup.string().required(t("validation.fNameA_msg_required")),
    branchID: yup.string().required(t("validation.branch_msg_required")),
    hiringDate: yup.string().required(t("validation.date_msg_required")),
    dateOfBirth: yup.string().required(t("validation.date_msg_required")),
    deliveryCommission: yup
      .string()
      .required(t("validation.delivery_commission")),
  });
  const queryClient = useQueryClient();
  const [snack, setSnack] = useState(false);
  const { data: branchesData } = useQuery("branches_list", async () => {
    const data = await axios.post(
      "https://vsoft.com-eg.net:4041/api/Branches/GetList",
      {
        pageSize: 0,
        pageNumber: 0,
      }
    );
    return data.data.data;
  });
  const handleClose = employee => {
    if (employee === "clickaway") {
      return;
    }
    setSnack(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  async function regeisterHandler(data) {
    const employeeSchema = {
      fID: 0,
      code: "",
      fName: "",
      fNameA: "",
      fPlayer: true,
      routeID: 0,
      branchID: 0,
      dateOfBirth: "",
      iD_NO: "",
      address: "",
      telephone1: "",
      telephone2: "",
      mobile: "",
      email: "",
      position: "",
      qualification: "",
      graduation: "",
      experience: "",
      directManagerID: 0,
      hiringDate: "",
      salaryFixed: 0,
      salaryVariable: 0,
      drivingLiscence: true,
      medicalIncurance: true,
      socialIncurance: true,
      vehicleOwnership: true,
      vehicleType: "",
      fDeleted: true,
      bank: "",
      acountNo: "",
      fDescription: "",
      agentID: 0,
      hrCode: "",
      fingerPrint: "",
      sendSMS: true,
      deliveryCommission: 0,
      branchName: "",
      branchNameA: "",
    };
    setDisable(true);
    setIsLoading(true);
    await axios
      .post("https://vsoft.com-eg.net:4041/api/Employees/Add", {
        ...employeeSchema,
        ...data,
      })
      .then(res => {
        queryClient.invalidateQueries("gridTableEmployees");
        props.handleClose();
        setIsLoading(false);
        setDisable(false);
        reset();
      })
      .catch(err => {
        setSnack(true);
        setIsLoading(false);
        setDisable(false);
      });
  }
  return (
    <div>
      <Modal open={props.open}>
        <form onSubmit={handleSubmit(regeisterHandler)}>
          <BoxModal>
            <TypographyHeadModal>{t("Add Agent")}</TypographyHeadModal>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "25px",
              }}
            >
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="code">
                    {t("employees.code")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="code"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("code")}
                      type="number"
                      placeholder={t("employees.code")}
                    />
                  </Box>
                  <ErrorText>{errors.code?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="english_name">
                    {t("employees.english_name")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="english_name"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("fName")}
                      placeholder={t("employees.name")}
                    />
                  </Box>
                  <ErrorText>{errors.fName?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="arabic_name">
                    {t("employees.arabic_name")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="arabic_name"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("fNameA")}
                      placeholder={t("employees.name")}
                    />
                  </Box>
                  <ErrorText>{errors.fNameA?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="delivery_commission">
                    {t("Commission")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="delivery_commission"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("deliveryCommission")}
                      type="number"
                      placeholder={t("employees.number")}
                    />
                  </Box>
                  <ErrorText>{errors.deliveryCommission?.message}</ErrorText>
                </Box>
                <Box
                  sx={{
                    width: "36%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledLabel htmlFor="representative">
                    {t("employees.representative")}
                  </StyledLabel>
                  <Box sx={{ width: "20%" }}>
                    <StyledCheckbox
                      id="representative"
                      {...register("fPlayer")}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="hiring_date">
                    {t("employees.hiring_date")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="hiring_date"
                      sx={{ width: "100%" }}
                      {...register("hiringDate")}
                      type="date"
                    />
                  </Box>
                  <ErrorText>{errors.hiringDate?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="birth_date">
                    {t("employees.birth_date")}
                  </StyledLabel>
                  <Box sx={{ width: "60%" }}>
                    <StyledInputBase
                      id="birth_date"
                      sx={{ width: "100%", minWidth: "150px !important" }}
                      {...register("dateOfBirth")}
                      type="date"
                    />
                  </Box>
                  <ErrorText>{errors.dateOfBirth?.message}</ErrorText>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%", columnGap: "4%" }}>
                <Box
                  sx={{
                    width: "36%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <StyledLabel htmlFor="send_sms">
                    {t("employees.send_sms")}
                  </StyledLabel>
                  <Box sx={{ width: "20%" }}>
                    <StyledCheckbox id="send_sms" {...register("sendSMS")} />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* ===================btn================ */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "1rem",
              }}
            >
              <StyledButton
                modal
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                {t("employees.cancel")}
              </StyledButton>
              <StyledButton modal type="submit" disabled={disable}>
                {isLoading ? <CircularProgress /> : t("employees.save")}
              </StyledButton>
            </Box>
          </BoxModal>
        </form>
      </Modal>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("employees.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
