import { Box } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { StyledButton, StyledInputBase, ErrorText } from "../utilities";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { clientSchema } from "../../validation/client";
import styled from "styled-components";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 400;
  width: 40%;
  text-transform: capitalize;
`;
const formContainer = {
  width: "45%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
};
export default function StepperClientsInfoForm({
  nextStep,
  setStepperData,
  stepperData,
}) {
  const { t } = useTranslation("finance");
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(clientSchema(t)),
    defaultValues: stepperData.client,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const submitForm = (data) => {
    setStepperData({ ...stepperData, client: data });
    nextStep();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitForm)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "25px",
            margin: "50px 5px 0 5px",
          }}
        >
          <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="accountNo">
                {t("clients.account_no")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="accountNo"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("accountNo")}
                  placeholder={t("clients.number")}
                  type="number"
                />
              </Box>
              <ErrorText>{errors.accountNo?.message}</ErrorText>
            </Box>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="arabic_name">
                {t("clients.arabic_name")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="arabic_name"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("fNameA")}
                  placeholder={t("clients.name")}
                />
              </Box>
              <ErrorText>{errors.fNameA?.message}</ErrorText>
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="english_name">
                {t("clients.english_name")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="english_name"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("fName")}
                  placeholder={t("clients.name")}
                />
              </Box>
              <ErrorText>{errors.fName?.message}</ErrorText>
            </Box>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="email">{t("clients.email")}</StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="email"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("email")}
                  placeholder={t("clients.email")}
                />
              </Box>
              <ErrorText>{errors.email?.message}</ErrorText>
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="contactPerson">
                {t("clients.contact_person")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="contactPerson"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("contactPerson")}
                  placeholder={t("clients.name")}
                />
              </Box>
              <ErrorText>{errors.contactPerson?.message}</ErrorText>
            </Box>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="contactNo">
                {t("clients.contact_no")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="contactNo"
                  sx={{
                    width: "100%",
                    minWidth: "150px !important",
                    backgroundColor: "transparent",
                  }}
                  {...register("contactNo")}
                  placeholder={t("clients.number")}
                />
              </Box>
              <ErrorText>{errors.contactNo?.message}</ErrorText>
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="address1">
                {t("clients.address1")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="address1"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("address1")}
                  placeholder={t("clients.address")}
                />
              </Box>
              <ErrorText>{errors.address1?.message}</ErrorText>
            </Box>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="address2">
                {t("clients.address2")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="address2"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("address2")}
                  placeholder={t("clients.address")}
                />
              </Box>
              <ErrorText>{errors.address2?.message}</ErrorText>
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", columnGap: "10%" }}>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="contactNo2">
                {t("clients.contact_no2")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="contactNo2"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("contactNo2")}
                  placeholder={t("clients.number")}
                />
              </Box>
              <ErrorText>{errors.contactNo2?.message}</ErrorText>
            </Box>
            <Box sx={formContainer}>
              <StyledLabel htmlFor="contactNo3">
                {t("clients.contact_no3")}
              </StyledLabel>
              <Box sx={{ width: "60%" }}>
                <StyledInputBase
                  id="contactNo3"
                  sx={{ width: "100%", minWidth: "150px !important" }}
                  {...register("contactNo3")}
                  placeholder={t("clients.number")}
                />
              </Box>
              <ErrorText>{errors.contactNo3?.message}</ErrorText>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            flexWrap: "wrap",
            marginTop: "2rem",
          }}
        >
          <StyledButton disabled>{t("clients.back")}</StyledButton>
          <StyledButton type="submit">{t("clients.next")}</StyledButton>
        </Box>
      </form>
    </div>
  );
}
