import * as React from "react";
import { Typography, CircularProgress, Box } from "@mui/material";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={53.48}
        color="primary"
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection:'column',
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="caption"
          sx={{ color: "#36CD59", fontSize: "9pt",margin:0 ,padding:0 }}
          component="div"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
        <Box
          variant="caption"
          sx={{ color: "#36CD59", fontSize: "9pt",display:'block',margin:0,padding:0 }}
          component="div"
          color="text.secondary"
        >
          Done
        </Box>
      </Box>
    </Box>
  );
}
export default function DeliveredPercentage({progress}) {
  return <CircularProgressWithLabel value={progress} variant="determinate" />;
}