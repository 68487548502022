import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Card, StyledCheckbox } from "../utilities";
import { useQueryClient, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  TypographyHeader,
  StyledInputBase,
  StyledButton,
} from "../../components/utilities";
import { useTranslation } from "react-i18next";
import { FormLabel, Grid, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const formStyle = {
  width: "45%",
};
const alignForm = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
const formFont = {
  fontSize: "17px",
  color: "#252E48",
};
const formContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  marginBottom: "15px",
  flexWrap: "wrap",
};

export default function MoreInformationEmployee() {
  const { t } = useTranslation("admin");
  const schema = yup.object().shape({
    iD_NO: yup
      .string(t("validation.id_msg_string"))
      .matches(/^[0-9]+$/, t("validation.must_digit_match"))
      .min(14, t("validation.min_14"))
      .max(15, t("validation.max_15")),
    salaryFixed: yup
      .string(t("validation.invalid_num_string"))
      .matches(/^[0-9]+$/, t("validation.must_digit_match")),
    salaryVariable: yup
      .string(t("validation.invalid_num_string"))
      .matches(/^[0-9]+$/, t("validation.must_digit_match")),
    telephone1: yup
      .string(t("validation.invalid_num_string"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("validation.telephone_msg_match")
      )
      .min(3, t("validation.min_4"))
      .max(20, t("validation.max_20")),
    telephone2: yup
      .string(t("validation.invalid_num_string"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("validation.telephone_msg_match")
      )
      .min(3, t("validation.min_4"))
      .max(20, t("validation.max_20")),
    email: yup
      .string(t("validation.mail_msg_string"))
      .email(t("validation.mail_msg_string"))
      .min(7, t("validation.min_4"))
      .max(50, t("validation.max_50")),
    bank: yup
      .string(t("validation.invalid_bank_name"))
      .min(3, t("validation.min_4")),
    vehicleType: yup.string().required(t("validation.vehicle_type")),
    address: yup.string().required(t("validation, fAddress_msg_required")),
    acountNo: yup
      .string(t("validation.invalid_bank"))
      .matches(/^[0-9]+$/, t("validation.must_digit_match"))
      .min(4, t("validation.min_4"))
      .max(20, t("validation.max_20")),
    fDescription: yup
      .string(t("validation.invalid_desc"))
      .min(4, t("validation.min_4"))
      .max(20, t("validation.max_20")),
    hrCode: yup.string(t("validation.invalide_code_msg")),
    fingerPrint: yup.string(t("validation.invalide_code_msg")),
    graduation: yup
      .string(t("validation.invalid_grad"))
      .matches(/^[0-9]+$/, t("validation.must_digit_match"))
      .min(4, t("validation.min_4")),
    directManagerID: yup
      .string(t("validation.invalid_dm_id"))
      .matches(/^[0-9]+$/, t("validation.must_digit_match")),
  });
  const { id } = useParams();
  const { data: employeeData } = useQuery(
    "loading_update_employee",
    async () => {
      const response =
        await axios.get`https://vsoft.com-eg.net:4041/api/Employees/GetByID/${id}`();
      return response.data.data;
    }
  );

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  useEffect(() => {
    if (employeeData) {
      console.log(employeeData);
      setValues(employeeData);
    }
  }, [employeeData]);
  console.log(getValues());
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  async function updateHandler(data) {
    setIsLoading(true);
    setError();
    let response = await axios
      .put(`https://vsoft.com-eg.net:4041/api/Employees/Update`, {
        ...getValues(),
        fID: parseInt(id),
      })
      .catch((err) => {});
    if (response.data.success) {
      setIsLoading(false);
      queryClient.invalidateQueries("gridTableEmployees");
      navigate(`/admin/employees`);
    } else {
      setIsLoading(false);
    }
  }
  return (
    <Box>
      <TypographyHeader>{t("employees.more_information")}</TypographyHeader>
      <form onSubmit={handleSubmit(updateHandler)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="iD_NO" sx={formFont}>
                    {t("employees.national_id")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="fName"
                    {...register("iD_NO")}
                    placeholder="National ID"
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="salaryFixed" sx={formFont}>
                    {t("employees.basic_salary")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="salaryFixed"
                    {...register("salaryFixed")}
                    placeholder="Basic Salary"
                    type="number"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="email" sx={formFont}>
                    {t("employees.email")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="email"
                    {...register("email")}
                    placeholder="E-Mail"
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="salaryVariable" sx={formFont}>
                    {t("employees.variable_salary")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="salaryVariable"
                    {...register("salaryVariable")}
                    placeholder="Variable Salary"
                    type="number"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="telephone1" sx={formFont}>
                    {t("employees.phone_number_1")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="telephone1"
                    {...register("telephone1")}
                    placeholder="Phone Number 1"
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="vehicleType" sx={formFont}>
                    {t("employees.type_of_car")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="vehicleType"
                    {...register("vehicleType")}
                    placeholder="Type Of vehicle"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="telephone2" sx={formFont}>
                    {t("employees.phone_number_2")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="telephone2"
                    {...register("telephone2")}
                    placeholder="Phone Number 2"
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="bank" sx={formFont}>
                    {t("employees.bank_name")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="bank"
                    {...register("bank")}
                    placeholder="Bank Name"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="address" sx={formFont}>
                    {t("employees.address")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="address"
                    {...register("address")}
                    placeholder="Address"
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="acountNo" sx={formFont}>
                    {t("employees.account_number")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="acountNo"
                    {...register("acountNo")}
                    placeholder="Account Number"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="fDescription" sx={formFont}>
                    {t("employees.job_description")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    {...register("fDescription")}
                    id="outlined-multiline-static"
                    placeholder="Job Description"
                    multiline
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        borderRadius: "10px !important",
                      },
                    }}
                    rows={4}
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="hrCode" sx={formFont}>
                    {t("employees.personal_code")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="hrCode"
                    {...register("hrCode")}
                    placeholder="Personal Code"
                  />
                </Grid>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="fingerPrint" sx={formFont}>
                    {t("employees.fingerprint")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="fingerPrint"
                    {...register("fingerPrint")}
                    placeholder="Fingerprint"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="qualification" sx={formFont}>
                    {t("employees.qualification")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="qualification"
                    {...register("qualification")}
                    placeholder="Qualification"
                  />
                </Grid>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="graduation" sx={formFont}>
                    {t("employees.graduation_year")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="graduation"
                    {...register("graduation")}
                    placeholder="Graduation Year"
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="fDescription" sx={formFont}>
                    {t("employees.notes")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    {...register("fDescription")}
                    id="outlined-multiline-static"
                    placeholder="Notes"
                    multiline
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        borderRadius: "10px !important",
                      },
                    }}
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box container sx={formContainer}>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="experience" sx={formFont}>
                    {t("employees.experience")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="experience"
                    {...register("experience")}
                    placeholder="Experience"
                  />
                </Grid>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="directManagerID" sx={formFont}>
                    {t("employees.direct_manager")}
                  </FormLabel>
                </Grid>
                <Grid item xs={8} sx={alignForm}>
                  <StyledInputBase
                    id="directManagerID"
                    {...register("directManagerID")}
                    placeholder="Direct Manager"
                  />
                </Grid>
              </Grid>
              <Grid container sx={formStyle}>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="socialIncurance" sx={formFont}>
                    {t("employees.social_insurance")}
                  </FormLabel>
                </Grid>
                <Grid item xs={2} sx={alignForm}>
                  <StyledCheckbox
                    id="socialIncurance"
                    {...register("socialIncurance")}
                  />
                </Grid>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="drivingLiscence" sx={formFont}>
                    {t("employees.driving_license")}
                  </FormLabel>
                </Grid>
                <Grid item xs={2} sx={alignForm}>
                  <StyledCheckbox
                    id="drivingLiscence"
                    {...register("drivingLiscence")}
                  />
                </Grid>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="vehicleOwnership" sx={formFont}>
                    {t("employees.own_a_car")}
                  </FormLabel>
                </Grid>
                <Grid item xs={2} sx={alignForm}>
                  <StyledCheckbox
                    id="vehicleOwnership"
                    {...register("vehicleOwnership")}
                  />
                </Grid>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="fDeleted" sx={formFont}>
                    {t("employees.leave_work")}
                  </FormLabel>
                </Grid>
                <Grid item xs={2} sx={alignForm}>
                  <StyledCheckbox id="fDeleted" {...register("fDeleted")} />
                </Grid>
                <Grid item xs={4} sx={alignForm}>
                  <FormLabel htmlFor="medicalIncurance" sx={formFont}>
                    {t("employees.medical_insurance")}
                  </FormLabel>
                </Grid>
                <Grid item xs={2} sx={alignForm}>
                  <StyledCheckbox
                    id="medicalIncurance"
                    {...register("medicalIncurance")}
                  />
                </Grid>
              </Grid>
              <StyledButton
                sx={{ width: "30% !important", marginTop: "35px" }}
                type="submit"
              >
                {isLoading ? <CircularProgress /> : "Save"}
              </StyledButton>
            </Box>
          </Card>
        </Box>
      </form>
    </Box>
  );
}
