import * as React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  Box,
  InputBase,
  CircularProgress,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import styled from "styled-components";
const StyledSelect = styled(InputBase)(() => ({
  " .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    border: "2px solid var(--secondary-color);",
    fontSize: 16,
    width: "125px",
    paddingBlock: "10px",
    paddingLeft: "20px",
    fontWeight: "bold",
    backgroundColor: "white",
    transition: "box-shadow .5s",
    "&:focus": {
      borderRadius: 50,
      borderColor: "var(--secondary-color)",
      boxShadow: "0 0 0 1px var(--secondary-color)",
    },
  },
  ".MuiSelect-iconOutlined": {
    color: "var(--primary-color)",
  },
}));
export default function SelectLabels() {
  const [value, setValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const getAnimation = keyframes`
  from{
    transform:rotate(0deg);
  }
  to{
    transform:rotate(360deg);
  }
`;
  const handleChange = (event) => {
    setLoading(true);
    // do the export action
    setValue(event.target.value);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "grid",
          opacity: "1",
          animation: `${getAnimation} 1s infinite`,
          animationTimingFunction: "linear",
          placeItems: "center",
          width: 190,
        }}
      >
        <CircularProgress
          sx={{
            color: "var(--primary-color)",
          }}
        />
      </Box>
    );
  } else {
    return (
      <div>
        <FormControl>
          <Select
            input={<StyledSelect defaultValue={""} />}
            value={value}
            id="export"
            onChange={handleChange}
            displayEmpty
            disableunderline="true"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <em>Export</em>
            </MenuItem>
            <MenuItem value="csv">Csv</MenuItem>
            <MenuItem value="Excel">Excel</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
}
