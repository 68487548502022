import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  StyledButton,
  TypographyHeader,
  Card,
} from "../../components/utilities";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AdjustIcon from "@mui/icons-material/Adjust";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useGetRunners } from "../../Queries/operation";
import StyledTextField from "../../components/utilities/TextField";
const StyledLabel = styled.label`
  font-size: 18px;
  font-weight: 400;
  color: #252e48 !important;
  text-transform: capitalize;
`;
const SelectBox = styled.select`
  border: 2px solid var(--secondary-color);
  border-radius: 50px;
  width: 255px;
  height: 2.5rem;
`;
export default function TrackRunners() {
  const [map, setMap] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [zoomVal, setZoomVal] = useState(6);
  const { t } = useTranslation("report");
  const [runners, setRunners] = useState({});
  const { data: runnerData } = useGetRunners();
  useEffect(() => {
    if (runnerData) {
      setRunners(
        runnerData.data.map((runner) => ({
          label: runner.englishName,
          id: runner.id,
        }))
      );
    }
  }, [runnerData]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCXeKQeNVJoTxR0TtIer14mnkuvKTItZfs",
  });
  if (!isLoaded) {
    return <CircularProgress />;
  }
  const RunnersRoute = [
    { lat: 24.5, lng: 25.9, name: "joh" },
    { lat: 28.5, lng: 30, name: "max" },
    { lat: 20.5, lng: 32.2, name: "jack" },
    { lat: 29.5, lng: 26.5, name: "tele" },
    { lat: 22.5, lng: 28, name: "robert" },
    { lat: 22.5, lng: 34, name: "hank" },
    { lat: 10, lng: 33.5, name: "sam" },
  ];
  const center = { lat: 27.5, lng: 30 };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <TypographyHeader>{t("track_runner.track_runner")}</TypographyHeader>
      </Box>
      <Card width={"100%"}>
        <Box
          sx={{
            padding: "20px 30px",
            width: "100%",
            display: "flex",
            gap: "60px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "30%",
              minWidth: "340px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <StyledLabel>{t("track_runner.runner")}</StyledLabel>
              <FormControl
                sx={{
                  width: "255px",
                }}
              >
                <Autocomplete
                  sx={{
                    height: "45px",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                  options={runners}
                  renderInput={(params) => (
                    <StyledTextField
                      placeholder={t("track_runner.runner")}
                      sx={{
                        width: "100%",
                        minWidth: "255px",
                        border: "2px solid var(--secondary-color)",
                        borderRadius: "50px",
                        padding: "0px 15px",
                      }}
                      variant="standard"
                      preventDefault
                      {...params}
                      label={""}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              width: "30%",
              minWidth: "340px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <StyledLabel>{t("track_runner.manual_update")}</StyledLabel>
              <FormControl
                sx={{
                  width: "255px",
                }}
              >
                <SelectBox placeholder={t("track_runner.manual_update")}>
                  <option value="">{t("track_runner.manual_update")}</option>
                  {runnerData?.data?.map((runner) => (
                    <option value={runner.id} key={runner.id}>
                      {runner.englishName}
                    </option>
                  ))}
                </SelectBox>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              width: "30%",
              minWidth: "340px",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <Box>
              <StyledButton sx={{ width: "255px !important" }}>
                {t("track_runner.show")}
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </Card>
      <Box sx={{ width: "100%" }}>
        <GoogleMap
          center={center}
          zoom={zoomVal}
          mapContainerStyle={{
            width: "100%",
            height: "70vh",
            position: "relative",
          }}
          options={{
            disableDefaultUI: true,
            clickableIcons: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          {RunnersRoute.map((route, index) => {
            return (
              <Marker
                clickable
                key={route.name}
                position={route}
                onClick={() => {
                  setShowInfo((old) => {
                    if (old) {
                      setShowInfo(null);
                      setShowInfo(route.name);
                    } else {
                      setShowInfo(route.name);
                    }
                  });
                  setZoomVal(8);
                }}
              >
                {showInfo === route.name && (
                  <InfoWindow
                    key={`info-${route.name}`}
                    onCloseClick={() => {
                      setShowInfo(null);
                      setZoomVal(6);
                    }}
                  >
                    <Typography>{route.name}</Typography>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
          <Box onClick={() => map.panTo(center)}>
            <AdjustIcon
              sx={{
                position: "absolute",
                bottom: "10%",
                right: "0.5%",
                width: "35px",
                height: "35px",
                backgroundColor: "#fff",
                color: "#4A4A4A",
                cursor: "pointer",
                padding: "2px",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                borderRadius: "50%",
                "&:hover": {
                  border: "1px solid #47BAFF",
                  background: "lightblue",
                },
              }}
            />
          </Box>
        </GoogleMap>
      </Box>
    </div>
  );
}
