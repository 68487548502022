import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import axios from "axios";
import UpdateAccountModal from "./UpdateAccountModal";
import { useQuery } from "react-query";
import { DeleteSvg, EditSvg, ExcelSvg, StyledCheckbox } from "../utilities";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteCashModal from "./DeleteCashModal";
export default function GridTableCashCollection() {
  const [pageSize, setPageSize] = React.useState(20);
  const [currentRow, setCurrentRow] = useState({});
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [openDeleteIcon, setOpenDeleteIcon] = React.useState(false);
  const columns = [
    {
      field: "transID",
      headerName: "Trans ID",
      editable: false,
      width: 100,
      minWidth: 100,
      maxWidth: 110,
    },
    {
      field: "transDate",
      headerName: "Date",
      editable: false,
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    {
      field: "runner",
      headerName: "Runner",
      editable: false,
      width: 140,
      minWidth: 140,
      maxWidth: 150,
    },
    {
      field: "cash",
      headerName: "Cash",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 120,
    },
    {
      field: "other",
      headerName: "Other",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 120,
    },
    {
      field: "total",
      headerName: "Total",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 120,
    },
    {
      field: "drsTransID",
      headerName: "DRS Trans",
      editable: false,
      width: 120,
      minWidth: 120,
      maxWidth: 130,
    },
    {
      field: "posted",
      headerName: "Posted",
      editable: false,
      width: 110,
      minWidth: 110,
      maxWidth: 120,
      renderCell: ({ row }) => {
        return (
          <strong>
            <StyledCheckbox
              checked={row.posted === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },
    {
      field: "updateStatus",
      headerName: "Update Status",
      editable: false,
      width: 140,
      minWidth: 140,
      maxWidth: 150,
      renderCell: ({ row }) => {
        return (
          <strong>
            <StyledCheckbox
              checked={row.updateStatus === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },
    {
      field: "totalCOD",
      headerName: "Total COD",
      editable: false,
      width: 120,
      minWidth: 120,
      maxWidth: 130,
    },
    {
      field: "difference",
      headerName: "Difference",
      editable: false,
      width: 120,
      minWidth: 120,
      maxWidth: 130,
    },
    {
      field: "postDate",
      headerName: "Post Date",
      editable: false,
      width: 190,
      minWidth: 190,
      maxWidth: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: false,
      width: 150,
      minWidth: 150,
      maxWidth: 170,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      minWidth: 140,
      maxWidth: 150,
      renderCell: ({ row }) => {
        return (
          <strong>
            <Box
              sx={{
                display: "flex",
                gap: "0.3rem",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ExcelSvg />
              </Box>
              {/* // =============================Edit Button============================= */}
              <Box
                onClick={() => {
                  setUpdateModalOpen(true);
                  setCurrentRow(row);
                }}
              >
                <EditSvg />
              </Box>
              {/* // =============================Delete Button============================= */}
              <Box
                onClick={() => {
                  setOpenDeleteIcon(true);
                  setCurrentRow(row);
                }}
              >
                <DeleteSvg />
              </Box>
            </Box>
          </strong>
        );
      },
    },
  ];
  const { isLoading, data } = useQuery(
    "gridtableCashCollection",
    async () => {
      const res = await axios.post(
        `https://vsoft.com-eg.net:4041/api/AgentPaymentHdrs/GetPage`,
        {
          pageSize: 1000,
          pageNumber: 0,
        }
      );
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div>
      <div>
        <Box sx={{ height: "650px", width: "100%" }}>
          <DataGrid
            loading={isLoading}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            rows={data ?? []}
            columns={columns}
            getRowId={(rowData) => {
              return rowData.transID;
            }}
            hideFooterSelectedRowCount
          />
        </Box>
      </div>
      <UpdateAccountModal
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        currentRow={currentRow}
      />
      <DeleteCashModal
        openDeleteIcon={openDeleteIcon}
        setOpenDeleteIcon={setOpenDeleteIcon}
        currentRow={currentRow}
      />
    </div>
  );
}
