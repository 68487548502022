import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Checkbox, Modal } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import DeleteModalClients from "./DeleteModalClients";
import SuspendClient from "./SuspendClient";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { NotFound } from "../../pages";
import Loading from "../Loading";
import DetailsSvg from "../utilities/DetailsSvg";
import DeleteSvg from "../utilities/DeleteSvg";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
export default function ClientsDataGrid() {
  const ActivationSwitcher = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#fca311" : "#f48c06",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const { language } = useTranslation().i18n;
  const [pageSize, setPageSize] = useState(10);
  const [deleteButton, setDeleteButton] = useState(false);
  const handleDelete = () => setDeleteButton(true);
  const handleCloseDelete = () => setDeleteButton(false);
  const [suspendButton, setSuspendButton] = useState(false);
  const handleSuspend = () => setSuspendButton(true);
  const handleCloseSuspend = () => setSuspendButton(false);
  const [currentRow, setCurrentRow] = useState({});
  const columns = [
    {
      field: "AccountNo",
      headerName: "Account No.",
      editable: false,
      width: 140,
      sortable: true,
    },
    {
      field: "fName",
      headerName: "Name",
      editable: false,
      width: 130,
      sortable: true,
    },
    {
      field: "ContactPerson",
      headerName: "Contact Person",
      editable: false,
      width: 170,
      sortable: true,
    },
    {
      field: "ContactNo",
      headerName: "Contact No.",
      editable: false,
      width: 140,
      sortable: false,
    },
    {
      field: "Address1",
      headerName: "Address 1",
      editable: false,
      width: 150,
      sortable: true,
    },
    {
      field: "AllowedWeight",
      headerName: "Allowed Weight",
      editable: false,
      width: 180,
      sortable: true,
    },
    {
      field: "PriceListID",
      headerName: "Price List",
      editable: false,
      width: 120,
      sortable: true,
    },
    {
      field: "Prefix",
      headerName: "prefix",
      editable: false,
      width: 80,
      sortable: true,
    },
    {
      field: "InActive",
      headerName: "Active",
      editable: false,
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <Checkbox
              checked={row.inActive === true ? true : false}
              icon={
                <CheckOutlinedIcon
                  sx={{
                    color: "var(--secondary-color)",
                  }}
                />
              }
              checkedIcon={<ClearOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },
    {
      field: "RegistrationDate",
      headerName: "Registeration Date",
      editable: false,
      width: 200,
      sortable: true,
    },
    {
      field: "used",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "0.2rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                component={Link}
                to={`/finance/clients/${parseInt(row.fID)}`}
              >
                <DetailsSvg />
              </Box>
              <Box
                onClick={() => {
                  setCurrentRow(row);
                  handleSuspend();
                }}
              >
                <ActivationSwitcher checked={!row.inActive} />
              </Box>
              <Box
                onClick={() => {
                  setCurrentRow(row);
                  handleDelete();
                }}
              >
                <DeleteSvg />
              </Box>
            </Box>
          </strong>
        );
      },
    },
  ];

  const { data, isLoading, isError } = useQuery(
    "ClientsDetails",
    async () => {
      const data = await axios.post(
        "https://vsoft.com-eg.net:4041/api/Clients/GetPage",
        {
          fromDate: "2023-03-28T15:48:35.516Z",
          toDate: "2023-03-28T15:48:35.516Z",
          serial: 0,
          pageParam: {
            pageSize: 0,
            pageNumber: 0,
          },
          language: language,
        }
      );
      console.log(data.data.data);
      return data.data.data;
    },
    { refetchOnWindowFocus: false }
  );
  if (isError) {
    return <NotFound />;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <Modal
        open={deleteButton}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root": {
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        <Box>
          <DeleteModalClients
            handleCloseDelete={handleCloseDelete}
            currentRow={currentRow}
          />
        </Box>
      </Modal>
      <Modal
        open={suspendButton}
        onClose={handleCloseSuspend}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root": {
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        <Box>
          <SuspendClient
            handleCloseSuspend={handleCloseSuspend}
            currentRow={currentRow}
          />
        </Box>
      </Modal>
      <DataGrid
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 15, 25]}
        pagination
        rows={data}
        columns={columns}
        loading={!data.length}
        getRowId={(rowData) => {
          return rowData.fID;
        }}
      />
    </Box>
  );
}
