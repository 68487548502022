import React from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { StyledButton, TypographyHeader } from "../../components/utilities";
import GridTableCashCollection from "../../components/Finance/GridTableCashCollection";
import { useNavigate } from "react-router-dom";
import PostCashCollection from "../../components/Finance/PostCashCollection";

export default function CashCollection() {
  const { t } = useTranslation("finance");
  const [openDeleteIcon, setOpenDeleteIcon] = React.useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <TypographyHeader>{t("cash.cash_collection")}</TypographyHeader>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <StyledButton
            onClick={() => {
              navigate("/finance/cash-collection/addcollection");
            }}
          >
            {t("cash.new_collection")}
          </StyledButton>
        </Box>
      </Box>
      <div>
        <GridTableCashCollection />
      </div>
      <PostCashCollection
        openDeleteIcon={openDeleteIcon}
        setOpenDeleteIcon={setOpenDeleteIcon}
      />
    </div>
  );
}
