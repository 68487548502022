import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as locales from "react-date-range/dist/locale";
import { DateRangePicker } from "react-date-range";
import {
  addDays,
  differenceInDays,
  startOfWeek,
  endOfWeek,
  isSaturday,
} from "date-fns";
import { useState } from "react";
export default function DateRange(props) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DateRangePicker
        onChange={item => {
          if (
            differenceInDays(
              item.selection.endDate,
              item.selection.startDate
            ) === 6
          ) {
            let selection = {};
            if (isSaturday(new Date())) {
              if (differenceInDays(new Date(), item.selection.endDate) === 0) {
                selection = {
                  startDate: startOfWeek(new Date(), { weekStartsOn: 6 }),
                  endDate: endOfWeek(new Date(), { weekStartsOn: 6 }),
                  key: "selection",
                };
              } else {
                selection = {
                  startDate: startOfWeek(addDays(new Date(), -1), {
                    weekStartsOn: 6,
                  }),
                  endDate: endOfWeek(addDays(new Date(), -1), {
                    weekStartsOn: 6,
                  }),
                  key: "selection",
                };
              }
            } else {
              selection = {
                startDate: addDays(item.selection.startDate, -1),
                endDate: addDays(item.selection.endDate, -1),
                key: "selection",
              };
            }
            setState([selection]);
            props.setDate({
              from: selection.startDate,
              to: selection.endDate,
            });
            return;
          }
          setState([item.selection]);
          props.setDate({
            from: item.selection.startDate,
            to: item.selection.endDate,
          });
        }}
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="horizontal"
        weekStartsOn={6}
        inputRanges={[]}
        showPreview={false}
        locale={locales["enUS"]}
        rangeColors={[
          "var(--secondary-color)",
          "var(--secondary-color)",
          "var(--secondary-color)",
        ]}
      />
    </div>
  );
}
