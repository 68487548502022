import {
  Alert,
  Box,
  CircularProgress,
  Container,
  FormLabel,
  Grid,
  InputBase,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ButtonModal from "../utilities/ButtonModal";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { accountSchema } from "../../validation/client";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import axios from "axios";
const formStyle = {
  width: "45%",
};
const alignForm = {
  display: "flex",
  alignItems: "base-line",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "1em",
};
const formBorder = {
  border: "var(--secondary-color) 2px solid",
  borderRadius: "50px",
  width: "100%",
  paddingX: "1em",
  height: "3rem",
};
const formFont = {
  fontSize: "1em",
  color: "#252E48",
  letterSpacing: 0,
  fontFamily: "poppins",
};

const formContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};
const btn = {
  background: "var(--gradient)",
  color: "#fff",
  borderRadius: "30px",
  height: "50px",
  minHeight: "40px",
  maxHeight: "60px",
  width: "40%",
};
const SelectBox = styled.select`
  border: 2px solid var(--secondary-color);
  border-radius: 50px;
  width: 100%;
  height: 3rem;
`;
export default function EditAccountForm({
  handleCloseEditAccount,
  currentRow,
}) {
  const { data: cityListData } = useQuery("CityList_Select", async () => {
    const data = await axios.post(
      `https://vsoft.com-eg.net:4041/api/bncGeoCity/GetList`,
      {
        pageSize: 100,
        pageNumber: 0,
      }
    );
    return data.data.data;
  });
  const { data: priceListData } = useQuery("PriceList_Select", async () => {
    const data = await axios.post(
      `https://vsoft.com-eg.net:4041/api/Clients/GetList`,
      {
        pageSize: 1000,
        pageNumber: 0,
      }
    );

    return data.data.data;
  });
  const { data: productListData } = useQuery("ProductList_Select", async () => {
    const data = await axios.post(
      `https://vsoft.com-eg.net:4041/api/bncProducts/GetPage
`,
      {
        pageSize: 100,
        pageNumber: 0,
      }
    );
    return data.data.agentPaymentDtls;
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(accountSchema),
    defaultValues: currentRow,
  });
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  async function handleFormSubmit(data) {
    setDisable(true);
    setIsLoading(true);
    setError();
    let response = await axios
      .put(`https://vsoft.com-eg.net:4041/api/Clients/Update`, data)
      .catch(err => {
        setSnack(true);
      });
    if (response.data.success) {
      setIsLoading(false);
      handleCloseEditAccount();
      queryClient.invalidateQueries("ClientsDetails");
    } else {
      setIsLoading(false);
      handleCloseEditAccount();
    }
  }
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box
        sx={{
          border: "1px #fff solid",
          width: "100%",
        }}
      >
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="cityID" sx={formFont}>
                Pickup City
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <SelectBox id="cityID" {...register("cityID")}>
                <option value="">City List</option>
                {cityListData?.map(city => (
                  <option value={city.id} key={city.id}>
                    {city.englishName}
                  </option>
                ))}
              </SelectBox>
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.cityID?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="ProductID" sx={formFont}>
                Pickup Product
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <SelectBox id="ProductID" {...register("ProductID")}>
                <option value="">Product List</option>
                {productListData?.map(product => (
                  <option value={product.ProductID} key={product.ProductID}>
                    {product.fName}
                  </option>
                ))}
              </SelectBox>
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.ProductID?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="allowedWeight" sx={formFont}>
                Allowed Weight
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="allowedWeight"
                {...register("allowedWeight")}
                placeholder="Weight"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.allowedWeight?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="priceListID" sx={formFont}>
                Price List
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <SelectBox id="priceListID" {...register("priceListID")}>
                <option value="">Price List</option>
                {priceListData?.map(item => (
                  <option value={item.id} key={item.id}>
                    {item.englishName}
                  </option>
                ))}
              </SelectBox>
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.priceListID?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="bankName" sx={formFont}>
                Bank Name
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="bankName"
                {...register("bankName")}
                placeholder="Name"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.bankName?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="bankAccount" sx={formFont}>
                Bank Account
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="bankAccount"
                {...register("bankAccount")}
                placeholder="Bank Account"
                sx={{ backgroundColor: "transparent", ...formBorder }}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.bankAccount?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="accountType" sx={formFont}>
                Account Type
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="accountType"
                {...register("accountType")}
                placeholder="Type"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.accountType?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="prefix" sx={formFont}>
                Prefix
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="prefix"
                {...register("prefix")}
                placeholder="Prefix"
                sx={{ backgroundColor: "transparent", ...formBorder }}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.prefix?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="monthlyAWBs" sx={formFont}>
                Monthly Awbs
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="monthlyAWBs"
                {...register("monthlyAWBs")}
                placeholder="Awbs"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              {errors.monthlyAWBs?.type === "required" && (
                <Typography>{errors.monthlyAWBs?.message}</Typography>
              )}
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="importRate" sx={formFont}>
                Import Rate
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="importRate"
                {...register("importRate")}
                placeholder="Type"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.importRate?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="registrationTypeID" sx={formFont}>
                registeration Type
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="registrationTypeID"
                {...register("registrationTypeID")}
                placeholder="Type"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.registerationTypeID?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="salesPerson" sx={formFont}>
                Sales Person
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="salesPerson"
                {...register("salesPerson")}
                placeholder="Sales Person"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.salesPerson?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="exportRate" sx={formFont}>
                Export Rate
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="exportRate"
                {...register("exportRate")}
                placeholder="Export Rate"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.exportRate?.message}</Typography>
            </Box>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={4} sx={alignForm}>
              <FormLabel htmlFor="swiftCode" sx={formFont}>
                Swift Code
              </FormLabel>
            </Grid>
            <Grid item xs={8} sx={alignForm}>
              <InputBase
                id="swiftCode"
                {...register("swiftCode")}
                placeholder="Code"
                sx={formBorder}
              />
            </Grid>
            <Box sx={{ color: "#cc0000", marginBottom: "2rem" }}>
              <Typography>{errors.swiftCode?.message}</Typography>
            </Box>
          </Grid>
        </Box>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <ButtonModal sx={btn} onClick={handleCloseEditAccount}>
            Cancel
          </ButtonModal>
          <ButtonModal type="submit" disabled={disable} sx={btn}>
            {isLoading ? <CircularProgress /> : "Update"}
          </ButtonModal>
          <Snackbar
            open={snack}
            autoHideDuration={3000}
            onClose={handleCloseEditAccount}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleCloseEditAccount}
              severity="error"
              sx={{ width: "100%" }}
            >
              Unexpected Error Happened !
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </form>
  );
}
