import { Button } from "@mui/material";
import styled from "styled-components";

const ModalButton = styled(Button)`
  width: 220px;
  height: 45px;
  background: transparent var(--gradient) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  letter-spacing: 0.5px;
  font: normal normal 600 16px;
  color: #ffffff;
  text-transform: capitalize;
`;

export default ModalButton;
