import React from "react";
import { Box } from "@mui/system";
import GridTableEmployees from "../../components/Admin/GridTableEmployees";
import AddEmployeesModal from "../../components/Admin/AddEmployeesModal";
import { StyledButton, TypographyHeader } from "../../components/utilities";
import { useTranslation } from "react-i18next";

export default function Employees() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation("admin");
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <TypographyHeader>{t("Agents")}</TypographyHeader>
        <StyledButton id="add" onClick={handleOpen}>
          {t("Add Agent")}
        </StyledButton>
      </Box>
      <AddEmployeesModal
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
      />
      <div>
        <GridTableEmployees pageName="account-details" identifier="fID" />
      </div>
    </div>
  );
}
