import React from "react";
import { Box } from "@mui/material";
import ComingSoonImg from "../assets/images/comingsoon.png";
export default function ComingSoon() {
  return (
    <Box
      sx={{
        display: "grid",
        height: "calc(100vh - 150px)",
        placeItems: "center",
      }}
    >
      <Box sx={{ display: "grid", placeItems: "center", gap: 5 }}>
        <Box sx={{ fontWeight: "bold", fontSize: "31pt" }}>Coming Soon</Box>
        <div className="">
          <Box
            component="img"
            sx={{ transform: "scale(1.5)" }}
            src={ComingSoonImg}
            alt=""
          />
        </div>
      </Box>
    </Box>
  );
}
