import React from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import StyledCheckbox from "./CheckBox";

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  width: 100%;
  height: 60px;
`;

const CheckContainer = styled.div`
  width: 30%;
  display: flex;
  align-content: flex-start;
`;

const Label = styled.label`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
`;
export default function CheckInputGroup(props) {
  const { register } = useFormContext();
  return (
    <InputGroup>
      <Label>{props.label}</Label>
      <CheckContainer>
        <StyledCheckbox {...register(props.name)} />
      </CheckContainer>
    </InputGroup>
  );
}
