import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../../assets/css/modules/pages/OperationsList.module.css";
import {
  StyledButton,
  TypographyHeadModal,
  TypographyHeader,
} from "../../components/utilities";
import PendingGridPayToCustomer from "../../components/Finance/PendingGridPayToCustomer";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";

export default function PayToCust() {
  const gridContainer = {
    width: "100%",
    marginBottom: "3rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    margin: "3rem 0 2rem 0",
  };
  const { t } = useTranslation("finance");
  const navigate = useNavigate();
  const { isLoading, data } = useQuery(
    "gridtablePaytocustomer",
    async () => {
      const res = await axios.post(
        `https://vsoft.com-eg.net:4041/api/PayToCustHdrs/GetPage`,
        {
          fromDate: "2023-03-28T15:15:08.682Z",
          toDate: "2023-03-28T15:15:08.682Z",
          serial: 0,
          pageParam: {
            pageSize: 1000,
            pageNumber: 0,
          },
        }
      );
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const PendingData = data && data.filter((row) => row.Posted === false);
  const PostedData = data && data.filter((row) => row.Posted === true);
  return (
    <div className={styles.mainContainer}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <TypographyHeader>{t("paytocust.pay_to_cust")}</TypographyHeader>
        <StyledButton onClick={() => navigate("/finance/paytocust/addpay")}>
          {t("paytocust.new_pay")}
        </StyledButton>
      </Box>
      <Box sx={gridContainer}>
        <TypographyHeadModal>{t("pending")}</TypographyHeadModal>

        <PendingGridPayToCustomer
          PendingData={PendingData}
          isLoading={isLoading}
        />
      </Box>
      <Box sx={gridContainer}>
        <TypographyHeadModal>{t("posted")}</TypographyHeadModal>

        <PendingGridPayToCustomer
          PostedData={PostedData}
          isLoading={isLoading}
        />
      </Box>
    </div>
  );
}
