import React from "react";

export default function DetailsSvg() {
  return (
    <div>
      <svg
        style={{
          cursor: "pointer",
          transform: "Scale(0.75)",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="32"
        viewBox="0 0 42 32"
      >
        <g
          id="Group_11156"
          data-name="Group 11156"
          transform="translate(14861 -352)"
        >
          <g
            id="Rectangle_18018"
            data-name="Rectangle 18018"
            transform="translate(-14861 352)"
            fill="#fff"
            stroke="#36cd59"
            strokeWidth="1"
          >
            <rect width="42" height="32" rx="5" stroke="none" />
            <rect x="0.5" y="0.5" width="41" height="31" rx="4.5" fill="none" />
          </g>
          <g id="details-more" transform="translate(-14853 362)">
            <path
              id="Path_9318"
              data-name="Path 9318"
              d="M28.938,11.8a1.3,1.3,0,0,0-1.3-1.3H4.3a1.3,1.3,0,0,0,0,2.594H27.641A1.3,1.3,0,0,0,28.938,11.8Z"
              transform="translate(-3 -10.5)"
              fill="#36cd59"
            />
            <path
              id="Path_9319"
              data-name="Path 9319"
              d="M28.938,17.8a1.3,1.3,0,0,0-1.3-1.3H4.3a1.3,1.3,0,0,0,0,2.594H27.641A1.3,1.3,0,0,0,28.938,17.8Z"
              transform="translate(-3 -11.313)"
              fill="#36cd59"
            />
            <path
              id="Path_9320"
              data-name="Path 9320"
              d="M19.859,22.5a1.3,1.3,0,0,1,0,2.594H4.3a1.3,1.3,0,0,1,0-2.594Z"
              transform="translate(4.781 -12.125)"
              fill="#36cd59"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
