import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../assets/css/modules/auth/ForgotPassword.module.css";
import { Form, Input, Button, FormGroup, Label, Col } from "reactstrap";
import AuthFooter from "../../components/AuthFooter";
export default function ForgetPassword() {
  const [phone, setPhone] = useState(null);
  const navigate = useNavigate();
  const handleArrowClick = () => {
    navigate("/auth/login");
  };
  const handleSubmit = e => {
    e.preventDefault();
    // here we should send the phone to the system to validate the provided number then send the code to the phone number
    // handle if the response from the server returns error
    navigate("/auth/verify-code");
  };
  return (
    <div className="auth-form-container">
      <div className="d-flex mx-auto w-100 justify-content-between g-5 align-items-center ">
        <div className={styles.arrow} onClick={handleArrowClick}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-left"
            className="svg-inline--fa fa-arrow-left fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
            ></path>
          </svg>
        </div>
        <p className={`text-capitalize ${styles.headerText} `}>
          forgot password
        </p>
        <div></div>
      </div>
      <div className="d-flex flex-column justify-content-center g-3 align-items-center">
        <h3 className={`${styles.formInfo} mb-5 font-weight-bold`}>
          Enter Your Phone Number To Verify Your Identity
        </h3>
        <Form
          className={`${styles.forgotPasswordForm} mt-3 mb-3 g-3`}
          onSubmit={handleSubmit}
        >
          <FormGroup
            row
            className="mb-4 d-flex justify-content-center align-items-center"
          >
            <Label
              htmlFor="phone"
              sm={3}
              className={`${styles.inputLabelText}`}
            >
              * Phone
            </Label>
            <Col sm={9}>
              <Input
                required
                className="button-bordered"
                id="phone"
                name="phone"
                type="text"
                placeholder="Phone"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Button className="w-100 " type="submit">
              Verify
            </Button>
          </FormGroup>
        </Form>
      </div>
      <AuthFooter />
    </div>
  );
}
