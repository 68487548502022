import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import {
  Grid,
  Box,
  Alert,
  Stack,
  Snackbar,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  TypographyHeader,
  StyledButton,
  Card,
  StyledInputBase,
  StyledCheckbox,
  ErrorText,
} from "../../components/utilities";
import MyModal from "../Operations/MyModal";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
`;
const StyledLabelshow = styled.label`
  border: none;
  width: 255px;
  height: 60px;
  background: var(--body-color);
  border-radius: 50px;
  font: normal normal bold 25px "Roboto";
  color: var(--primary-color);
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const formStyle = {
  width: "45%",
};

export default function AddNewCollection() {
  const { t, i18n } = useTranslation("finance");
  const { language } = i18n;
  const schema = yup.object({
    awb: yup.string().required(t("validation.awb_is_requiured")),
  });
  const { data: runnerData } = useQuery("runnerData", async () => {
    const data = await axios.post(
      `https://vsoft.com-eg.net:4041/api/Runners/GetList`,
      {
        pageSize: 0,
        pageNumber: 0,
      }
    );
    return data.data.data;
  });
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = React.useState(20);
  const columns = [
    {
      field: "awb",
      headerName: "AWB",
      editable: false,
      width: 250,
    },
    {
      field: "cod",
      headerName: "COD",
      editable: false,
      width: 200,
    },
    {
      field: "accountID",
      headerName: "Account",
      editable: false,
      width: 200,
    },
    {
      field: "subAccountID",
      headerName: "Sub Account",
      editable: false,
      width: 200,
    },
    {
      field: "toCityID",
      headerName: "City",
      editable: false,
      width: 200,
      sortable: false,
    },
    {
      field: "transportation",
      headerName: "Transportation",
      editable: false,
      width: 200,
    },
  ];
  const [transactionData, setTransactionData] = useState([]);
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };

  const getDRS = async (Drs) => {
    axios
      .post(
        `https://vsoft.com-eg.net:4041/api/AgentPaymentDtls/GetAWBsByDRSNew/${Drs.id}`,
        {
          pageParam: {
            pageSize: 0,
            pageNumber: 0,
          },
          language,
        }
      )
      .then((res) => {
        setData([res.data.agentPaymentDtls]);
      })
      .catch((err) => {
        setSnack(true);
      });
  };

  const { register, handleSubmit, watch } = useForm({
    mode: "onTouched",
    defaultValues: {
      id: "",
    },
  });
  const totalCod = data
    .map((rowData) => rowData.cod)
    .reduce((prevVal, nextVal) => prevVal + nextVal, 0);
  const cashVal = parseFloat(watch("cash"));
  const otherVal = parseFloat(watch("other"));
  const totalValues = cashVal + otherVal;
  const differenceVal = totalValues - totalCod;
  // ==================================================================================================
  const getRunner = async (runner) => {
    setIsLoading(true);
    axios
      .post(
        `https://vsoft.com-eg.net:4041/api/AgentPaymentDtls/GetAWBsByRunnerID/${runner.runner}`,
        {
          pageParam: {
            pageSize: 0,
            pageNumber: 0,
          },
          language,
        }
      )
      .then((res) => {
        setIsLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setSnack(true);
      });
  };
  const { register: registerRunner, handleSubmit: handleSubmitRunner } =
    useForm({
      mode: "onTouched",
      defaultValues: {
        runner: "",
      },
    });
  // ===============================================================
  const methodsAwb = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit: handleSubmitAwb,
    register: registerAwb,
    formState: { errors },
  } = methodsAwb;
  const awbSchema = {
    pageParam: {
      pageSize: 0,
      pageNumber: 0,
    },
    fromDate: "2020-09-11T11:23:32.799Z",
    toDate: "2022-09-11T11:23:32.799Z",
    serial: 0,
    awBs: "ADD000203",
    reFs: "string",
    pickupNo: 0,
    language: "string",
    accountID: 0,
    userID: 0,
    mainStatusID: 0,
  };
  const multiCallBack = (data) => {
    axios
      .post(`https://vsoft.com-eg.net:4041/api/Shipments/GetShipments`, {
        ...awbSchema,
        awBs: data,
      })
      .then((res) => {
        setData(res.data.shipments);
      });
  };
  const submitAwb = (data) => {
    axios
      .post(`https://vsoft.com-eg.net:4041/api/Shipments/GetShipments`, {
        ...awbSchema,
        awBs: data.awb,
      })
      .then((res) => {
        setData(res.data.shipments);
      });
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <TypographyHeader>{t("cash.new_collection")}</TypographyHeader>
        <StyledButton>
          {isLoading ? <CircularProgress /> : t("cash.save")}
        </StyledButton>
      </Box>
      <Card width={"100%"}>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            mb: "1rem",
          }}
        >
          <form onSubmit={handleSubmitRunner(getRunner)}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                mb: "0.5rem",
              }}
            >
              <Grid
                container
                sx={{ width: "40%" }}
                alignItems="center"
                columnGap={2}
              >
                <Grid item xs={4}>
                  <StyledLabel sx={formStyle}>{t("cash.runner")}</StyledLabel>
                </Grid>
                <Grid item xs={5}>
                  <Select
                    sx={{ width: "100%" }}
                    defaultValue
                    displayEmpty
                    input={<StyledInputBase {...registerRunner("runner")} />}
                  >
                    <MenuItem value="" disabled>
                      <em>Runner</em>
                    </MenuItem>
                    {runnerData &&
                      runnerData.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id || ""}>
                            {item.englishName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
              <Grid container sx={{ width: "60%" }} alignItems="center">
                <Grid item xs={6}>
                  <StyledButton sx={formStyle}>Runner Shipments</StyledButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            mb: "1rem",
          }}
        >
          <form onSubmit={handleSubmit(getDRS)}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                mb: "1rem",
              }}
            >
              <Grid
                container
                sx={{ width: "40%" }}
                alignItems="center"
                columnGap={2}
              >
                <Grid item xs={4}>
                  <StyledLabel sx={formStyle} htmlFor="awb">
                    {`${t("cash.awb_no")}`}
                  </StyledLabel>
                  <ErrorText>{errors.awb?.message}</ErrorText>
                </Grid>
                <Grid item xs={5}>
                  <StyledInputBase
                    id="awb"
                    {...registerAwb("awb")}
                    placeholder="AWB"
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ width: "60%" }} alignItems="center">
                <Grid item xs={6}>
                  <StyledButton type="submit">Add</StyledButton>
                </Grid>
                <Grid item xs={6}>
                  <StyledButton type="button" onClick={() => setOpen(true)}>
                    {t("cash.multi_awb")}
                  </StyledButton>
                </Grid>
              </Grid>
            </Box>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <StyledLabel>{t("cash.update_status")}</StyledLabel>
              </Grid>
              <Grid>
                <StyledCheckbox />
              </Grid>
            </Grid>
          </form>
        </Box>
        <MyModal
          open={open}
          setOpen={setOpen}
          transactionData={transactionData}
          setTransactionData={setTransactionData}
          cb={multiCallBack}
        />
      </Card>
      {/* ===================================== */}
      <Card width={"100%"}>
        <Grid container rowGap={4} sx={{ padding: "0 50px" }}>
          <Grid container alignItems="center">
            <Grid item xs={2} sx={{ mb: "10px" }}>
              <StyledLabel>{t("cash.total_value")}</StyledLabel>
            </Grid>
            <Grid item xs={4} sx={{ mb: "10px" }}>
              <StyledLabelshow defaultValue={0}>{totalValues}</StyledLabelshow>
            </Grid>
            <Grid item xs={2} sx={{ mb: "10px" }}>
              <StyledLabel>{t("cash.total_cod")}</StyledLabel>
            </Grid>
            <Grid item xs={4} sx={{ mb: "10px" }}>
              <StyledLabelshow defaultValue={0}>{totalCod}</StyledLabelshow>
            </Grid>
            <Grid item xs={2} sx={{ mb: "10px" }}>
              <StyledLabel>{t("cash.difference")}</StyledLabel>
            </Grid>
            <Grid item xs={4} sx={{ mb: "10px" }}>
              <StyledLabelshow defaultValue={0}>
                {differenceVal}
              </StyledLabelshow>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <StyledLabel htmlFor="cash">{t("cash.cash")}</StyledLabel>
            </Grid>
            <Grid item xs={4}>
              <StyledInputBase
                id="cash"
                {...register("cash")}
                placeholder="Cash"
                defaultValue={0}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <StyledLabel htmlFor="other">{t("cash.other")}</StyledLabel>
            </Grid>
            <Grid item xs={4}>
              <StyledInputBase
                id="other"
                {...register("other")}
                placeholder="other"
                defaultValue={0}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <StyledLabel>{t("cash.notes")}</StyledLabel>
          </Grid>
          <Grid item xs={4}>
            <StyledInputBase
              placeholder="Notes"
              multiline
              sx={{
                "& .MuiInputBase-input": {
                  borderRadius: "20px !important",
                },
              }}
              rows="4"
            />
          </Grid>
        </Grid>
      </Card>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("cash.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
      <div>
        <Box sx={{ height: "550px", width: "100%" }}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            rows={data ?? data}
            // put condition if data is setted return data else return empty array
            columns={columns}
            getRowId={(rowData) => {
              return rowData.serial;
            }}
            hideFooterSelectedRowCount
          />
        </Box>
      </div>
    </div>
  );
}
