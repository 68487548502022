import "./assets/css/global/App.css";
import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Auth from "./components/routes/Auth";
import { Home } from "./pages";
import { Loading } from "./components";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import "./helpers/i18n";
import { checkUser } from "./redux/sharedToolKit";
import Admin from "./components/routes/Admin";
import Finance from "./components/routes/Finance";

import Reports from "./components/routes/Reports";
function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = i18n;
  useEffect(() => {
    dispatch(checkUser());
  });

  console.log(
    getComputedStyle(document.body.querySelector("#root")).getPropertyValue(
      "--primary-color"
    )
  );
  const Dashboard = React.lazy(() => import("./pages/Dashboard"));
  const Operations = React.lazy(() =>
    import("./components/routes/operations/OperationsIndex")
  );
  const theme = createTheme({
    palette: {
      primary: {
        main: getComputedStyle(document.body)
          .getPropertyValue("--primary-color")
          .trim(),
        contrastText: "#000000",
      },
    },
  });
  return (
    <Suspense fallback={<Loading />}>
      <Box
        className="App"
        sx={{ fontFamily: language === "en" ? "Roboto" : "Cairo" }}
        dir={language === "ar" ? "rtl" : "ltr"}
      >
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/Auth/*" element={<Auth />} />
            <Route path="/" element={<Home />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="donations/*" element={<Operations />} />
              <Route path="reports/*" element={<Reports />} />
              <Route path="admin/*" element={<Admin />} />
              <Route path="finance/*" element={<Finance />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </Box>
    </Suspense>
  );
}

export default App;
