import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  DeleteSvg,
  EditSvg,
  StyledCheckbox,
  TypographyHeader,
  StyledButton,
} from "../utilities";
import { useTranslation } from "react-i18next";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteModalUserMobile from "./DeleteModalUserMobile";
import UpdateModalUserMobile from "./UpdateModalUserMobile";
import AddingModalCreateUserMobileApp from "./CreateUserMobileApp";

export default function AccountMobileRunnerDetails() {
  const { t, i18n } = useTranslation("admin");
  const { language } = i18n;
  const { id } = useParams();
  const [pageSize, setPageSize] = useState(20);
  const [currentRow, setCurrentRow] = useState({});
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [openDeleteIcon, setOpenDeleteIcon] = useState(false);
  const handleCloseCreateUser = () => {
    setCreateUser(false);
  };

  const columns = [
    {
      field: "DisplayName",
      headerName: "Name",
      width: 300,
      editable: false,
    },
    {
      field: "WebUserName",
      headerName: "User Name",
      width: 300,
      editable: false,
    },

    {
      field: "IsEnabled",
      headerName: "Active",
      width: 280,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <StyledCheckbox
              checked={row.IsEnabled === true ? true : false}
              icon={<ClearOutlinedIcon />}
              checkedIcon={<CheckOutlinedIcon />}
              disabled
              size="small"
            />
          </strong>
        );
      },
    },

    {
      field: "action",
      headerName: "Actions",
      width: 320,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <strong>
            <Box
              sx={{
                display: "flex",
                gap: "0.25rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => {
                  setUpdateModalOpen(true);
                  setCurrentRow(row);
                }}
              >
                <EditSvg />
              </Box>
              <Box
                onClick={() => {
                  setOpenDeleteIcon(true);
                  setCurrentRow(row);
                }}
              >
                <DeleteSvg />
              </Box>
            </Box>
          </strong>
        );
      },
    },
  ];
  const { isLoading, data } = useQuery(
    "gridTableWebUser",
    async () => {
      const res = await axios.post(
        `https://vsoft.com-eg.net:4041/api/WebRunnerUsers/GetPageByRunnerID/${id}`,
        {
          fromDate: "2023-04-08T16:19:08.966Z",
          toDate: "2023-04-08T16:19:08.966Z",
          serial: 0,
          pageParam: {
            pageSize: 0,
            pageNumber: 0,
          },
          language: language,
        }
      );
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div>
      <div>
        <Box sx={{ height: "500px", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <TypographyHeader>{t("employees.all_account")}</TypographyHeader>
            <StyledButton
              onClick={() => {
                setCreateUser(true);
              }}
            >
              {t("employees.add_account")}
            </StyledButton>
          </Box>
          <DataGrid
            disableSelectionOnClick
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 15, 25]}
            loading={isLoading}
            rows={data ?? []}
            columns={columns}
            getRowId={(rowData) => {
              return rowData.UserID;
            }}
          />
        </Box>
      </div>
      <UpdateModalUserMobile
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        currentRow={currentRow}
      />
      <AddingModalCreateUserMobileApp
        userID={id}
        open={createUser}
        setCreateUser={setCreateUser}
        currentRow={currentRow}
        handleCloseCreateUser={handleCloseCreateUser}
      />

      <DeleteModalUserMobile
        openDeleteIcon={openDeleteIcon}
        setOpenDeleteIcon={setOpenDeleteIcon}
        currentRow={currentRow}
      />
    </div>
  );
}
