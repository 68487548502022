import * as yup from "yup";
// const { t } = useTranslation("finance");
export const clientSchema = (t) => {
  return yup.object().shape({
    accountNo: yup
      .string(t("validation.invalid_acc_no"))
      .required(t("validation.acc_no_req"))
      .matches(/^[0-9]+$/, t("validation.must_be_number"))
      .max(9999999999, t("validation.max_10")),
    fNameA: yup
      .string(t("validation.invalid_name"))
      .required(t("validation.arabic_Name_is_required"))
      .min(3, t("validation.min_3")),
    fName: yup
      .string(t("validation.invalid_name"))
      .required(t("validation.english_Name_is_required"))
      .min(3, t("validation.min_3")),
    email: yup
      .string(t("validation.invalid_mail"))
      .email(t("validation.invalid_mail"))
      .required(t("validation.mail_required")),
    contactPerson: yup
      .string(t("validation.invalid_name"))
      .required(t("validation.contact_person_is_required"))
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
    contactNo: yup
      .string(t("validation.invalid_number"))
      .required(t("validation.contact_no_is_required"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("validation.valid_phone")
      )
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
    address1: yup
      .string(t("validation.invalid_address"))
      .required(t("validation.address1_is_required")),
    address2: yup
      .string(t("validation.invalid_address"))
      .required(t("validation.address2_is_required")),
    contactNo2: yup
      .string(t("validation.invalid_number"))
      .required(t("validation.contact_no_is_required"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("validation.valid_phone")
      )
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
    contactNo3: yup
      .string(t("validation.invalid_number"))
      .required(t("validation.contact_no_is_required"))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t("validation.valid_phone")
      )
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
  });
};

export const accountSchema = (t) => {
  return yup.object().shape({
    CityID: yup
      .string(t("validation.invalid_city"))
      .required(t("validation.city_req")),
    ProductID: yup
      .string(t("validation.invalid_product"))
      .required(t("validation.product_req")),
    allowedWeight: yup
      .string(t("validation.invalid_weight"))
      .required(t("validation.weight_req"))
      .matches(/^[0-9]+$/, t("validation.only_by_kilo"))
      .min(1, t("validation.min_1"))
      .max(20, t("validation.max_20")),
    PriceListID: yup
      .string(t("validation.invalid_pricelist"))
      .required(t("validation.price_list_req")),
    bankName: yup
      .string(t("validation.invalid_bank"))
      .required(t("validation.bank_name_req"))
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
    bankAccount: yup
      .string(t("validation.invalid_bank_acc"))
      .required(t("validation.bank_acc_req"))
      .matches(/^[0-9]+$/, t("validation.must_be_number"))
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
    accountType: yup
      .string(t("validation.invalid_acc_type"))
      .required(t("validation.bank_type_req"))
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
    prefix: yup
      .string(t("validation.invalid_prefix"))
      .required("Prefix is required")
      .min(1, t("validation.min_1"))
      .max(3, t("validation.max_5")),
    monthlyAWBs: yup
      .string(t("validation.invalid_monthly_awbs"))
      .required(t("validation.monthly_awbs_req"))
      .matches(/^[0-9]+$/, t("validation.must_be_number")),
    importRate: yup
      .string(t("validation.invalid_import_rate"))
      .required(t("validation.import_rate_req"))
      .matches(/^[0-9]+$/, t("validation.must_be_number")),
    registrationTypeID: yup
      .string(t("validation.invalid_register_type"))
      .required(t("validation.register_type_req"))
      .min(1, t("validation.min_1"))
      .max(20, t("validation.max_20")),
    salesPerson: yup
      .string(t("validation.invalid_sales_person"))
      .required(t("validation.sales_person_req"))
      .min(3, t("validation.min_3"))
      .max(20, t("validation.max_20")),
    exportRate: yup
      .string(t("validation.invalid_export_rate"))
      .required(t("validation.export_rate_req"))
      .matches(/^[0-9]+$/, t("validation.must_be_number")),
    swiftCode: yup
      .string(t("validation.invalid_swift_code"))
      .required(t("validation.swift_code_req"))
      .matches(/^[0-9]+$/, t("validation.must_be_number")),
  });
};
