const generateUsers = (num) => {
  let tmpArr = [];
  const namesList = [
    "Elliot Møller",
    "Olivia Pedersen",
    "Niklas Döring",
    "Elaine Fowler",
    "Jay Reynolds",
  ];
  let genRundomData = () => {
    let orders = Math.floor(Math.random() * (10000 - 1000) + 1000);
    return {
      name: namesList[Math.floor(Math.random() * namesList.length)],
      image: "https://loremflickr.com/640/360",
      phoneNumber: "010002312543",
      orders,
      id: Math.random().toString(36).slice(2),
      progress: (orders / 10000) * 100,
    };
  };
  for (let i = 0; i < num; i++) {
    tmpArr.push(genRundomData());
  }
  return tmpArr;
};
export default generateUsers;
