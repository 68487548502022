import { Alert, Box, Stack } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar } from "@mui/material";
import { BoxModalDel, StyledButton, TypographyHeadModal } from "../utilities";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useState } from "react";
import { useQueryClient } from "react-query";

export default function DeletedModalClients({ handleCloseDelete, currentRow }) {
  const { t } = useTranslation("finance");
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };
  async function handleDelete(e) {
    e.preventDefault();
    setDisable(true);
    setIsLoading(true);
    await axios
      .delete(
        `https://vsoft.com-eg.net:4041/api/Clients/Delete/${currentRow.fID}`
      )
      .then((rse) => {
        setTimeout(() => {
          queryClient.invalidateQueries("ClientsDetails");
          handleCloseDelete();
          setIsLoading(false);
          setDisable(false);
        }, 3000);
      })
      .catch((err) => {
        setSnack(true);
        setIsLoading(false);
        setDisable(false);
      });
  }
  return (
    <div>
      <form onSubmit={handleDelete}>
        <BoxModalDel>
          <Box
            sx={{
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
              margin: "20px 0 45px 0",
            }}
          >
            <WarningAmberIcon
              sx={{
                transform: "Scale(2.8)",
                margin: "0 30px 0 0",
                color: "#ffb121",
              }}
            />
            <TypographyHeadModal delmodal={true}>
              {t("clients.TypographyHeadModaldel")}
            </TypographyHeadModal>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <StyledButton modal onClick={handleCloseDelete}>
              {t("clients.close")}
            </StyledButton>
            <StyledButton modal disabled={disable} type="submit">
              {isLoading ? <CircularProgress /> : t("clients.delete")}
            </StyledButton>
          </Box>
        </BoxModalDel>
      </form>
      {/* ========================================= */}
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("clients.error")}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
