import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormLabel,
  Grid,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonModal from "../utilities/ButtonModal";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import axios from "axios";
const formStyle = {
  width: "45%",
};
const alignForm = {
  display: "flex",
  alignItems: "base-line",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "1em",
};
const formFont = {
  fontSize: "1em",
  color: "#252E48",
  letterSpacing: 0,
};
const formContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};
const btn = {
  background: "var(--gradient)",
  color: "#fff",
  borderRadius: "30px",
  height: "50px",
  minHeight: "40px",
  maxHeight: "60px",
  width: "40%",
};
export default function EditActionForm({ handleCloseEditAction, currentRow }) {
  const { register, handleSubmit, setError } = useForm({
    defaultValues: currentRow,
  });
  const { t } = useTranslation("finance");
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  async function handleFormSubmit(data) {
    setDisable(true);
    setIsLoading(true);
    setError();
    let response = await axios
      .put(`https://vsoft.com-eg.net:4041/api/Clients/Update`, data)
      .catch(err => {
        setSnack(true);
      });
    if (response.data.success) {
      setIsLoading(false);
      handleCloseEditAction();
      queryClient.invalidateQueries("ClientsDetails");
    } else {
      setIsLoading(false);
      handleCloseEditAction();
    }
  }
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box
        sx={{
          border: "1px #fff solid",
          width: "100%",
        }}
      >
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={8} sx={alignForm}>
              <FormLabel sx={formFont}>
                {t("clients.add_fees_to_cod")}
              </FormLabel>
            </Grid>
            <Grid item xs={1} sx={alignForm}>
              <Checkbox
                {...register("webLogin")}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={8} sx={alignForm}>
              <FormLabel sx={formFont}>
                {t("clients.apply_return_cost")}
              </FormLabel>
            </Grid>
            <Grid item xs={1} sx={alignForm}>
              <Checkbox
                {...register("applyReturnCost")}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={8} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.inactive")}</FormLabel>
            </Grid>
            <Grid item xs={1} sx={alignForm}>
              <Checkbox
                {...register("inActive")}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box container sx={formContainer}>
          <Grid container sx={formStyle}>
            <Grid item xs={8} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.cash_payment")}</FormLabel>
            </Grid>
            <Grid item xs={1} sx={alignForm}>
              <Checkbox
                {...register("cashPayment")}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={8} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.bank_payment")}</FormLabel>
            </Grid>
            <Grid item xs={1} sx={alignForm}>
              <Checkbox
                {...register("bankPayment")}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={formStyle}>
            <Grid item xs={8} sx={alignForm}>
              <FormLabel sx={formFont}>{t("clients.send_sms")}</FormLabel>
            </Grid>
            <Grid item xs={1} sx={alignForm}>
              <Checkbox
                {...register("sendSMS")}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          <ButtonModal sx={btn} onClick={handleCloseEditAction}>
            {t("clients.cancel")}
          </ButtonModal>
          <ButtonModal type="submit" disabled={disable} sx={btn}>
            {isLoading ? <CircularProgress /> : t("clients.update")}
          </ButtonModal>
          <Snackbar
            open={snack}
            autoHideDuration={3000}
            onClose={handleCloseEditAction}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={handleCloseEditAction}
              severity="error"
              sx={{ width: "100%" }}
            >
              {t("clients.error")}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </form>
  );
}
