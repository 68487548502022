import { React, useEffect } from "react";
import { Box, FormLabel } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  StyledButton,
  TypographyHeader,
  StyledInputBase,
  Card,
  SelectInputGroup,
} from "../../components/utilities";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGetRunners } from "../../Queries/operation";
import MyModal from "../../components/Operations/MyModal";
const StyledLabel = styled(FormLabel)`
  font-size: 18px;
  font-weight: 400;
  color: #252e48 !important;
  text-transform: capitalize;
`;

export default function PaymentStatusTracking() {
  const { t } = useTranslation("report");
  const [pageSize, setPageSize] = useState(20);
  const [open, setOpen] = useState(false);
  const { data: runnersData } = useGetRunners();
  useEffect(() => {
    if (runnersData) {
      setRunnersRow(
        runnersData.data.map(runner => ({
          name: runner.englishName,
          id: runner.id,
        }))
      );
    }
  }, [runnersData]);
  const [runnersRow, setRunnersRow] = useState([]);
  const columns = [
    {
      field: "awb",
      headerName: "AWB",
      width: 110,
      editable: false,
    },
    {
      field: "consignee",
      headerName: "Consignee",
      width: 150,
      editable: false,
    },
    {
      field: "ref",
      headerName: "ref",
      width: 150,
      editable: false,
    },

    {
      field: "userName",
      headerName: "User Name",
      width: 170,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
    },
    {
      field: "employee",
      headerName: "Employee Code",
      width: 150,
      editable: false,
    },
    {
      field: "runner",
      headerName: "Runner",
      width: 170,
      editable: false,
    },
    {
      field: "callAction",
      headerName: "Call Action ",
      width: 170,
      editable: false,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 170,
      editable: false,
    },
    {
      field: "delivery",
      headerName: "Delivery Time",
      width: 140,
      editable: false,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 140,
      editable: false,
    },
    {
      field: "actionDate",
      headerName: "Action Date",
      width: 150,
      editable: false,
    },
    {
      field: "actionTime",
      headerName: "Action Time",
      width: 150,
      editable: false,
    },
    {
      field: "recipientType",
      headerName: "Recipient Type",
      width: 150,
      editable: false,
    },
    {
      field: "recipientName",
      headerName: "Recipient Name",
      width: 150,
      editable: false,
    },
    {
      field: "account",
      headerName: "Account",
      width: 150,
      editable: false,
    },
    {
      field: "consigneeType",
      headerName: "Consignee Type",
      width: 150,
      editable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 150,
      editable: false,
    },
    {
      field: "specialInstruction",
      headerName: "Special Instructions",
      width: 150,
      editable: false,
    },
    {
      field: "fromBranch",
      headerName: "From Branch",
      width: 150,
      editable: false,
    },
    {
      field: "toBranch",
      headerName: "To Branch",
      width: 150,
      editable: false,
    },
    {
      field: "transNo",
      headerName: "Trans. No.",
      width: 150,
      editable: false,
    },
    {
      field: "transName",
      headerName: "Trans. Name",
      width: 150,
      editable: false,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 150,
      editable: false,
    },
  ];
  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      user: "",
      runner: "",
      status: "",
    },
  });

  const { isLoading, data } = useQuery(
    "gridtableAWBStatusTracking",
    async () => {
      const res = await axios.post(
        // `https://vsoft.com-eg.net:4041/api/Branches/GetPage`,
        {
          pageSize: 1000,
          pageNumber: 0,
        }
      );
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <TypographyHeader>{t("Payment Status Tracking")}</TypographyHeader>
      </Box>
      <form>
        <FormProvider {...methods}>
          <Card width={"100%"}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                rowGap: "30px ",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  columnGap: "22px",
                  rowGap: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("awb_status_tracking.user")}</StyledLabel>
                  <SelectInputGroup
                    name="user"
                    items={runnersRow}
                    placeholder={t("awb_status_tracking.user")}
                  />
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("awb_status_tracking.runner")}</StyledLabel>
                  <SelectInputGroup
                    name="runner"
                    items={runnersRow}
                    placeholder={t("awb_status_tracking.runner")}
                  />
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("awb_status_tracking.status")}</StyledLabel>
                  <SelectInputGroup
                    name="status"
                    items={runnersRow}
                    placeholder={t("awb_status_tracking.status")}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  columnGap: "22px",
                  rowGap: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("awb_status_tracking.from")}</StyledLabel>
                  <StyledInputBase
                    sx={{ width: "255px" }}
                    type="datetime-local"
                  />
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("awb_status_tracking.to")}</StyledLabel>
                  <StyledInputBase
                    sx={{ width: "255px" }}
                    type="datetime-local"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  columnGap: "22px",
                  rowGap: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "5px",
                  }}
                >
                  <StyledLabel>{t("Payment Number")}</StyledLabel>
                  <StyledInputBase placeholder={t("Payment Number")} />
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "5px",
                  }}
                >
                  <StyledButton
                    type="button"
                    onClick={() => setOpen(true)}
                    sx={{ width: "255px !important" }}
                  >
                    {t("Multiple Payments")}
                  </StyledButton>
                </Box>
                <Box
                  sx={{
                    width: "30%",
                    minWidth: "340px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "5px",
                  }}
                >
                  <StyledButton sx={{ width: "255px !important" }}>
                    {t("awb_status_tracking.show")}
                  </StyledButton>
                </Box>
              </Box>
            </Box>
          </Card>
          <MyModal open={open} setOpen={setOpen} />
        </FormProvider>
      </form>
      <Box sx={{ height: "650px", width: "100%" }}>
        <DataGrid
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowsPerPageOptions={[20, 50, 100]}
          loading={isLoading}
          rows={data ?? []}
          columns={columns}
          getRowId={rowData => {
            return rowData.awb;
          }}
        />
      </Box>
    </div>
  );
}
