// fake data as imagination to the data we may get from the server 

import * as Images from '../assets/images/Dashboard'
const {Star,Dollar,Delivered,Rto,Deliveries,Branches} = Images
const cardsData = [
    {
        title:'All Clients',
        number:500, 
        icon: Dollar,
        fromall:null,
        color:'#F0940B'
    },
    {
        title:'Online Deliverers',
        number:200, 
        icon: Deliveries,
        fromall:'All Deliverers 500',
        color:'#7D36CD'
    },
    {
        title:'All Orders',
        number:500, 
        icon: Star,
        fromall:'',
        color:'#00BBFF'
    },
    {
        title:'Done Orders',
        number:500, 
        icon: Delivered,
        fromall:null,
        color:'#36CD59'
    },
    {
        title:'Pending Orders',
        number:500, 
        icon: Branches,
        fromall:null,
        color:'#FFDF00'
    },
    {
        title:'Daily Returns',
        number:500, 
        icon: Rto,
        fromall:null,
        color:'#F00B0B'
    }
]
export default cardsData;