import { Grid } from "@mui/material";
import React from "react";
import { TypographyHeader, StyledButton } from "../../components/utilities";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
const StyledLabel = styled.label`
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
`;
export default function CompanyData() {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { data } = useQuery("componydata", async () => {
    const res = await axios.get(
      `https://vsoft.com-eg.net:4041/api/Companies/GetCompanyInfo`
    );
    return res.data.data;
  });
  return (
    <div>
      <TypographyHeader style={{ marginBottom: "40px" }}>
        {t("company_data.company_profile")}
      </TypographyHeader>
      <Grid container sx={{ gap: "2rem", fontSize: "17px", fontWeight: "600" }}>
        <Grid
          item
          xs={3}
          sx={{
            height: "260px",
            background: "#fff",
            GridShadow: "0px 3px 6px #C4C8D066",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            placeItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              // border: "1px solid #C4C8D066",
              boxShadow: "0px 6px 6px 2px #C4C8D066",
              display: "grid",
              placeItems: "center",
            }}
          >
            <img
              src={`data:image/png;base64,${data?.Logo1}`}
              width="80%"
              alt=""
            />
          </Box>
          <StyledButton onClick={() => navigate("/admin/company-data/add")}>
            {t("company_data.edit_company_data")}
          </StyledButton>
        </Grid>
        <Grid
          item
          container
          xs={10}
          sx={{
            height: "600px",
            background: "#fff",
            GridShadow: "0px 3px 6px #C4C8D066",
            borderRadius: "0px 20px 20px 20px",
            padding: "50px 70px",
          }}
        >
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.company_name")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.CompanyName}
          </Grid>
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.address_in_english")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.fAddress}
          </Grid>
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.address_in_arabic")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.fAddressA}
          </Grid>
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.email")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.Email}
          </Grid>
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.phone1")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.fTelephone1}
          </Grid>
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.phone2")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.fTelephone2}
          </Grid>
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.fax")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.Fax}
          </Grid>
          <Grid xs={2.5} item>
            <StyledLabel>{t("company_data.website")}</StyledLabel>
          </Grid>
          <Grid xs={8} item>
            {data?.WebSite}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
