import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { NewPassword, Signup, Login, ForgetPassword } from "../../pages/Auth";
import { NotFound } from "../../pages";
import "../../assets/css/global/globals.css";
import { useSelector } from "react-redux";
export default function Auth() {
  const { isLoggedIn } = useSelector(state => ({
    isLoggedIn: state.rootReducer.auth.isLoggedIn,
  }));
  console.log(isLoggedIn);
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <div className="auth">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
